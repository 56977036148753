<template>
  <div>
    <multiselect
      v-model="selected"
      :track-by="config.trackBy"
      :placeholder="config.placeholder"
      :options="list"
      :custom-label="config.customLabel"
      :searchable="true"
      :show-no-results="Boolean(query)"
      :preserve-search="true"
      :internal-search="skipExternalSerching"
      :max-height="350"
      :allow-empty="allowEmpty"
      :name="name"
      :loading="loading"
      :class="{ lg: size === 'lg', ...multiselectClass }"
      :multiple="multiple"
      :taggable="taggable"
      :disabled="disabled"
      :close-on-select="closeOnSelect"
      class="search"
      open-direction="below"
      @search-change="debounceFind"
      @open="open"
    >
      <template #afterList>
        <infinite-loading v-if="list.length" @infinite="infiniteHandler">
          <template #no-results>
            <slot name="no-result-infinite">
              <span />
            </slot>
          </template>
          <template #no-more>
            <slot name="no-more-infinite"> No more </slot>
          </template>
        </infinite-loading>
      </template>
      <template #noOptions>
        <slot name="no-options"> List is empty </slot>
      </template>
      <template #noResult>
        <slot name="no-result" />
      </template>
      <template #singleLabel="{ option }">
        <slot name="singleLabel" :option="option" />
      </template>
      <template #option="{ option }">
        <slot name="option" :option="option" />
      </template>
    </multiselect>
  </div>
</template>

<script>
import axios from "axios";
import debounce from "lodash/debounce";

import Multiselect from "vue-multiselect";

const searchOptionsDefault = {
  trackBy: "id",
  placeholder: "",
  customLabel: (e) => e,
  changeParams: (e) => e,
  changeURL: (e) => e,
  processResults: (e) => e || [],
  onListUpdate: (e) => e,
  onIncludedUpdate: (e) => e,
};

export default {
  components: {
    Multiselect,
  },

  props: {
    value: {
      type: [Object, Array],
      default: null,
    },
    searchUrl: {
      type: String,
      required: true,
      default: "",
    },
    size: {
      type: String,
      default: "",
    },
    multiselectClass: {
      type: Object,
      default: () => {},
    },
    openPrefetch: {
      type: Boolean,
      default: false,
    },
    renderPrefetch: {
      type: Boolean,
      default: false,
    },
    allowEmpty: {
      type: Boolean,
      default: true,
    },
    skipExternalSerching: Boolean,
    multiple: Boolean,
    taggable: Boolean,
    disabled: Boolean,
    closeOnSelect: Boolean,
    searchOptions: {
      type: Object,
      default: () => {},
    },
    name: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      config: searchOptionsDefault,
      list: [],
      included: [],
      query: null,
      page: 1,
      per_page: 20,
      loading: false,
      selected: null,
    };
  },

  watch: {
    value: {
      handler(val) {
        this.selected = val || null;
      },
      immediate: true,
      deep: true,
    },

    selected: {
      handler(val) {
        this.$emit("input", val);
      },
      deep: true,
    },

    query() {
      if (!this.skipExternalSerching) {
        this.fetchData();
      }
    },

    list: {
      handler(data) {
        this.config.onListUpdate(data);
      },
    },
    included: {
      handler(data) {
        this.config.onIncludedUpdate(data);
      },
    },
  },

  mounted() {
    this.config = {
      ...this.config,
      ...this.searchOptions,
    };
    if (this.renderPrefetch && this.value) {
      this.fetchData({ renderPrefetch: true });
    }
  },

  methods: {
    open() {
      if (this.openPrefetch) {
        this.list = [];
        this.fetchData();
      }
    },

    getParams(opt = {}) {
      const params = {
        ...opt,
        query: this.query,
        page: this.page,
        per_page: this.per_page,
      };

      return this.config.changeParams(params);
    },

    getURL(opt = {}) {
      const params = {
        ...opt,
        query: this.query,
        page: this.page,
        per_page: this.per_page,
      };
      return this.config.changeURL(this.searchUrl, params);
    },

    infiniteHandler($state) {
      this.page += 1;
      const url = this.getURL();
      const params = this.getParams();

      axios.get(url, { params }).then(({ data }) => {
        const res = this.config.processResults(data.data);

        if (res?.length) {
          this.list.push(...res);
          $state.loaded();
        } else {
          $state.complete();
        }
      });
    },

    emitLoading(value) {
      this.$emit("loading", value);
      this.loading = value;
    },

    debounceFind: debounce(function (q) {
      this.query = q || null;
    }, 500),

    fetchData(opt = {}) {
      this.page = 1;
      const url = this.getURL(opt);
      const params = this.getParams(opt);

      this.emitLoading(true);

      axios
        .get(url, { params })
        .then(({ data }) => {
          this.included = data.included;
          return Promise.resolve(this.config.processResults(data.data));
        })
        .then((data) => {
          this.list = data;
          if (opt.renderPrefetch) {
            [this.selected] = this.list;
          }
        })
        .finally(() => {
          this.emitLoading(false);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  ::v-deep {
    .multiselect__tags {
      border: 1px solid #ced4da;
    }
  }
  &.lg {
    ::v-deep {
      .multiselect__select {
        top: 50%;
        transform: translateY(-50%);
      }
      .multiselect--active .multiselect__select {
        top: 50%;
        transform: rotateZ(180deg) translateY(50%);
      }
      .multiselect__tags {
        font-size: 1.25rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        padding-left: 1rem;
        .multiselect__single,
        .multiselect__placeholder {
          font-size: 1.25rem;
          vertical-align: middle;
        }
        .multiselect__input {
          font-size: 1.25rem;
          margin: 0;
        }
        .multiselect__placeholder {
          padding-left: 5px;
          margin: 0;
        }
      }
    }
  }
}
</style>
