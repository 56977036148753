var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cnd-input",class:{ 'cnd-input--form': _vm.form, 'cnd-input--error': _vm.error },attrs:{"data-name":_vm.name}},[(!_vm.hideTitle)?_c('label',{staticClass:"cnd-input__label",class:{
      'cnd-input__label--size-md': _vm.size === 'md',
      'cnd-input__label--size-lg': _vm.size === 'lg',
      'cnd-input__label--light': _vm.variant === 'light',
      'cnd-input__label--dark': _vm.variant === 'dark',
    }},[_vm._t("default")],2):_vm._e(),_c('div',{staticClass:"cnd-input__group",class:{
      'cnd-input__group--light': _vm.variant === 'light',
      'cnd-input__group--dark': _vm.variant === 'dark',
      'cnd-input__group--white': _vm.variant === 'white',
    }},[_vm._t("input")],2),(_vm.error)?_c('span',{staticClass:"cnd-input__label cnd-input__label--size-sm"},[_vm._t("error")],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }