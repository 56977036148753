<template>
  <div>
    <b-skeleton-wrapper>
      <template #loading>
        <b-skeleton type="button"></b-skeleton>
      </template>
      <button v-if="whatsappMeta.value" class="bttn bttn--sm bttn--orange" @click="open">
        {{ $t("dashboard.open_whatsapp_message") }}
      </button>
    </b-skeleton-wrapper>
    <b-modal
      id="WhatsappMessageModal"
      ref="WhatsappMessageModal"
      size="lg"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
    >
      <div slot="modal-header">
        <h2>{{ $t(`dashboard.sms_message_modal_title`) }}</h2>
      </div>
      <error :response.sync="error" :alert="true"></error>
      <section v-if="step === 1">
        <form id="WhatsappMessageModalForm1" @submit.prevent="submit1">
          <b-row>
            <b-col cols="12">
              <b-form-group>
                <template v-slot:label>
                  <label
                    class="form-group__label"
                    :class="{ 'form-group__label--required': selectedTeamsIsEmpty }"
                  >
                    {{ $t("dashboard.sms_message_which_team") }}
                  </label>
                </template>

                <div v-if="importGroup">
                  <b>
                    {{ $t("dashboard.message_to_imported_group", "The Imported group") }}
                  </b>
                </div>
                <div v-else>
                  <div class="mb-2">
                    <b-form-select
                      :required="selectedTeamsIsEmpty"
                      v-model="targetBucket"
                      :options="options"
                    >
                      <template v-slot:first>
                        <b-form-select-option :value="null">
                          {{ $t("dashboard.sms_message_please_select_option") }}
                        </b-form-select-option>
                      </template>
                    </b-form-select>
                  </div>

                  <b-row
                    v-if="targetBucket === 'has_goal_not_reached_between_custom_pct_range'"
                    class="pt-2"
                  >
                    <b-col>
                      <b-form-group label="From">
                        <b-form-input
                          v-model.number="formPayload.custom_pct_range.from"
                          type="text"
                          inputmode="numeric"
                          pattern="[0-9]*"
                          min="0"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>

                    <b-col>
                      <b-form-group label="To">
                        <b-form-input
                          v-model.number="formPayload.custom_pct_range.to"
                          type="text"
                          inputmode="numeric"
                          pattern="[0-9]*"
                          min="0"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-form-group>
                    <template v-slot:label>
                      <label class="form-group__label">
                        {{ $t("edit_campaign.donation_level_team", "Teams") }}
                      </label>
                    </template>
                    <multiselect-search
                      v-model="selectedTeams"
                      :open-prefetch="true"
                      :search-url="getSearchUrl"
                      :search-options="searchOptions"
                      :multiselectClass="{ cms: true }"
                      :multiple="true"
                      :taggable="true"
                    >
                      <template #no-options>
                        {{
                          $t(
                            "dashboard.team_search_no_options",
                            "Please enter 1 or more characters"
                          )
                        }}
                      </template>
                      <template #no-more-infinite>
                        {{ $t("dashboard.team_search_no_more", "No more teams") }}
                      </template>
                    </multiselect-search>
                  </b-form-group>

                  <div v-if="selectedTeams.length" class="pl-4 mt-2">
                    <b-form-checkbox v-model="formPayload.include_children_teams">
                      {{ $t("dashboard.message_include_children_teams", "Include children teams") }}
                    </b-form-checkbox>

                    <b-form-checkbox v-model="formPayload.include_grandchildren_teams">
                      {{
                        $t(
                          "dashboard.message_include_grandchildren_teams",
                          "Include grandchildren teams"
                        )
                      }}
                    </b-form-checkbox>

                    <b-form-checkbox
                      v-if="
                        formPayload.include_grandchildren_teams ||
                        formPayload.include_children_teams
                      "
                      v-model="formPayload.exclude_parent_teams"
                    >
                      {{
                        $t(
                          "dashboard.message_exclude_parent_teams",
                          "do not send to the parent teams itself"
                        )
                      }}
                    </b-form-checkbox>
                  </div>
                </div>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group>
                <template v-slot:label>
                  <label class="form-group__label form-group__label--required">{{
                    $t("dashboard.sms_message_data")
                  }}</label>
                </template>
                <Multiselect
                  v-model="template"
                  placeholder="Choose template"
                  label="template_name"
                  track-by="template_name"
                  :options="templates"
                  :show-labels="false"
                  :disabled="step === 2"
                  :multiple="false"
                >
                </Multiselect>
              </b-form-group>
            </b-col>
          </b-row>
        </form>
      </section>
      <section v-else-if="step === 2">
        <form id="WhatsappMessageModalForm2" @submit.prevent="submit2">
          <b-row>
            <b-col cols="12">
              <b-form-group>
                <template v-slot:label>
                  <label class="form-group__label">
                    <b>{{ $t("dashboard.sms_message_step2_you_are_about") }}</b>
                    &nbsp;
                    <u>{{ targetTeamBucketText }}</u>
                  </label>
                </template>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group>
                <template v-slot:label>
                  <label class="form-group__label">
                    <b>{{ $t("dashboard.sms_message_step2_message") }}</b>
                  </label>
                </template>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col v-if="alloweCountriesPrefix.length > 0" cols="12">
              <b-form-group>
                <label class="form-group__label">
                  <b-alert show variant="warning">{{
                    $t("dashboard.sms_message_step2_allowed_countries")
                  }}</b-alert>
                </label>
                <div class="d-flex flex-wrap">
                  <div
                    v-for="(country, index) in alloweCountriesPrefix"
                    :key="index"
                    class="d-flex flex-column align-items-center mr-3"
                  >
                    <h4 class="fs-1">
                      <b-badge class="mb-1" variant="success">
                        {{ country }}
                      </b-badge>
                    </h4>
                  </div>
                </div>
              </b-form-group>
            </b-col>
            <b-col v-else>
              <b-alert show variant="danger">{{
                $t("dashboard.sms_message_step2_no_availeble_countries")
              }}</b-alert>
            </b-col>
          </b-row>
        </form>
      </section>
      <div slot="modal-footer" class="d-flex flex-row">
        <button class="mx-2 bttn bttn--lg bttn--blue" @click="close" type="button">
          {{ $t(`dashboard.cancel_btn`) }}
        </button>

        <button class="mx-2 bttn bttn--lg bttn--blue" @click="back" type="button" v-if="step === 2">
          {{ $t(`dashboard.sms_message_modal_back`) }}
        </button>

        <div class="ml-auto" v-if="step === 1">
          <button
            id="submit-btn"
            class="bttn bttn--lg bttn--orange"
            form="WhatsappMessageModalForm1"
          >
            {{ $t(`dashboard.sms_message_modal_save`) }}
          </button>
        </div>

        <b-overlay
          rounded
          opacity="0.6"
          spinner-small
          spinner-variant="primary"
          class="ml-auto d-inline-block"
          v-else-if="step === 2"
        >
          <button
            type="submit"
            class="bttn bttn--lg bttn--orange"
            form="WhatsappMessageModalForm2"
            :disabled="alloweCountriesPrefix?.length == 0"
          >
            {{ $t(`dashboard.sms_message_modal_send`) }}
          </button>
        </b-overlay>
      </div>
    </b-modal>
  </div>
</template>

<script>
import error from "@/components/error-message.vue";
import { mapState, mapActions } from "vuex";
import { notificationsMixin } from "@/mixins";
import MultiselectSearch from "@/components/multiselect-search.vue";
import Multiselect from "vue-multiselect";

import urls from "@/constants/urls";
import { defineComponent } from "vue";

export default defineComponent({
  mixins: [notificationsMixin],
  components: {
    error,
    MultiselectSearch,
    Multiselect,
  },
  data() {
    return {
      step: 1,
      error: null,
      formPayload: {
        target_bucket: null,
        custom_pct_range: {
          from: 0,
          to: 100,
        },
        message: "",
        group: "",
        include_children_teams: false,
        include_grandchildren_teams: false,
        exclude_parent_teams: false,
      },
      template: {},
      importGroup: false,
      targetBucket: null,
      selectedTeams: [],
      options: [
        {
          text: this.$t("dashboard.sms_message_options_all_teams"),
          value: "all",
        },
        {
          text: this.$t("dashboard.sms_message_options_all_teams_no_goal_reach"),
          value: "has_goal_not_reached",
        },
        {
          text: this.$t("dashboard.sms_message_options_teams_no_goal"),
          value: "has_no_goal",
        },
        {
          text: this.$t(
            "dashboard.sms_message_options_teams_no_donations",
            "All teams without donations"
          ),
          value: "has_no_donations",
        },
        {
          text: this.$t(
            "dashboard.sms_message_options_teams_with_donations",
            "All teams with donations"
          ),
          value: "has_donations",
        },
        {
          label: this.$t("dashboard.sms_message_options_teams_goal_no_reach"),
          options: [
            {
              text: "<=25%",
              value: "has_goal_not_reached_below_25pct",
            },
            {
              text: "<=50%",
              value: "has_goal_not_reached_below_50pct",
            },
            {
              text: "<=75%",
              value: "has_goal_not_reached_below_75pct",
            },
            {
              text: "<=100%",
              value: "has_goal_not_reached_below_100pct",
            },
          ],
        },
        {
          label: this.$t(
            "dashboard.sms_message_options_teams_goal_between",
            "Teams with progress between below point:"
          ),
          options: [
            {
              text: "0% - 25%",
              value: "has_goal_not_reached_between_0_25pct",
            },
            {
              text: "25% - 50%",
              value: "has_goal_not_reached_between_25_50pct",
            },
            {
              text: "50% - 75%",
              value: "has_goal_not_reached_between_50_75pct",
            },
            {
              text: "75% - 100%",
              value: "has_goal_not_reached_between_75_100pct",
            },
          ],
        },
        {
          text: this.$t(
            "dashboard.sms_message_options_between_custom_pct_range",
            "Custom percentage range"
          ),
          value: "has_goal_not_reached_between_custom_pct_range",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      cId: (state) => state.dashboard.selectedCampaign,
      metas: (state) => state.campaign.metas,
    }),
    oId() {
      return localStorage.getItem("orgId");
    },
    whatsappMeta() {
      return this.metas["campaign_whatsapp_settings"] || {};
    },
    maxCount() {
      return this.whatsappMeta?.max_msg_count || 0;
    },
    alloweCountriesPrefix() {
      return this.whatsappMeta?.allowed_countries_prefixes || [];
    },
    templates() {
      return this.whatsappMeta?.templates?.filter((t) => t.type === "type_team_message") || [];
    },
    targetTeamBucketText() {
      if (this.importGroup) {
        return this.$t("dashboard.message_to_imported_group", "The Imported group");
      }

      let text = null;
      this.options.forEach((v) => {
        if (v.options) {
          return v.options.forEach((o) => {
            o.value === this.targetBucket && (text = `${v.label} ${o.text}`);
          });
        } else {
          if (v.value === this.targetBucket) {
            switch (this.targetBucket) {
              case "has_goal_not_reached_between_custom_pct_range": {
                const { from, to } = this.formPayload.custom_pct_range;
                text = `${this.$t(
                  "dashboard.sms_message_options_teams_goal_between",
                  "Teams with progress between below point:"
                )} ${from}% - ${to}%`;
                break;
              }

              default:
                text = v.text;
                break;
            }
          }
        }
      });

      if (this.selectedTeams.length > 0) {
        text = `${(text || "All").replace(
          "All",
          this.selectedTeams.map((t) => this.searchOptions.customLabel(t)).join(", ")
        )}`;
      }

      return text;
    },
    getSearchUrl() {
      const cID = this.cId;
      const orgID = localStorage.getItem("orgId");

      if (Number(cID) === 0) {
        throw new Error("getSearchUrl whatsapp msg campaign id is 0");
      }

      return urls.apiTeams.replace(":orgId", orgID).replace(":campaignId", cID);
    },

    searchOptions() {
      return {
        placeholder: this.$t(
          "dashboard.whatsapp_team_search_label_placeholder",
          "Add individual teams"
        ),
        changeParams: ({ query, page, per_page: pp }) => ({
          q: query,
          page: page,
          limit: pp,
        }),
        customLabel: (e) => `[${e.id}] ${e.name}`,
        processResults: (data) =>
          data.map((el) => ({
            id: el.id,
            ...el.attributes,
          })),
      };
    },

    selectedTeamsIsEmpty() {
      return this.selectedTeams.length === 0;
    },
  },
  watch: {
    selectedTeams(l) {
      if (l?.length === 0) {
        this.formPayload.include_children_teams = false;
        this.formPayload.include_grandchildren_teams = false;
      }
    },
  },
  methods: {
    ...mapActions({
      saveWhatsappMessage: "messaging/save",
    }),
    close() {
      this.step = 1;
      this.targetBucket = null;
      this.template = {};
      this.selectedTeams = [];
      this.formPayload = {
        target_bucket: null,
        custom_pct_range: {
          from: 0,
          to: 100,
        },
        message: "",
        group: "",
        include_children_teams: false,
        include_grandchildren_teams: false,
      };
      this.importGroup = false;

      this.$refs.WhatsappMessageModal.hide();
    },
    open({ team, importGroup } = {}) {
      if (importGroup) {
        this.selected = "";
        this.importGroup = importGroup;
      }

      if (team) {
        this.selectedTeams = [
          {
            id: team.id,
            ...team.attributes,
          },
        ];
      }
      this.$refs.WhatsappMessageModal.show();
    },
    back() {
      if (this.step === 2) {
        this.step = 1;
      }
    },
    submit1() {
      if (this.template?.template_name !== "") {
        this.step = 2;
      }
    },
    submit2() {
      const data = {
        campaign_id: Number(this.cId),
        ...this.formPayload,
        subject: this.template.template_name,
        target_bucket: this.targetBucket,
      };

      if (this.importGroup) {
        data.group = this.importGroup;
      }

      if (this.selectedTeams.length) {
        if (this.targetBucket) {
          data.bucket_and_list = true;
        }

        data.team_ids = this.selectedTeams.map((t) => Number(t.id));
      }

      this.saveWhatsappMessage({
        oId: this.oId,
        cId: this.cId,
        delivery: "whatsapp",
        audience: "teams",
        data,
      })
        .then((data) => {
          this.close();
          this.$_notificationsMixin_makeToast(
            `Success`,
            `Message id: ${data.sqs_message_id}`,
            "success"
          );
        })
        .catch((e) => (this.error = e.response));
    },
  },
});
</script>

<style lang="scss" scoped>
::v-deep {
  .custom-control {
    z-index: unset;
  }
  .multiselect.cms {
    width: 100%;
    min-height: 50px;
    cursor: pointer;
    .multiselect__select {
      height: 50px;
      &:before {
        top: 60%;
      }
    }
    .multiselect__tags {
      &-wrap {
        min-height: 50px;
        display: inline-flex;
        flex-wrap: wrap;
        align-items: center;
      }
      min-height: 50px;
      padding: 0px 40px 0 8px;
    }
    .multiselect__single,
    .multiselect__placeholder {
      font-size: 16px;
      font-weight: 600;
      min-height: 50px;
      line-height: 50px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0;
    }
    .multiselect__input {
      min-height: 50px;
      font-size: 16px;
      font-weight: 600;
      line-height: 50px;
      margin-bottom: 0;
    }
  }
  input[type="radio"][name="target-radios"] {
    &:disabled {
      ~ label {
        &::before {
          background-color: #007bff !important;
        }
      }
    }
    ~ label {
      width: 100%;
      &::after {
        background-image: unset !important;
        background: unset;
      }
    }
  }
}

.disabled-area {
  pointer-events: none;
}
</style>
