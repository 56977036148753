<script>
import axios from "axios";
import urls from "@/constants/urls";
import { notificationsMixin } from "@/mixins";

export default {
  mixins: [notificationsMixin],
  props: {
    teamId: {
      type: Number,
      required: true,
    },
    reportName: {
      type: String,
      required: true,
    },
    reportParams: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      computedReportParams: {
        ...this.reportParams,
      },
      page: 1,
      perPage: 10,
      total: 0,
      list: [],
      loading: false,
    };
  },
  computed: {
    checkDuplicatesOptions() {
      switch (this.reportName) {
        case "protected_team_past_campaign_donors_outreach":
          return [
            {
              text: this.$t("reports.filters_compare_by_email", "Compare by email"),
              value: "email",
            },
            {
              text: this.$t("reports.filters_compare_by_phone", "Compare by  phone"),
              value: "phone",
            },
            {
              text: this.$t(
                "reports.filters_compare_by_email_or_phone",
                "Compare by email OR phone"
              ),
              value: "email_or_phone",
            },
            {
              text: this.$t(
                "reports.filters_compare_by_email_and_phone",
                "Compare by email AND phone"
              ),
              value: "email_and_phone",
            },
          ];
      }

      return [];
    },
    loadersCanBeUnlocked() {
      return Object.values(this.computedReportParams).every((value) => {
        if (Array.isArray(value)) {
          return value.length > 0;
        }
        return Boolean(value);
      });
    },
  },
  methods: {
    async getProtectedDonorReport() {
      const { data } = await axios.get(urls.getProtectedDonorReport(this.teamId, this.reportName), {
        params: {
          ...this.computedReportParams,
          page: this.page,
          per_page: this.perPage,
        },
      });
      this.total = data.total;
      this.list = data.data;
      return data;
    },
    async generateFileProtectedDonorReport() {
      const { data } = await axios.get(
        urls.generateFileProtectedDonorReport(this.teamId, this.reportName),
        {
          params: {
            ...this.computedReportParams,
          },
        }
      );

      const l = data.tmp_url || data.link || data.data.attributes.link;
      const a = document.createElement("a");
      a.href = l;
      a.target = "_blank";

      a.click();
      a.remove();

      return data.link;
    },
    setReportParams(key, value) {
      this.computedReportParams[key] = value;
    },
    setPagination(page, perPage) {
      this.page = page;
      this.perPage = perPage;
    },
    async loadingAction(promise) {
      if (!this.loadersCanBeUnlocked) return;

      this.loading = true;
      try {
        const data = await promise;
        return data;
      } catch (e) {
        this.$_notificationsMixin_handleCatch(e);
        return null;
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {
    page: {
      handler() {
        this.loadingAction(this.getProtectedDonorReport());
      },
    },
    perPage: {
      handler() {
        this.loadingAction(this.getProtectedDonorReport());
      },
    },
  },
};
</script>

<template>
  <div>
    <slot
      :getProtectedDonorReport="getProtectedDonorReport"
      :generateFileProtectedDonorReport="generateFileProtectedDonorReport"
      :computedReportParams="computedReportParams"
      :setReportParams="setReportParams"
      :loadingAction="loadingAction"
      :page="page"
      :perPage="perPage"
      :setPagination="setPagination"
      :total="total"
      :list="list"
      :loading="loading"
      :checkDuplicatesOptions="checkDuplicatesOptions"
      :loadersCanBeUnlocked="loadersCanBeUnlocked"
    />
  </div>
</template>
