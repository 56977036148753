import axios from "axios";
import urls from "@/constants/urls.js";

const state = {
  statistic: [],
};

const mutations = {
  setStatistic(state, data) {
    state.statistic = data;
  },
};

const actions = {
  getDonorStatisticInfo({ commit }) {
    axios
      .get(urls.donorAccountStats.split("?")[0])
      .then((response) => {
        let statistic = response.data.data ? response.data.data : [];
        commit("setStatistic", statistic);
      })
      .catch((e) => {
        console.log(e);
      });
  },
  getDonorStatisticById({ commit }, props) {
    let currency = "usd";
    let organizations = "organizations";

    axios
      .get(
        urls.donorAccountStats
          .replace(":currency", currency)
          .replace(":organizations", organizations)
          .replace(":date_from", props.date_from)
          .replace(":date_to", props.date_to)
      )
      .then((response) => {
        let statistic = response.data.data ? response.data.data : [];
        statistic.attributes.sum_real_amt.usd = statistic.attributes.sum_real_amt.usd
          ? statistic.attributes.sum_real_amt.usd
          : 0;
        statistic.attributes.sum_total_amt.usd = statistic.attributes.sum_total_amt.usd
          ? statistic.attributes.sum_total_amt.usd
          : 0;

        commit("setStatistic", statistic);
      })
      .catch((e) => {
        console.log(e);
      });
  },
};

export default {
  state,
  actions,
  mutations,
};
