<template>
  <div class="tw-col-span-3">
    <div class="donations-container">
      <div class="donations-block--header">
        <div class="donations-title d-flex justify-content-between align-items-center">
          <h4>{{ $t("donor_dashboard.donations", "Donations") }}</h4>
          <b-button
            class="bttn bttn--sm bttn--orange text-nowrap"
            @click="openModalExportDonations"
          >
            {{ $t("donor_dashboard.export_receipts", "Export receipts") }}
          </b-button>
          <b-dropdown
            :text="selectedYears[0] ? String(selectedYears[0]) : 'Years'"
            class="custom-dropdown"
            ref="dropdownMenu"
          >
            <b-form-checkbox-group v-if="years.length === 0" disabled>{{
              $t("donor_dashboard.donation_no_years", "No years")
            }}</b-form-checkbox-group>
            <b-form-checkbox-group
              v-else
              v-model="selectedYears"
              :options="years"
            ></b-form-checkbox-group>
          </b-dropdown>
        </div>
        <donation-export-modal
          ref="exportDonorDonations"
          :years="selectedYears.length ? selectedYears : years"
        ></donation-export-modal>
      </div>

      <div class="donations-plates-wrap">
        <div class="donations-block__th">
          <div class="th-item">{{ $t("donor_dashboard.donation_date", "Date") }}</div>
          <div class="th-item">
            {{ $t("donor_dashboard.donation_org", "Organization") }}
          </div>
          <div class="th-item">
            {{ $t("donor_dashboard.donation_campaign", "Campaign") }}
          </div>
          <div class="th-item">
            {{ $t("donor_dashboard.donation_amount", "Amount") }}
          </div>
          <div class="th-item">
            {{ $t("donor_dashboard.donation_effective_amount", "Effective Amount") }}
            <tooltip
              :title="
                $t(
                  'donor_dashboard.donation_effective_amount_tooltip',
                  'Refers to the total amount of money contributed, considering any subscriptions or matching donations.'
                )
              "
              class="mx-1"
            />
          </div>
          <div class="th-item">
            {{ $t("donor_dashboard.donation_actions", "Actions") }}
          </div>
        </div>
        <donation-info
          v-for="donation in changeDonations(donations)"
          :key="donation.id"
          :donation="donation"
          :isLoading="isLoading"
          @download-receipt="openEditReceiptModal"
          @update-payment-details="openUpdatePaymentDetails"
          @edit-public-data="editPublicData"
          @show-org-contacts="showOrgContacts"
        >
          <template v-if="donation.recurring_period > 0 && !isLoading" #top-list>
            <div class="ml-2 p-2">
              <b>
                {{ $t("donor_dashboard.subscription_donation", "Subscription Donation") }}
              </b>
              (
              <small v-if="!donation.recurring_canceled">
                {{
                  $t("donor_dashboard.payments_remaining", "{n} payments remaining", {
                    n: donation.recurring_period - donation.recurringCharges.length - 1,
                  })
                }}
              </small>
              <small v-else>
                {{ $t("donor_dashboard.canceled", "Canceled") }}
              </small>
              |
              <small>
                {{
                  $t("donor_dashboard.successful_payments", "{n} successful payments", {
                    n: donation.recurringCharges.length + 1,
                  })
                }}
              </small>
              )
            </div>
          </template>
          <template v-if="donation.recurring_period > 0 && !isLoading" #sub-list>
            <div class="ml-2 p-2">
              <b-button
                class="p-0 h-auto"
                v-b-toggle="`recurring-charges-collapse-${donation.id}`"
                variant="link"
              >
                <b>
                  {{
                    $t(
                      "donor_dashboard.subscription_donation_open",
                      "Click here to view all installments"
                    )
                  }}
                  ▼
                </b>
              </b-button>
            </div>
            <b-collapse :id="`recurring-charges-collapse-${donation.id}`">
              <donation-info
                v-for="recurringCharge in changeDonations(donation.recurringCharges)"
                :key="recurringCharge.id"
                :donation="recurringCharge"
                :isLoading="isLoading"
                @download-receipt="openEditReceiptModal"
                @update-payment-details="openUpdatePaymentDetails"
                @edit-public-data="editPublicData"
                @show-org-contacts="showOrgContacts"
                class="ml-2 border-0"
              />
            </b-collapse>
          </template>
        </donation-info>
        <div class="donation-plate total-plate" v-if="!isLoading && donationsCount > 0">
          <div class="donation-plate__cell"></div>
          <div class="donation-plate__cell donation-plate__cell--18"></div>
          <div class="donation-plate__cell donation-plate__cell--22">
            <span class="blue">{{ donationsTotals.donationsTotal }} Donations</span>
          </div>
          <div class="donation-plate__cell">{{ toCurrency(donationsTotals.amount) }}</div>
          <div class="donation-plate__cell">{{ toCurrency(donationsTotals.effectiveAmount) }}</div>
          <div class="donation-plate__cell"></div>
          <div class="donation-plate__cell"></div>
        </div>
      </div>
      <div v-if="!(donationsCount > 0)" class="empty-result-message">
        {{ $t("dashboard.donations_empty") }}
      </div>
      <div class="pagination">
        <b-pagination
          size="md"
          v-if="donationsCount > 10"
          :total-rows="donationsCount"
          v-model="currentPage"
          :per-page="10"
        ></b-pagination>
      </div>
    </div>
    <div v-if="isLoading">
      <div class="loadings">
        <LdsSpinner />
      </div>
    </div>
    <edit-receipt-modal ref="editReceipt" />
    <UpdatePaymentDetails ref="updatePaymentDetails" />
    <EditDonationModal ref="editDonationModal" @update-list="getNewPageDonation" />
    <OrgContacts ref="orgContactsModal" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import urls, { appHost } from "@/constants/urls.js";
import LdsSpinner from "@/common-components/ui-elements/lds-spinner.vue";
import donationExportModal from "./modals/donation-export-modal.vue";
import donationInfo from "./subcomponents/donation-info.vue";
import editReceiptModal from "./subcomponents/receipt-modal.vue";
import UpdatePaymentDetails from "./donor-donations/update-payment-details.vue";
import EditDonationModal from "./donor-donations/edit-donation-modal.vue";
import OrgContacts from "./donor-donations/org-contacts-modal.vue";
import { includedArrToMap, mapIncludedToRelationships } from "@/helpers/jsonapi";
import { deepCopy } from "@/helpers/calendarModule";
import Tooltip from "@/common-components/tooltip.vue";

export default {
  data() {
    return {
      currentPage: 1,
      subscriptionsTotal: 0,
      selectedYears: [],
    };
  },

  components: {
    donationInfo,
    donationExportModal,
    editReceiptModal,
    LdsSpinner,
    UpdatePaymentDetails,
    OrgContacts,
    EditDonationModal,
    Tooltip,
  },

  computed: {
    ...mapState({
      isLoading: (s) => s.donorDonations.loadingDonation,
      years: (s) => s.donorDonations.years,
      donations: (s) => s.donorDonations.donations,
      donationsCount: (s) => Number(s.donorDonations.donationsTotal),
      includedMap: (s) => includedArrToMap(s.donorDonations.included),
      donationsTotals(state) {
        let amount = 0;
        let effectiveAmount = 0;
        let donations = state.donorDonations.donations;
        let donationsTotal =
          Number(state.donorDonations.donationsTotal) + Number(this.subscriptionsTotal);
        for (var donation of donations) {
          amount += donation.attributes.charged_amount;
          effectiveAmount += donation.attributes.effective_amount;
        }
        return { donationsTotal, amount, effectiveAmount };
      },
    }),
  },

  watch: {
    selectedYears() {
      let receiptSetting = `?extend=donation_receipt_id&extend=organization&page=${this.currentPage}&limit=10`;
      let dateSettings = this.getDateSetting();
      let setting = {
        setting: receiptSetting + dateSettings,
      };
      this.$store.dispatch("getDonorDonationsInfo", setting).then(this.countSubscriptions);
    },
    currentPage() {
      this.getNewPageDonation();
    },
  },

  created() {
    let settings = `?extend=donation_receipt_id&extend=organization&page=1&limit=10`;
    let prop = {
      setting: settings,
    };
    this.$store.dispatch("getDonorDonationsInfo", prop).then(this.countSubscriptions);
  },

  methods: {
    toCurrency(value) {
      if (!value) {
        value = 0;
      }
      let result = value
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,")
        .toString();
      return result;
    },

    countSubscriptions(donations) {
      for (var donation of this.changeDonations(donations)) {
        if (donation.recurring_period > 0) {
          this.subscriptionsTotal = this.subscriptionsTotal + donation.recurringCharges.length;
        }
      }
    },

    getNewPageDonation() {
      let receiptSetting = `?extend=donation_receipt_id&extend=organization&page=${this.currentPage}&limit=10`;
      let dateSettings = this.getDateSetting();
      let setting = {
        setting: receiptSetting + dateSettings,
      };
      this.$store.dispatch("getDonorDonationsInfo", setting).then(this.countSubscriptions);
    },

    select(option) {
      let i = this.selectedYears.indexOf(option);
      if (i >= 0) {
        this.selectedYears.splice(i, 1);
      } else {
        this.selectedYears.push(option);
      }
    },
    getDateSetting() {
      let dateSettings = "";

      if (this.selectedYears.length !== 0) {
        for (let year of this.selectedYears) {
          dateSettings += `&year=${year}`;
        }
      }
      return dateSettings;
    },
    mapDonation(donation) {
      let donationRow = {
        ...donation.attributes,
      };
      let donationRelationships = mapIncludedToRelationships(
        deepCopy(donation.relationships),
        deepCopy(this.includedMap)
      );

      let conectedOrganization = donationRelationships.organization.data;
      let conectedCampaign = donationRelationships.campaign.data;

      let campaignShortLink = conectedCampaign.attributes.short_link || "";

      donationRow.id = donation.id;

      donationRow.date = new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
      }).format(new Date(donation.attributes.date * 1000));

      donationRow.organizationName = conectedOrganization.attributes.orgname;
      donationRow.organizationAttr = conectedOrganization.attributes;
      donationRow.campaign = donation.attributes.campaign_name;
      donationRow.teamData = donationRelationships.campaign_team_data.data;
      donationRow.teamDonations = donationRelationships.campaign_team_donations.data;
      donationRow.recurringCharges = donationRelationships?.recurring_charges?.data || [];
      donationRow.campaignShortLink = `${appHost}/${campaignShortLink}`;

      donationRow.chargedCurrency = donation.attributes.processing_charged_currency;
      donationRow.amount = donation.attributes.processing_charged_amount;

      donationRow.amountEffective = donation.attributes.effective_amount;
      donationRow.currencySign = donation.attributes.currency_sign;

      donationRow.campaignTotalSign = conectedCampaign.attributes.currency_sign || "$";
      donationRow.campaignTotal = conectedCampaign.attributes.campaign_stats.total || 0;
      donationRow.donationReceiptId = donation.attributes.donation_receipt_id;

      return donationRow;
    },
    changeDonations(donations) {
      let changeDonations = [];
      for (var donation of donations) {
        let donationRow = this.mapDonation(donation);
        changeDonations.push(donationRow);
      }
      return changeDonations;
    },
    downloadReceipt(donate) {
      axios
        .get(urls.donorDonationReceipt.replace(":donationId", donate.id))
        .then((response) => {
          window.open(response.data.data.attributes.download_link);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    openModalExportDonations() {
      this.$refs.exportDonorDonations.openModal();
    },
    openEditReceiptModal(payload) {
      this.$refs.editReceipt.openModal(payload);
    },
    editPublicData(payload) {
      this.$refs.editDonationModal.show(payload);
    },
    openUpdatePaymentDetails(payload) {
      this.$refs.updatePaymentDetails.show(payload);
    },
    showOrgContacts(data) {
      this.$refs.orgContactsModal.show(data);
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/_vars.scss";
.loadings {
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.custom-dropdown {
  .dropdown-toggle {
    background: #eef5fa;
    width: 120px;
    height: 38px;
    border-radius: 19px;
    color: #107598;
    &:not(:disabled):hover,
    &:not(:disabled):focus,
    &:not(:disabled):active {
      border: none;
      color: #107598;
      background: #eef5fa;
    }
  }
  &.show > .dropdown-toggle {
    border: none;
    color: #107598;
    background: #eef5fa;
  }
  .dropdown-menu {
    min-width: 100%;
  }
  .custom-checkbox {
    padding-left: 0;
    margin-right: 0;
    text-align: right;
    display: block;
    .custom-control-label {
      padding-right: 45px;
      font-size: 16px;
      &:before {
        right: 15px;
        left: auto;
      }
      &:after {
        left: auto;
        right: 15px;
      }
    }
    .custom-control-input:checked ~ .custom-control-label::before {
      background: #107598;
    }
    .custom-control-input:focus + .custom-control-label {
      &:before {
        outline: none !important;
        border: none !important;
        box-shadow: none !important;
      }
    }
  }
}
.donations-container {
  box-shadow: 0 8px 32px 0 rgba(35, 40, 71, 0.08);
  background-color: #ffffff;
  color: $fd;
  h4 {
    font: 26px/1.2 "VitoExtended-Bold";
    margin-bottom: 0;
    width: 70%;
  }
  .bttn--orange {
    background-color: #ff5555;
    margin-right: 21px;
    width: 190px;
    font-size: 15px;
    &:hover {
      opacity: 0.85;
    }
  }
  .donations-title {
    padding-bottom: 20px;
    border-bottom: 1px solid #e6eaed;
  }
  .label {
    display: inline-block;
    padding: 10px 19px;
  }
}
.donations-block--header {
  padding: 20px 20px 0;
}
.donations-block__th {
  box-shadow: 0 20px 20px 0 rgba(35, 40, 71, 0.08), 0 -10px rgba(0, 0, 0, 0),
    -10px 0 rgba(0, 0, 0, 0), 10px 0 rgba(0, 0, 0, 0);
}
.donations-block__th {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(6, 1fr);
  .th-item {
    padding: 20px 7px;
    font-size: 16px;
    vertical-align: middle;
    letter-spacing: -0.6px;
  }
  a {
    color: #107598;
  }
  .blue {
    color: #107598;
  }
}

.donations-plates-wrap {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  width: 100%;
}
.donation-plate.total-plate {
  font-family: "VitoExtended-Bold";
}
.donations-plates-wrap {
  font-size: 16px;
}

.pagination {
  width: 100%;
  margin-top: 5px;
  margin-bottom: 30px;
  li {
    width: 30px;
    height: 30px;
    display: flex;
    @media only screen and (min-width: 768px) {
      width: 50px;
      height: 50px;
    }
    .page-link {
      width: 100%;
      height: auto;
      display: flex;
      //line-height: 30px;
      padding: 0;
      align-items: center;
      justify-content: center;
    }
  }
  .page-item.disabled {
    .page-link {
      height: auto;
      display: flex;
      //line-height: 30px;
      padding: 0;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
