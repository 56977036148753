<script setup>
import { useCampaignGift } from "@/composables/campaign-gift";
import urls from "@/constants/urls";
import { blobToFile, processImageFile } from "@/helpers/processImageFile";
import { notificationsMixin } from "@/mixins";
import axios from "axios";
// import SelectCampaign from "@/views/dashboard/subcomponents/selectCampaign.vue";
import { nanoid } from "nanoid";
import { getCurrentInstance, reactive, ref, watch } from "vue";

const emit = defineEmits(["submit"]);

const {
  item,
  loading: loadingCampaignGift,
  fetchOrgCampaignGiftByID,
  createOrgCampaignGift,
  updateOrgCampaignGift,
} = useCampaignGift();

const vm = getCurrentInstance();
const $t = vm.proxy.$t.bind(vm.proxy);
const cId = vm.proxy.$store.state.campaign.campaign.data.id;
const notificationsMixinHandleCatch =
  notificationsMixin.methods.$_notificationsMixin_handleCatch.bind(vm.proxy);
const notificationsMixinMakeToastCompat =
  notificationsMixin.methods.$_notificationsMixin_makeToastCompat.bind(vm.proxy);

const MODAL_ID = `campaign-gift-modal-${cId}-${nanoid()}`;
const FORM_ID = `campaign-gift-modal-form-${cId}-${nanoid()}`;

const formData = reactive({
  campaign_id: "",
  title: "",
  image: "",
  quantity: "",
  points: "",
});
const imageFile = ref(null);

async function uploadImageFile(file) {
  const form_data = new FormData();
  const originalName = file.name;
  const type = "image/webp";
  const ext = "webp";

  const webpImageBlob = await processImageFile(file);
  const webpImageFile = blobToFile(webpImageBlob, `${originalName}.${ext}`, type);
  form_data.append("file", webpImageFile);

  const orgId = localStorage.getItem("orgId");
  const response = await axios.post(urls.loadImageQuill.replace(":OrgId", orgId), form_data);

  return response.data.src;
}

watch(imageFile, (file) => {
  if (file) {
    loadingCampaignGift.value = true;

    uploadImageFile(file)
      .then((src) => {
        formData.image = src;
      })
      .catch((e) => {
        console.error(e);
        notificationsMixinHandleCatch(e);
      })
      .finally(() => {
        loadingCampaignGift.value = false;
      });
  }
});

async function show(id) {
  if (!id) {
    formData.campaign_id = cId;

    vm.proxy.$bvModal.show(MODAL_ID);
    return;
  }

  try {
    await fetchOrgCampaignGiftByID(cId, id, {});

    formData.campaign_id = item.value.campaign_id;
    formData.title = item.value.title;
    formData.image = item.value.image;
    formData.quantity = item.value.quantity;
    formData.points = item.value.points;

    vm.proxy.$bvModal.show(MODAL_ID);
  } catch (e) {
    notificationsMixinHandleCatch(e);
  }
}

async function hide() {
  vm.proxy.$bvModal.hide(MODAL_ID);
}

function clearData() {
  formData.campaign_id = "";
  formData.title = "";
  formData.image = "";
  formData.quantity = "";
  formData.points = "";
}

async function submit() {
  const formDataClone = structuredClone(formData);
  formDataClone.campaign_id = Number(formDataClone.campaign_id);

  if (item.value) {
    try {
      await updateOrgCampaignGift(cId, item.value.ID, formDataClone);

      notificationsMixinMakeToastCompat({
        group: "Notification",
        title: $t("edit_campaign.success_save_campaign_gift", "Saved"),
      });
      emit("submit", hide, formDataClone);

      return;
    } catch (e) {
      notificationsMixinHandleCatch(e);
    }
  }

  try {
    await createOrgCampaignGift(cId, formDataClone);

    notificationsMixinMakeToastCompat({
      group: "Notification",
      title: $t("edit_campaign.success_save_campaign_gift", "Saved"),
    });
    emit("submit", hide, formDataClone);

    return;
  } catch (e) {
    notificationsMixinHandleCatch(e);
  }
}
</script>

<template>
  <div>
    <slot :show="show" :loading="loadingCampaignGift" />
    <b-modal :id="MODAL_ID" @hidden="clearData" size="xl" scrollable centered>
      <template #modal-header="{ hide }">
        <div>
          <h2>{{ $t("edit_campaign.campaign_gifts_modal_title", "Gifts") }}</h2>
          <div class="modal-header__hide" @click="hide">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <path
                d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
              />
              <path d="M0 0h24v24H0z" fill="none" />
            </svg>
          </div>
        </div>
      </template>
      <template #modal-footer="{ hide }">
        <div>
          <button type="button" class="bttn bttn--lg bttn--blue" @click="hide" name="button">
            {{ $t("edit_campaign.campaign_gifts_modal_close", "Close") }}
          </button>
          <button
            type="submit"
            :form="FORM_ID"
            :disabled="loadingCampaignGift"
            :class="{
              disabled: loadingCampaignGift,
            }"
            class="bttn bttn--lg bttn--orange"
          >
            {{ $t("edit_campaign.campaign_gifts_modal_save", "Save") }}
          </button>
        </div>
      </template>

      <form :id="FORM_ID" @submit.prevent="submit">
        <!-- no idea if this is needed -->
        <!-- <b-form-group :label="$t('edit_campaign.campaign_gifts_modal_save', 'Campaign ID')">
          <SelectCampaign defaultStyles required v-model="formData.campaign_id" />
        </b-form-group> -->
        <b-form-group :label="$t('edit_campaign.campaign_gifts_modal_title', 'Title')">
          <b-form-input required v-model="formData.title" />
        </b-form-group>
        <b-form-group :label="$t('edit_campaign.campaign_gifts_modal_image', 'Image')">
          <b-form-file
            v-model="imageFile"
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Drop file here..."
          ></b-form-file>
        </b-form-group>
        <b-form-group :label="$t('edit_campaign.campaign_gifts_modal_quantity', 'Quantity')">
          <b-form-input
            required
            type="text"
            inputmode="numeric"
            v-model.number="formData.quantity"
          />
        </b-form-group>
        <b-form-group :label="$t('edit_campaign.campaign_gifts_modal_points', 'Points')">
          <b-form-input required type="text" inputmode="numeric" v-model.number="formData.points" />
        </b-form-group>
      </form>
    </b-modal>
  </div>
</template>
