export const includedArrToMap = (inc) => {
  const included = inc || [];

  return included.reduce((map, el) => {
    map[`${el.type}_${el.id}`] = el;
    return map;
  }, {});
};

export const mapIncludedToData = (listOrItem, included) => {
  if (included) {
    const includedMap = includedArrToMap(included);

    if (Array.isArray(listOrItem)) {
      return listOrItem.map((el) => {
        el.relationships = mapIncludedToRelationships(el.relationships, includedMap);
        return el;
      });
    } else {
      listOrItem.relationships = mapIncludedToRelationships(listOrItem.relationships, includedMap);
    }
  }

  return listOrItem;
};

export const mapIncludedToRelationships = (relationships, includedMap) => {
  if (includedMap) {
    const relEntr = Object.entries(relationships);
    relEntr.map(([key, { data }]) => {
      if (data) {
        if (Array.isArray(data)) {
          relationships[key].data = data.map((el) => includedMap[`${el.type}_${el.id}`]);
        } else {
          relationships[key].data = includedMap[`${data.type}_${data.id}`];
        }
      }
    });
  }

  return relationships;
};

export function mapCampaignIncludedToRelationships(campaignData, campaignIncluded) {
  const newCampaignData = structuredClone(campaignData);
  const newCampaignIncluded = structuredClone(campaignIncluded);

  const includedMap = includedArrToMap(newCampaignIncluded);
  const relationships = mapIncludedToRelationships(newCampaignData.relationships, includedMap);

  newCampaignData.relationships = relationships;

  return newCampaignData;
}
