<template>
  <div class="payout-page">
    <section class="header-earned">
      <div class="total-earned">
        <h1>Total Earned</h1>
        <p><sup>$</sup>93,101</p>
        <div class="after-fees">
          <h2>After Fees</h2>
          <p>
            Before Fees:<span><sup>$</sup>100,000</span>
          </p>
        </div>
        <button class="button">
          <svg
            width="15"
            height="14"
            viewBox="0 0 15 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.2"
              d="M12.4975 1C13.6011 0.998621 14.4972 1.89138 14.5 2.99496C14.5028 4.09853 13.6111 4.9958 12.5076 4.99999C11.404 5.00417 10.5056 4.11368 10.5 3.01012C10.4973 2.47837 10.7065 1.96744 11.0813 1.59025C11.4561 1.21306 11.9657 1.00067 12.4975 1Z"
              fill="#107598"
            />
            <path
              opacity="0.2"
              d="M14.7299 7.39802C15.5512 9.19353 14.2376 13 14.2376 13H11.422C11.8258 11.2376 12.1594 8.7626 11.4084 7.11626C11.2922 6.86267 11.1554 6.62391 11 6.40359C11.4445 6.13114 11.9363 5.99282 12.4339 6.00029C12.5995 6.00029 14.0674 5.94504 14.7299 7.39802Z"
              fill="#107598"
            />
            <path
              opacity="0.49535"
              d="M2.5025 1C1.39893 0.998621 0.502788 1.89138 0.500006 2.99496C0.497225 4.09853 1.38885 4.9958 2.49242 4.99999C3.59599 5.00417 4.49439 4.11368 4.49997 3.01012C4.50266 2.47837 4.29349 1.96744 3.91867 1.59025C3.54385 1.21306 3.03425 1.00067 2.5025 1Z"
              fill="#107598"
            />
            <path
              opacity="0.49535"
              d="M2.56612 6.00029C2.4005 6.00029 0.932595 5.94504 0.270108 7.39802C-0.551195 9.19353 0.762435 13 0.762435 13H3.578C3.17416 11.2376 2.84065 8.7626 3.59162 7.11626C3.70782 6.86267 3.84463 6.62391 4 6.40359C3.55551 6.13114 3.06373 5.99282 2.56612 6.00029Z"
              fill="#107598"
            />
            <path
              opacity="0.9"
              d="M5 2.5C5 1.11929 6.11929 0 7.5 0C8.88071 0 10 1.11929 10 2.5C10 3.88071 8.88071 5 7.5 5C6.83696 5 6.20107 4.73661 5.73223 4.26777C5.26339 3.79893 5 3.16304 5 2.5Z"
              fill="#107598"
            />
            <path
              opacity="0.9"
              d="M7.5 6.00035C7.27505 6.00035 5.27525 5.93716 4.36805 7.60281C3.48309 9.22801 4.41748 12.2863 4.84513 13.4945C4.95755 13.7994 5.2433 14.0009 5.56199 14H9.438C9.7567 14.0009 10.0425 13.7994 10.1549 13.4945C10.5825 12.2863 11.5169 9.22043 10.632 7.60281C9.72475 5.93463 7.72495 6.00035 7.5 6.00035Z"
              fill="#107598"
            />
          </svg>
          Share PDF
        </button>
      </div>

      <div class="total-columns">
        <div class="column donors">
          <svg
            width="32"
            height="30"
            viewBox="0 0 32 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.2"
              d="M26.4944 2C28.9774 1.9969 30.9937 4.00561 31 6.48866C31.0062 8.9717 29.0001 10.9906 26.5171 11C24.034 11.0094 22.0126 9.00578 22.0001 6.52276C21.994 5.32633 22.4647 4.17675 23.308 3.32807C24.1513 2.4794 25.2979 2.0015 26.4944 2Z"
              fill="#FF5555"
            />
            <path
              opacity="0.2"
              d="M31.3923 15.9958C33.2402 19.8433 30.2845 28 30.2845 28H23.9495C24.8581 24.2235 25.6085 18.9199 23.9189 15.392C23.6574 14.8486 23.3496 14.337 23 13.8648C24.0001 13.281 25.1066 12.9846 26.2262 13.0006C26.5989 13.0006 29.9017 12.8822 31.3923 15.9958Z"
              fill="#FF5555"
            />
            <path
              opacity="0.49535"
              d="M5.50563 2C3.02258 1.9969 1.00627 4.00561 1.00001 6.48866C0.993757 8.9717 2.99992 10.9906 5.48295 11C7.96598 11.0094 9.98738 9.00578 9.99994 6.52276C10.006 5.32633 9.53534 4.17675 8.692 3.32807C7.84866 2.4794 6.70207 2.0015 5.50563 2Z"
              fill="#FF5555"
            />
            <path
              opacity="0.49535"
              d="M5.77378 13.0006C5.40113 13.0006 2.09834 12.8822 0.607742 15.9958C-1.24019 19.8433 1.71548 28 1.71548 28H8.05051C7.14186 24.2235 6.39146 18.9199 8.08114 15.392C8.34259 14.8486 8.65042 14.337 9 13.8648C7.99989 13.281 6.89338 12.9846 5.77378 13.0006Z"
              fill="#FF5555"
            />
            <path
              opacity="0.9"
              d="M11 5.5C11 2.46243 13.4624 0 16.5 0C19.5376 0 22 2.46243 22 5.5C22 8.53757 19.5376 11 16.5 11C15.0413 11 13.6424 10.4205 12.6109 9.38909C11.5795 8.35764 11 6.95869 11 5.5Z"
              fill="#FF5555"
            />
            <path
              opacity="0.9"
              d="M16.5 13.0007C16.018 13.0007 11.7327 12.8665 9.78867 16.406C7.89233 19.8595 9.89461 26.3584 10.811 28.9258C11.0519 29.5737 11.6642 30.0019 12.3471 30H20.6529C21.3358 30.0019 21.9481 29.5737 22.189 28.9258C23.1054 26.3584 25.1077 19.8434 23.2113 16.406C21.2673 12.8611 16.982 13.0007 16.5 13.0007Z"
              fill="#FF5555"
            />
          </svg>
          <h1>76,000</h1>
          <p>Total Donors</p>
        </div>
        <div class="column date">
          <svg
            width="32"
            height="30"
            viewBox="0 0 32 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M23 2.00008V1.00004C23 0.400086 22.6001 0 22 0C21.3999 0 20.9999 0.399971 20.9999 1.00004V2.00008H11.0001V1.00004C11.0001 0.400086 10.6001 0 10 0C9.40005 0 8.99996 0.399971 8.99996 1.00004V2.00008H5C2.19997 2.00008 0 4.20004 0 7.00007V25C0 27.8 2.19997 30 5 30H27C29.8 30 32 27.8 32 25V7.00007C32 4.20004 29.8 2.00008 27 2.00008H23Z"
              fill="#FFC064"
            />
            <path
              d="M30 25C30 26.6 28.6 28 27 28H5.00002C3.40002 28 2 26.6 2 25V12H30V25Z"
              fill="#FFF0D9"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M6 26C6.59997 26 7.00006 25.8 7.40003 25.4C7.80001 25.0001 8 24.4 8 24C8 23.6 7.80001 22.9999 7.40003 22.6C6.60008 21.8 5.40003 21.8 4.59997 22.6C4.19999 22.9999 4 23.3999 4 24C4 24.4 4.19999 25.0001 4.59997 25.4C4.99994 25.8 5.40003 26 6 26Z"
              fill="#FFC064"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M6 19C6.59997 19 7.00006 18.8 7.40003 18.4C7.80001 18.0001 8 17.4 8 17C8 16.6 7.80001 15.9999 7.40003 15.6C6.60008 14.8 5.40003 14.8 4.59997 15.6C4.19999 15.9999 4 16.3999 4 17C4 17.6001 4.19999 18.0001 4.59997 18.4C4.99994 18.8 5.40003 19 6 19Z"
              fill="#FFC064"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M13 26C13.6 26 14.0001 25.8 14.4 25.4C14.8 25.0001 15 24.4 15 24C15 23.6 14.8 22.9999 14.4 22.6C13.6001 21.8 12.4 21.8 11.6 22.6C11.2 22.9999 11 23.6 11 24C11 24.4 11.2 25.0001 11.6 25.4C11.9999 25.8 12.4 26 13 26Z"
              fill="#FFC064"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M13 19C13.6 19 14.0001 18.8 14.4 18.4C14.8 18.0001 15 17.4 15 17C15 16.6 14.8 15.9999 14.4 15.6C13.6001 14.8 12.4 14.8 11.6 15.6C11.2 15.9999 11 16.6 11 17C11 17.4 11.2 18.0001 11.6 18.4C11.9999 18.8 12.4 19 13 19Z"
              fill="#FFC064"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M19 26C19.6 26 20.0001 25.8 20.4 25.4C20.8 25.0001 21 24.6001 21 24C21 23.3999 20.8 22.9999 20.4 22.6C19.6001 21.8 18.4 21.8 17.6 22.6C17.2 22.9999 17 23.6 17 24C17 24.4 17.2 25.0001 17.6 25.4C17.9999 25.8 18.4 26 19 26Z"
              fill="#FFC064"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M19 19C19.6 19 20.0001 18.8 20.4 18.4C20.8 18.0001 21 17.6001 21 17C21 16.6 20.8 15.9999 20.4 15.6C19.6001 14.8 18.4 14.8 17.6 15.6C17.2 15.9999 17 16.6 17 17C17 17.4 17.2 18.0001 17.6 18.4C17.9999 18.8 18.4 19 19 19Z"
              fill="#FFC064"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M26 26C26.6 26 27.0001 25.8 27.4 25.4C27.8 25.0001 28 24.4 28 24C28 23.6 27.8 22.9999 27.4 22.6C26.6001 21.8 25.4 21.8 24.6 22.6C24.2 22.9999 24 23.3999 24 24C24 24.4 24.2 25.0001 24.6 25.4C24.9999 25.8 25.4 26 26 26Z"
              fill="#FFC064"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M27.4 18.4C27.8 18.0001 28 17.6001 28 17C28 16.6 27.8 15.9999 27.4 15.6C26.6001 14.8 25.4 14.8 24.6 15.6C24.2 15.9999 24 16.6 24 17C24 17.4 24.2 18.0001 24.6 18.4C24.9999 18.8 25.4 19 26 19C26.6 19 27.0001 18.8 27.4 18.4Z"
              fill="#FFC064"
            />
          </svg>
          <h1>76,000</h1>
          <p>Campaign Date</p>
        </div>
        <div class="column gateways">
          <svg
            width="39"
            height="30"
            viewBox="0 0 39 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M37.35 24H7.65C6.715 24 6 23.2607 6 22.2938V1.70616C6 0.739336 6.715 0 7.65 0H37.35C38.285 0 39 0.739336 39 1.70616V22.2938C39 23.2607 38.285 24 37.35 24Z"
              fill="#4ECBA8"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M31.35 30H1.65C0.715 30 0 29.2607 0 28.2938V7.70616C0 6.73934 0.715 6 1.65 6H31.35C32.285 6 33 6.73934 33 7.70616V28.2938C33 29.2607 32.285 30 31.35 30Z"
              fill="white"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M31.35 30H1.65C0.715 30 0 29.2607 0 28.2938V7.70616C0 6.73934 0.715 6 1.65 6H31.35C32.285 6 33 6.73934 33 7.70616V28.2938C33 29.2607 32.285 30 31.35 30Z"
              fill="#00D39C"
              fill-opacity="0.44837"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M9.71429 19H6.28571C5.55714 19 5 18.4222 5 17.6667V16.3333C5 15.5778 5.55714 15 6.28571 15H9.71429C10.4429 15 11 15.5778 11 16.3333V17.6667C11 18.4222 10.4429 19 9.71429 19Z"
              fill="#00D39C"
            />
            <rect y="11" width="33" height="2" fill="#00D39C" />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M27.4995 24H17.4922C17.2187 24 17 23.7778 17 23.5C17 23.2222 17.2187 23 17.4922 23H27.4995C27.7729 23 27.9916 23.2222 27.9916 23.5C28.0463 23.7778 27.8276 24 27.4995 24Z"
              fill="#00D39C"
            />
          </svg>
          <h1>76,000</h1>
          <p>Payment Gateways</p>
        </div>
        <div class="column donations">
          <svg
            width="38"
            height="30"
            viewBox="0 0 38 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M34.1301 14.2403C34.1301 14.2756 34.6992 13.7809 34.6992 13.8163H38L32.271 20L26.4661 13.8163H29.7669C29.7669 13.7809 30.4878 14.2756 30.4878 14.2403C30.4878 8.33922 25.5935 3.5689 19.561 3.5689C16.8293 3.5689 14.3252 4.5583 12.4282 6.18375L10 3.49823C12.5799 1.30742 15.9187 0 19.561 0C27.6043 0 34.1301 6.39576 34.1301 14.2403Z"
              fill="#2782A2"
            />
            <path
              d="M3.86992 15.7597C3.86992 15.7244 3.30081 16.2191 3.30081 16.1837H0L5.729 10L11.5339 16.1837H8.23306C8.23306 16.2191 7.5122 15.7244 7.5122 15.7597C7.5122 21.6608 12.4065 26.4311 18.439 26.4311C21.1707 26.4311 23.6748 25.4417 25.5718 23.8163L28 26.5018C25.4201 28.6926 22.0813 30 18.439 30C10.3957 30 3.86992 23.6042 3.86992 15.7597Z"
              fill="#2782A2"
              fill-opacity="0.45"
            />
          </svg>
          <h1>76,000</h1>
          <p>Recurring Donations</p>
        </div>
      </div>
    </section>

    <section class="tablist-donations">
      <div role="tablist">
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button v-b-toggle.accordion-1>
              Charged Donations<span class="accordion-item-trigger-icon_closed when-opened"></span>
              <span class="accordion-item-trigger-icon when-closed"></span>
            </b-button>
          </b-card-header>
          <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
            <b-card-body class="gateway">
              <h1>Gateway: <span>Check-USD</span></h1>
              <div class="description">
                <div class="gateway">
                  <div class="native-currency">
                    <p>AMNT Charged (native currency):</p>
                    <h2>USD<sup>$</sup>100,000</h2>
                  </div>
                  <div class="market-rate">
                    <p>AMNT USD (calculated by avg daily market rate):</p>
                    <h2>USD<sup>$</sup>150,000</h2>
                  </div>
                </div>
                <div class="gateway">
                  <div class="donation-count">
                    <p>Donation Count:</p>
                    <h2>1300</h2>
                  </div>
                  <div class="fees">
                    <p>Gateway (Stripe) fees and Taxes:</p>
                    <h2>USD<sup>$</sup>-1,230</h2>
                  </div>
                </div>
                <div class="gateway fees">
                  <p>Charidy fee:</p>
                  <h2>USD<sup>$</sup>-6,230</h2>
                </div>
              </div>
            </b-card-body>
          </b-collapse>
        </b-card>

        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button v-b-toggle.accordion-2>
              Recurring Donations<span
                class="accordion-item-trigger-icon_closed when-opened"
              ></span>
              <span class="accordion-item-trigger-icon when-closed"></span>
            </b-button>
          </b-card-header>
          <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
            <b-card-body class="gateway">
              <h1>Gateway: <span>Stripe-USD</span></h1>
              <div class="description">
                <div class="gateway">
                  <div class="native-currency">
                    <p>Amount Charged:</p>
                    <h2>USD<sup>$</sup>10,000</h2>
                  </div>
                  <div class="fees">
                    <p>Gateway (Stripe) fees and Taxes:</p>
                    <h2>USD<sup>$</sup>-230</h2>
                  </div>
                </div>
                <div class="gateway">
                  <div class="donation-count">
                    <p>Donation Count:</p>
                    <h2>13</h2>
                  </div>
                  <div class="fees">
                    <p>Charidy fee:</p>
                    <h2>USD<sup>$</sup>-6,230</h2>
                  </div>
                </div>
              </div>
            </b-card-body>
          </b-collapse>
        </b-card>

        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button v-b-toggle.accordion-3>
              Transfer Amounts<span class="accordion-item-trigger-icon_closed when-opened"></span>
              <span class="accordion-item-trigger-icon when-closed"></span>
            </b-button>
          </b-card-header>
          <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
            <b-card-body class="transfers">
              <div class="transfer">
                <div class="stripe">
                  <h1>Stripe USD</h1>
                </div>
                <div class="stripe-description">
                  <h2>USD<sup>$</sup>140,000</h2>
                  <p class="charidy">Charidy USD<sup>$</sup>-1,230</p>
                  <p>Stripe USD<sup>$</sup>-230</p>
                </div>
              </div>
              <div class="transfer">
                <div class="stripe">
                  <h1>Stripe CAD</h1>
                </div>
                <div class="stripe-description">
                  <h2>CAD<sup>$</sup>110,000</h2>
                  <p class="charidy">Charidy USD<sup>$</sup>-10,000</p>
                  <p>Stripe CAD<sup>$</sup>-560</p>
                </div>
              </div>
              <div class="transfer">
                <div class="stripe">
                  <h1>Yaadpay</h1>
                </div>
                <div class="stripe-description">
                  <h2>NIS<sup>$</sup>21,000</h2>
                  <p class="charidy">N/A</p>
                  <p>N/A</p>
                </div>
              </div>
              <div class="usd">
                <h1>Net Balance</h1>
                <h2><sup>$</sup>12,000,000</h2>
              </div>
            </b-card-body>
          </b-collapse>
        </b-card>

        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button v-b-toggle.accordion-4>
              Outstanding Balance<span
                class="accordion-item-trigger-icon_closed when-opened"
              ></span>
              <span class="accordion-item-trigger-icon when-closed"></span>
            </b-button>
          </b-card-header>
          <b-collapse id="accordion-4" accordion="my-accordion" role="tabpanel">
            <b-card-body class="balans">
              <div class="balance-description">
                <p>Balance</p>
                <h2><sup>$</sup>-12,000,000</h2>
              </div>
              <button>Pay</button>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
      <div class="button-bottom">
        <button class="button">
          <svg
            width="15"
            height="14"
            viewBox="0 0 15 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.2"
              d="M12.4975 1C13.6011 0.998621 14.4972 1.89138 14.5 2.99496C14.5028 4.09853 13.6111 4.9958 12.5076 4.99999C11.404 5.00417 10.5056 4.11368 10.5 3.01012C10.4973 2.47837 10.7065 1.96744 11.0813 1.59025C11.4561 1.21306 11.9657 1.00067 12.4975 1Z"
              fill="#107598"
            />
            <path
              opacity="0.2"
              d="M14.7299 7.39802C15.5512 9.19353 14.2376 13 14.2376 13H11.422C11.8258 11.2376 12.1594 8.7626 11.4084 7.11626C11.2922 6.86267 11.1554 6.62391 11 6.40359C11.4445 6.13114 11.9363 5.99282 12.4339 6.00029C12.5995 6.00029 14.0674 5.94504 14.7299 7.39802Z"
              fill="#107598"
            />
            <path
              opacity="0.49535"
              d="M2.5025 1C1.39893 0.998621 0.502788 1.89138 0.500006 2.99496C0.497225 4.09853 1.38885 4.9958 2.49242 4.99999C3.59599 5.00417 4.49439 4.11368 4.49997 3.01012C4.50266 2.47837 4.29349 1.96744 3.91867 1.59025C3.54385 1.21306 3.03425 1.00067 2.5025 1Z"
              fill="#107598"
            />
            <path
              opacity="0.49535"
              d="M2.56612 6.00029C2.4005 6.00029 0.932595 5.94504 0.270108 7.39802C-0.551195 9.19353 0.762435 13 0.762435 13H3.578C3.17416 11.2376 2.84065 8.7626 3.59162 7.11626C3.70782 6.86267 3.84463 6.62391 4 6.40359C3.55551 6.13114 3.06373 5.99282 2.56612 6.00029Z"
              fill="#107598"
            />
            <path
              opacity="0.9"
              d="M5 2.5C5 1.11929 6.11929 0 7.5 0C8.88071 0 10 1.11929 10 2.5C10 3.88071 8.88071 5 7.5 5C6.83696 5 6.20107 4.73661 5.73223 4.26777C5.26339 3.79893 5 3.16304 5 2.5Z"
              fill="#107598"
            />
            <path
              opacity="0.9"
              d="M7.5 6.00035C7.27505 6.00035 5.27525 5.93716 4.36805 7.60281C3.48309 9.22801 4.41748 12.2863 4.84513 13.4945C4.95755 13.7994 5.2433 14.0009 5.56199 14H9.438C9.7567 14.0009 10.0425 13.7994 10.1549 13.4945C10.5825 12.2863 11.5169 9.22043 10.632 7.60281C9.72475 5.93463 7.72495 6.00035 7.5 6.00035Z"
              fill="#107598"
            />
          </svg>
          Share PDF
        </button>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  components: {},

  created() {
    this.getPayouts();
  },

  computed: {
    ...mapState({
      payouts: (state) => state.payouts.payouts,
      loading: (state) => state.payouts.loadingPayouts,
    }),
  },

  methods: {
    ...mapActions(["getPayouts"]),
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_vars.scss";

.payout-page {
  width: 100%;
}

.header-earned {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
}

.total-earned {
  text-align: center;
  width: 100%;
  h1 {
    font-size: 26px;
    line-height: 33px;
    text-align: center;
    color: #107598;
    margin-bottom: 20px;
  }
  p {
    font-size: 60px;
    line-height: 76px;
    text-align: center;
    color: #414359;
    margin: 0;
    sup {
      font-size: 28px;
      line-height: 36px;
      top: -1em;
    }
  }
}

.after-fees {
  h2 {
    font-size: 24px;
    line-height: 31px;
    color: #414359;
    margin-bottom: 9px;
  }
  p {
    font-size: 24px;
    line-height: 31px;
    text-align: center;
    color: #a0a1ac;
    margin-bottom: 40px;
    span {
      font-size: 26px;
      line-height: 33px;
      text-align: center;
      color: #00d39c;
      sup {
        font-size: 15px;
        line-height: 19px;
        top: -0.6em;
        margin-left: 12px;
      }
    }
  }
}

.total-earned {
  max-width: 732px;
  width: 100%;
}

.button {
  background: #e9f3f7;
  border-radius: 26px;
  border: none;
  font-size: 17px;
  line-height: 17px;
  color: #107598;
  padding: 16px 82px 19px 77px;
  margin: auto;
  svg {
    margin-right: 12px;
  }
}

.total-columns {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 468px;
  width: 100%;
}

.column {
  background: #ffffff;
  box-shadow: 0px 8px 32px rgba(88, 88, 88, 0.0795686);
  border-radius: 4px;
  text-align: center;
  padding: 22px 33px 17px 33px;
  margin-bottom: 16px;
  max-width: 226px;
  width: 100%;
  // margin-right: 26px;
  // &:nth-child(2n) {
  //   margin-right: 0;
  // }
  svg {
    margin-bottom: 21px;
  }
  h1 {
    font-size: 35px;
    line-height: 28px;
    margin-bottom: 18px;
  }
  p {
    font-size: 18px;
    line-height: 23px;
    color: #414359;
    font-weight: normal;
  }
}

.donors {
  color: #ff5555;
}

.date {
  color: #ffc064;
}

.gateways {
  color: #00d39c;
}

.donations {
  color: #107598;
}

.p-1 {
  background: #ffffff;
  box-shadow: 0px 8px 32px rgba(88, 88, 88, 0.0795686);
  border-radius: 4px;
  border: none;
  padding: 0 !important;
}

.mb-1,
.my-1 {
  margin-bottom: 16px !important;
}

.btn,
.bttn {
  padding: 0;
  font-size: 20px;
  line-height: 17px;
  color: #107598;
  font-family: normal;
  justify-content: space-between;
  padding: 32px 26.5px 31px 37px !important;
  width: 100%;
  &:focus {
    background: #e2eef2 !important;
    text-decoration: none;
  }
  .accordion-item-trigger-icon {
    $size: 8px;
    width: $size;
    height: $size;
    border-right: 1px solid #107598;
    border-bottom: 1px solid #107598;
    transform: translateY(-$size / 4) rotate(45deg);
    transition: transform 0.2s ease;
  }
  .accordion-item-trigger-icon_closed {
    $size: 8px;
    margin-top: 8px;
    width: $size;
    height: $size;
    border-right: 1px solid #107598;
    border-bottom: 1px solid #107598;
    transform: translateY(-$size / 4) rotate(224deg);
    transition: transform 0.2s ease;
  }
}

.btn-secondary {
  background: transparent;
  border: transparent;
  font-family: $vito-light;
}

.btn-info {
  background: transparent;
  border: none;
  color: #107598;
  &:hover {
    color: #107598 !important;
  }
}

.card {
  border: none;
}

.card-body {
  padding: 36px 138px 44px 26px;
}

.description {
  display: flex;
  justify-content: space-between;
  padding-top: 31px;
}

.gateway {
  h1 {
    font-size: 24px;
    line-height: 31px;
    text-align: center;
    text-transform: uppercase;
    color: #107598;
    margin: 0;
    padding: 0;
    span {
      font-size: 22px;
      line-height: 28px;
      text-transform: none;
      margin-left: 30px;
    }
  }
  p {
    font-size: 20px;
    line-height: 25px;
    color: #414359;
    margin-bottom: 10px;
  }
  h2 {
    font-size: 26px;
    line-height: 33px;
    color: #00d39c;
    margin: 0;
    sup {
      margin-left: 15px;
      font-size: 17px;
      line-height: 22px;
    }
  }
}

.native-currency {
  margin-bottom: 24px;
}

.donation-count {
  margin-bottom: 24px;
  h2 {
    color: #107598;
  }
}

.fees {
  h2 {
    color: #ff5555;
  }
}

.transfers {
  padding: 9.5px 26px 20px 27px;
  .transfer {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px dashed rgba(65, 67, 89, 0.197294);
    h1 {
      font-size: 26px;
      line-height: 33px;
      color: #414359;
      mix-blend-mode: normal;
      opacity: 0.57;
      padding-top: 26.5px;
      margin-bottom: 0;
    }
    h2 {
      font-size: 26px;
      line-height: 33px;
      color: #414359;
      margin-bottom: 8px;
      sup {
        font-size: 17px;
        line-height: 22px;
        color: #414359;
        margin-left: 14px;
      }
    }
    p {
      font-size: 22px;
      line-height: 28px;
      color: #ff5555;
      margin: 0;
      sup {
        font-size: 15px;
        line-height: 19px;
        text-align: center;
        color: #ff5555;
        margin-left: 18px;
      }
    }
    .charidy {
      margin-bottom: 5px;
    }
  }
}

.stripe-description {
  padding-top: 26.5px;
  padding-bottom: 21.5px;
  text-align: right;
}

.usd {
  padding: 62px 0 0px 0px;
  text-align: right;
  h1 {
    font-size: 16px;
    line-height: 20px;
    color: #00d39c;
    display: none;
  }
  h2 {
    color: #00d39c;
    font-size: 26px;
    line-height: 33px;
    margin: 0;
    sup {
      color: #00d39c;
      font-size: 17px;
      line-height: 22px;
      margin-left: 14px;
    }
  }
}

.balans {
  padding: 38px 26px 36px 31px;
  text-align: center;
  button {
    background: #e9f3f7;
    border-radius: 26px;
    border: none;
    font-size: 17px;
    line-height: 17px;
    color: #107598;
    padding: 18px 78px 17px 78px;
  }
}

.balance-description {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px dashed rgba(65, 67, 89, 0.197294);
  margin-bottom: 26px;
  p {
    font-size: 20px;
    line-height: 17px;
    text-align: center;
    color: #414359;
  }
  h2 {
    font-size: 26px;
    line-height: 33px;
    text-align: center;
    color: #ff5555;
    margin-bottom: 20px;
    sup {
      font-size: 17px;
      line-height: 22px;
      margin-right: 17px;
    }
  }
}

.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}

@media only screen and (max-width: 768px) {
  .description {
    flex-wrap: wrap;
  }

  .gatewey {
    margin-bottom: 25px;
  }
}

@media only screen and (max-width: 768px) {
  .btn,
  .bttn {
    font-size: 17px;
    line-height: 17px;
  }

  .description {
    display: block;
  }

  .card-body {
    padding: 36px 16px 0px 16px;
  }

  .gateway {
    h1 {
      font-size: 18px;
      line-height: 23px;
    }
    h2 {
      font-size: 16px;
      line-height: 20px;
    }
    p {
      font-size: 15px;
      line-height: 19px;
    }
  }

  .native-currency,
  .market-rate,
  .donation-count,
  .fees {
    margin-bottom: 12px;
  }

  .transfers .transfer {
    h1 {
      font-size: 15px;
      line-height: 19px;
    }
    h2 {
      font-size: 18px;
      line-height: 23px;
    }
    p {
      font-size: 12px;
      line-height: 15px;
    }
  }

  .usd {
    background: #f1fdfa;
    border-radius: 0px 0px 6px 6px;
    display: flex;
    justify-content: space-between;
    padding: 18px 16px 16px 17px;
    margin-left: -17px;
    margin-right: -17px;
    h1 {
      display: block;
    }
  }

  .balance-description {
    p {
      font-size: 16px;
      line-height: 20px;
    }
    h2 {
      font-size: 18px;
      line-height: 23px;
      sup {
        font-size: 12px;
        line-height: 15px;
        margin-right: 10px;
      }
    }
  }

  .balans {
    button {
      margin-bottom: 16px;
    }
  }
}

@media only screen and (max-width: 992px) {
  .total-earned {
    margin: auto;
    h1 {
      font-size: 16px;
      line-height: 20px;
    }
    p {
      font-size: 42px;
      line-height: 54px;
      sup {
        font-size: 22px;
        line-height: 28px;
      }
    }
    button {
      padding: 16px 63px 14px 56px;
      display: none;
    }
  }

  .total-columns {
    margin: auto;
  }

  .header-earned {
    flex-wrap: wrap;
  }

  .after-fees {
    h2 {
      font-size: 14px;
      line-height: 18px;
    }
    p {
      font-size: 14px;
      line-height: 18px;
      span {
        font-size: 16px;
        line-height: 20px;
        sup {
          font-size: 12px;
          line-height: 15px;
        }
      }
    }
  }

  .button-bottom {
    text-align: center;
    margin-bottom: -100px;
  }
}

@media only screen and (min-width: 992px) {
  .button-bottom {
    text-align: center;
    margin-bottom: -100px;
    display: none;
  }
}

@media only screen and (max-width: 480px) {
  .column {
    padding: 26px 21px 24px 21px;
    max-width: 216px;
    width: 100%;
  }
}

@media only screen and (max-width: 375px) {
  .column {
    max-width: 163px;
  }
}

@media only screen and (min-width: 1200px) {
  .column {
    max-width: 218px;
  }
}
</style>
