<template>
  <div class="dashboard-block chart" v-if="Object.keys(chartData).length > 0">
    <h2 class="dashboard-block__title">{{ $t("dashboard.chart_title") }}</h2>

    <div class="dashboard-block__subtitle">
      {{ $t("dashboard.chart_description") }}
    </div>

    <button class="bttn bttn--orange" type="button" name="button" @click="reinitChart">
      <svg viewBox="0 0 512 512">
        <path
          fill="currentColor"
          d="M370.7 133.3A168.2 168.2 0 0 0 93 214.9a12 12 0 0 1-11.6 9.1H24.1a12 12 0 0 1-11.8-14.2 248 248 0 0 1 415-133.1L463 41a24 24 0 0 1 41 17v134a24 24 0 0 1-24 24H346a24 24 0 0 1-17-41l41.7-41.7zM32 296h134a24 24 0 0 1 17 41l-41.7 41.7A168.2 168.2 0 0 0 419 297.1a12 12 0 0 1 11.6-9.1h57.3a12 12 0 0 1 11.8 14.2 248 248 0 0 1-415 133.1L49 471a24 24 0 0 1-41-17V320a24 24 0 0 1 24-24z"
        />
      </svg>
    </button>

    <div class="line-chart">
      <canvas id="lineCahrt"></canvas>
    </div>
  </div>
</template>

<script type="text/javascript">
import { mapState } from "vuex";

import Chart from "chart.js";

export default {
  data() {
    return {
      chart: null,
    };
  },

  computed: mapState({
    chartData: (state) => {
      return state.dashboard.campaignDashboard?.data?.attributes?.chart || {};
    },

    labelList() {
      return this.chartData?.label_list || [];
    },

    donationNArray: function () {
      let array = [];

      if (!this.chartData.data_set) return array;

      this.chartData.data_set.forEach((el) => {
        array.push(el.donation_n);
      });

      return array;
    },

    donationTotalAmtArray: function () {
      let array = [];

      if (!this.chartData.data_set) return array;

      this.chartData.data_set.forEach((el) => {
        array.push(el.donation_amt_total);
      });
      return array;
    },

    uniquePageVisits: function () {
      let array = [];

      if (!this.chartData.data_set) return array;

      this.chartData.data_set.forEach((el) => {
        array.push(el.unique_page_visit_n);
      });

      return array;
    },
  }),

  methods: {
    initialChart: function () {
      if (Object.keys(this.chartData).length === 0) return;

      Chart.defaults.global.defaultFontFamily = "VitoExtended-Light";
      this.chart = new Chart(document.getElementById("lineCahrt"), {
        type: "line",
        options: {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: true,
            labels: {
              boxWidth: 12,
              usePointStyle: false,
            },
          },
          scales: {
            yAxes: [
              {
                id: "count",
                type: "linear",
                ticks: {
                  beginAtZero: true,
                },
                gridLines: {
                  display: false,
                },
              },
              {
                id: "amount",
                type: "linear",
                position: "right",
                ticks: {
                  beginAtZero: true,
                },
                gridLines: {
                  display: false,
                },
              },
            ],
            xAxes: [
              {
                gridLines: {
                  display: false,
                },
              },
            ],
          },
        },
        data: {
          labels: this.labelList,
          datasets: [
            // remove until GA4 integration fixed https://app.clickup.com/t/85ztemtnt
            // {
            //   label: this.$t("dashboard.page_view_label_chart"),
            //   fill: false,
            //   lineTension: 0.2,
            //   backgroundColor: "#3CC7A0",
            //   borderColor: "#3CC7A0",
            //   borderCapStyle: "butt",
            //   borderDash: [],
            //   borderDashOffset: 0.0,
            //   borderJoinStyle: "miter",
            //   borderWidth: 2,
            //   pointBorderColor: "#3CC7A0",
            //   pointBackgroundColor: "#fff",
            //   pointBorderWidth: 1,
            //   pointHoverRadius: 5,
            //   pointHoverBackgroundColor: "#3CC7A0",
            //   pointHoverBorderColor: "rgba(220,220,220,1)",
            //   pointHoverBorderWidth: 2,
            //   pointRadius: 1,
            //   pointHitRadius: 0,
            //   data: this.uniquePageVisits,
            //   spanGaps: false,
            //   yAxisID: "count",
            // },
            {
              label: this.$t("dashboard.donation_count_label_chart"),
              fill: false,
              lineTension: 0.2,
              backgroundColor: "#F26E66",
              borderColor: "#F26E66",
              borderCapStyle: "butt",
              borderDash: [],
              borderDashOffset: 0.0,
              borderJoinStyle: "miter",
              borderWidth: 2,
              pointBorderColor: "#F26E66",
              pointBackgroundColor: "#fff",
              pointBorderWidth: 1,
              pointHoverRadius: 5,
              pointHoverBackgroundColor: "#F26E66",
              pointHoverBorderColor: "rgba(220,220,220,1)",
              pointHoverBorderWidth: 2,
              pointRadius: 1,
              pointHitRadius: 10,
              data: this.donationNArray,
              spanGaps: false,
              yAxisID: "count",
            },
            {
              label: this.$t("dashboard.donation_total_label_chart"),
              fill: false,
              lineTension: 0.2,
              backgroundColor: "#107598",
              borderColor: "#107598",
              borderCapStyle: "butt",
              borderDash: [],
              borderDashOffset: 0.0,
              borderJoinStyle: "miter",
              borderWidth: 2,
              pointBorderColor: "#107598",
              pointBackgroundColor: "#fff",
              pointBorderWidth: 1,
              pointHoverRadius: 5,
              pointHoverBackgroundColor: "#107598",
              pointHoverBorderColor: "rgba(220,220,220,1)",
              pointHoverBorderWidth: 2,
              pointRadius: 1,
              pointHitRadius: 10,
              data: this.donationTotalAmtArray,
              spanGaps: false,
              yAxisID: "amount",
            },
          ],
        },
      });
    },

    reinitChart() {
      this.chart.destroy();
      this.initialChart();
    },
  },

  mounted: function () {
    this.initialChart();
  },
};
</script>
