<template>
  <component :is="containerTag" :class="containerClass">
    <div class="row">
      <div class="col-12">
        <ValidationProvider rules="no_whitespace" v-slot="{ failedRules, validate }">
          <div class="form-group form-unit">
            <label class="form-unit__label form-unit__label--upper-sm">
              {{ $t("edit_campaign.generalinfo_name") }}
            </label>
            <input
              type="text"
              required
              class="form-control form-unit__input"
              :disabled="disabledByFieldOrDefault('campaignTitle')"
              :placeholder="$t('edit_campaign.generalinfo_name')"
              :value="campaignTitle"
              @input="
                emitWithValidate(validate, 'update:campaignTitle', $event.target.value, failedRules)
              "
            />
          </div>
        </ValidationProvider>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-6">
        <ValidationProvider rules="integer|numeric|min_value:1" v-slot="{ failedRules, validate }">
          <div class="form-group form-unit form-unit--addon goal-input">
            <label class="form-unit__label form-unit__label--upper-sm">{{
              $t("edit_campaign.generalinfo_goal_amount")
            }}</label>
            <div class="form-unit__addon-wrap">
              <div
                class="form-unit__addon-icon"
                :class="{ disabled: disabledByFieldOrDefault('campaignCurrency') }"
                v-if="disabledByFieldOrDefault('campaignCurrency')"
              >
                {{ campaignCurrency }}
              </div>
              <b-dropdown
                id="ddown1"
                v-else-if="!disabledByFieldOrDefault('campaignCurrency')"
                :text="campaignCurrency"
                class="dropdown"
              >
                <b-dropdown-item
                  v-for="(currency, idx) in currencies"
                  @click="updateCurrency(currency.attributes.code)"
                  :key="idx"
                >
                  {{ currency.attributes.code }}
                </b-dropdown-item>
              </b-dropdown>
              <input
                min="1"
                type="text"
                inputmode="numeric"
                pattern="[0-9]*"
                class="form-control form-unit__input"
                :disabled="disabledByFieldOrDefault('campaignPrimaryGoal')"
                :placeholder="$t('edit_campaign.generalinfo_goal_amount')"
                :value="campaignPrimaryGoal"
                @input="
                  emitWithValidate(
                    validate,
                    'update:campaignPrimaryGoal',
                    Number($event.target.value) || 1,
                    failedRules
                  )
                "
              />
            </div>
          </div>
        </ValidationProvider>
      </div>
      <div class="col-12 col-sm-6">
        <div class="form-group form-unit">
          <label class="form-unit__label form-unit__label--upper-sm">{{
            $t("edit_campaign.generalinfo_primary_goal_maching")
          }}</label>
          <div
            class="matching-input"
            :class="{
              'matching-input--disabled': disabledByFieldOrDefault('campaignPrimaryGoalMultiplier'),
            }"
          >
            <div class="matching-input__unit">
              <input
                class="matching-input__input"
                type="radio"
                name=""
                id="x1"
                :disabled="disabledByFieldOrDefault('campaignPrimaryGoalMultiplier')"
                :checked="campaignPrimaryGoalMultiplier === 1"
                :value="1"
                @change="$emit('update:campaignPrimaryGoalMultiplier', Number($event.target.value))"
              />
              <label class="matching-input__label" for="x1">{{
                $t("edit_campaign.generalinfo_x1", "x1")
              }}</label>
            </div>
            <div class="matching-input__unit">
              <input
                class="matching-input__input"
                type="radio"
                name=""
                id="x2"
                :disabled="disabledByFieldOrDefault('campaignPrimaryGoalMultiplier')"
                :checked="campaignPrimaryGoalMultiplier === 2"
                :value="2"
                @change="$emit('update:campaignPrimaryGoalMultiplier', Number($event.target.value))"
              />
              <label class="matching-input__label" for="x2">{{
                $t("edit_campaign.generalinfo_x2", "x2")
              }}</label>
            </div>
            <div class="matching-input__unit">
              <input
                class="matching-input__input"
                type="radio"
                name=""
                id="x3"
                :disabled="disabledByFieldOrDefault('campaignPrimaryGoalMultiplier')"
                :checked="campaignPrimaryGoalMultiplier === 3"
                :value="3"
                @change="$emit('update:campaignPrimaryGoalMultiplier', Number($event.target.value))"
              />
              <label class="matching-input__label" for="x3">{{
                $t("edit_campaign.generalinfo_x3", "x3")
              }}</label>
            </div>
            <div class="matching-input__unit">
              <input
                class="matching-input__input"
                type="radio"
                name=""
                id="x4"
                :disabled="disabledByFieldOrDefault('campaignPrimaryGoalMultiplier')"
                :checked="campaignPrimaryGoalMultiplier === 4"
                :value="4"
                @change="$emit('update:campaignPrimaryGoalMultiplier', Number($event.target.value))"
              />
              <label class="matching-input__label" for="x4">{{
                $t("edit_campaign.generalinfo_x4", "x4")
              }}</label>
            </div>
            <div class="matching-input__unit">
              <input
                class="matching-input__input"
                type="radio"
                name=""
                id="x5"
                :disabled="disabledByFieldOrDefault('campaignPrimaryGoalMultiplier')"
                :checked="campaignPrimaryGoalMultiplier === 5"
                :value="5"
                @change="$emit('update:campaignPrimaryGoalMultiplier', Number($event.target.value))"
              />
              <label class="matching-input__label" for="x5">{{
                $t("edit_campaign.generalinfo_x5", "x5")
              }}</label>
            </div>
            <div class="matching-input__unit">
              <input
                class="matching-input__input"
                type="radio"
                name=""
                id="x6"
                :disabled="disabledByFieldOrDefault('campaignPrimaryGoalMultiplier')"
                :checked="campaignPrimaryGoalMultiplier === 6"
                :value="6"
                @change="$emit('update:campaignPrimaryGoalMultiplier', Number($event.target.value))"
              />
              <label class="matching-input__label" for="x6">{{
                $t("edit_campaign.generalinfo_x6", "x6")
              }}</label>
            </div>
            <div class="matching-input__unit">
              <input
                class="matching-input__input"
                type="radio"
                name=""
                id="x7"
                :disabled="disabledByFieldOrDefault('campaignPrimaryGoalMultiplier')"
                :checked="campaignPrimaryGoalMultiplier === 7"
                :value="7"
                @change="$emit('update:campaignPrimaryGoalMultiplier', Number($event.target.value))"
              />
              <label class="matching-input__label" for="x7">{{
                $t("edit_campaign.generalinfo_x7", "x7")
              }}</label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-6">
        <div class="form-group form-unit form-unit--addon date-time-select">
          <label class="form-unit__label form-unit__label--upper-sm">{{
            $t("edit_campaign.generalinfo_start_date")
          }}</label>
          <div class="form-unit__addon-wrap">
            <div
              class="form-unit__addon-icon"
              :class="{ disabled: disabledByFieldOrDefault('campaignStartDateMemo') }"
            >
              <svg viewBox="0 0 24 22">
                <g fill="none" fill-rule="evenodd" opacity="1">
                  <path
                    stroke="#107598"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M4.64 1v3.64M19.18 1v3.64M1 2.82V21h14.55l7.27-7.27V2.82zM21.91 15.09v5h-5"
                  />
                  <path
                    fill="#107598"
                    fill-rule="nonzero"
                    d="M1 2.82V21h14.55l7.27-7.27V2.82H1zm6.36 14.54H5.55v-1.81h1.81v1.81zm0-3.63H5.55V11.9h1.81v1.82zm0-3.64H5.55V8.27h1.81v1.82zM11 17.36H9.18v-1.81H11v1.81zm0-3.63H9.18V11.9H11v1.82zm0-3.64H9.18V8.27H11v1.82zm3.64 7.27h-1.82v-1.81h1.82v1.81zm0-3.63h-1.82V11.9h1.82v1.82zm0-3.64h-1.82V8.27h1.82v1.82zm3.63 3.64h-1.82V11.9h1.82v1.82zm0-3.64h-1.82V8.27h1.82v1.82z"
                  />
                </g>
              </svg>
            </div>
            <datetime
              input-id="start-date"
              type="datetime"
              v-model="campaignStartDateMemo"
              :zone="timeZone"
              :disabled="disabledByFieldOrDefault('campaignStartDateMemo')"
              value-zone="UTC"
              input-class="form-control form-unit__input"
            ></datetime>
          </div>
        </div>
        <div class="time-zone-info">
          {{ $t("edit_campaign.generalinfo_your_time_zone_set") }}
          {{ timeZone }}
          <span v-if="!disabledByFieldOrDefault(null, null)"
            >,
            <a href="#" class="change-time-zone-button" @click="openModal">
              {{ $t("edit_campaign.generalinfo_click_here") }}
            </a>
            {{ $t("edit_campaign.generalinfo_to_change") }}
          </span>
        </div>
      </div>
      <div class="col-12 col-sm-6">
        <ViewByMeta
          metaFeatureName="display_campaign_end_date"
          :metas="metasInSelectedCampaign"
          v-slot="{ meta: displayCampaignEndDate }"
        >
          <div
            v-if="displayCampaignEndDate.value"
            class="form-group form-unit form-unit--addon date-time-select"
          >
            <label class="form-unit__label form-unit__label--upper-sm">{{
              $t(
                "edit_campaign.generalinfo_display_campaign_end_date",
                "Display campaign end date (countdown):"
              )
            }}</label>
            <div class="form-unit__addon-wrap">
              <div
                class="form-unit__addon-icon"
                :class="{ disabled: disabledByFieldOrDefault('campaignDisplayEndDateMemo') }"
              >
                <CampaignDateInputIcon />
              </div>
              <datetime
                input-id="end-date-meta"
                type="datetime"
                v-model="campaignDisplayEndDateMemo"
                :zone="timeZone"
                :disabled="disabledByFieldOrDefault('campaignDisplayEndDateMemo')"
                value-zone="UTC"
                input-class="form-control form-unit__input"
              ></datetime>
            </div>
            <b-form-text>
              {{
                $t(
                  "edit_campaign.generalinfo_display_campaign_end_date_help",
                  "Show countdown clock until this date. This is the time the campaign clock will reach 0. The donation form will remain open until the time specified in the FINISH DATE."
                )
              }}
            </b-form-text>
          </div>
        </ViewByMeta>
        <div class="form-group form-unit form-unit--addon date-time-select">
          <label class="form-unit__label form-unit__label--upper-sm">
            {{ $t("edit_campaign.generalinfo_finish_date", "Accept donations until:") }}
          </label>
          <div class="form-unit__addon-wrap">
            <div
              class="form-unit__addon-icon"
              :class="{ disabled: disabledByFieldOrDefault('campaignEndDateMemo') }"
            >
              <CampaignDateInputIcon />
            </div>
            <datetime
              input-id="end-date"
              type="datetime"
              v-model="campaignEndDateMemo"
              :zone="timeZone"
              :disabled="disabledByFieldOrDefault('campaignEndDateMemo')"
              value-zone="UTC"
              input-class="form-control form-unit__input"
            ></datetime>
          </div>
          <b-form-text>
            {{
              $t(
                "edit_campaign.generalinfo_finish_date_help",
                "Allow donations to the campaign after the campaign ends until this date."
              )
            }}
          </b-form-text>
        </div>
      </div>
    </div>
    <div class="row">
      <component :is="shortLinkTag" :class="shortLinkClass">
        <ValidationProvider rules="short_link" v-slot="{ failedRules, valid, validate }">
          <ShortlinkInput
            :valid="valid"
            :config="linkValidation"
            :value="campaignShortLink"
            :disabled="disabledByFieldOrDefault('campaignShortLink', 'readonlyLink')"
            @input="emitWithValidate(validate, 'update:campaignShortLink', $event, failedRules)"
            @validation-result="$emit('campaignShortLinkValidationResult', $event)"
            @validation="$emit('campaignShortLinkValidation', $event)"
          />
        </ValidationProvider>
      </component>
    </div>
    <change-timezone ref="changeTimeZone" />
  </component>
</template>

<script>
import { mapState } from "vuex";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import changeTimeZone from "@/views/campaigns/campaign-edit-vue/subcomponents/changeTimeZone.vue";
import ShortlinkInput from "@/views/campaigns/campaign-edit-vue/subcomponents/shortlink-input.vue";
import CampaignDateInputIcon from "@/views/campaigns/campaign-edit-vue/subcomponents/campaignDateInputIcon.vue";
import ViewByMeta from "@/views/campaigns/campaign-wizards/viewByMeta.vue";
import Tooltip from "@/common-components/tooltip.vue";
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    containerTag: { type: [String, Function], default: "div" },
    containerClass: { type: [String, Array, Object], default: "m-0 row w-100" },

    shortLinkTag: { type: [String, Function], default: "div" },
    shortLinkClass: {
      type: [String, Array, Object],
      default: "col-12 col-sm-6",
    },

    disabled: Boolean,
    readonly: Boolean,
    readonlyLink: Boolean,
    readonlyField: {
      type: Object,
      default: () => ({}),
    },

    linkValidation: { type: Object, default: () => ({}) },

    campaignTitle: { type: String, default: "" },
    campaignCurrency: { type: String, default: "" },
    campaignPrimaryGoal: { type: Number, default: 1 },
    campaignPrimaryGoalMultiplier: { type: Number, default: 1 },
    campaignShortLink: { type: String, default: "" },
    campaignStartDate: { type: Number, default: 0 },
    campaignStartDateString: { type: String, default: "" },
    campaignEndDate: { type: Number, default: 0 },
    campaignEndDateString: { type: String, default: "" },
    campaignDisplayEndDate: { type: Number, default: 0 },
    campaignDisplayEndDateString: { type: String, default: "" },
  },
  data() {
    return {};
  },
  components: {
    "change-timezone": changeTimeZone,
    ShortlinkInput,
    ValidationObserver,
    ValidationProvider,
    CampaignDateInputIcon,
    ViewByMeta,
    Tooltip,
  },
  computed: {
    ...mapState({
      currencies: (state) => state.account.currencyList,
      timeZone: (state) => {
        return state.account.account.data.attributes.timezone;
      },
      metas: (s) => s.campaign.metas,
    }),
    metasInSelectedCampaign() {
      return this.metas;
    },
    campaignStartDateMemo: {
      get() {
        return this.campaignStartDateString;
      },
      set(v) {
        this.$emit("update:campaignStartDate", new Date(v).getTime() / 1000);
        this.$emit("update:campaignStartDateString", v);
      },
    },
    campaignEndDateMemo: {
      get() {
        return this.campaignEndDateString;
      },
      set(v) {
        this.$emit("update:campaignEndDate", new Date(v).getTime() / 1000);
        this.$emit("update:campaignEndDateString", v);
      },
    },
    campaignDisplayEndDateMemo: {
      get() {
        return this.campaignDisplayEndDateString;
      },
      set(v) {
        this.$emit("update:campaignDisplayEndDate", new Date(v).getTime() / 1000);
        this.$emit("update:campaignDisplayEndDateString", v);
      },
    },
  },
  methods: {
    updateCurrency(val) {
      this.$emit("update:campaignCurrency", val.toLowerCase());
    },
    openModal: function (e) {
      e.preventDefault();
      this.$refs.changeTimeZone.openModal();
    },
    disabledByFieldOrDefault(field, type = "readonly") {
      if (Reflect.has(this.readonlyField, field)) {
        return !this.readonlyField[field];
      }

      return this[type] || this.disabled;
    },
    emitWithValidate(validate, event, ...args) {
      this.$emit(event, ...args);
      validate(...args);
    },
  },
});
</script>

<style lang="scss">
@import "../../../../styles/_vars.scss";
.goal-input {
  .dropdown {
    display: flex;
    min-width: 100px;
    min-height: 38px;
    height: 100%;
    &.open {
      .btn {
        background-color: #ffffff;
        border-color: rgba(32, 67, 78, 0.15);
      }
    }
    .btn {
      min-height: 50px;
      border-right: none;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-top: 1px solid #e6eaed !important;
      border-bottom: 1px solid #e6eaed !important;
      border-left: 1px solid #e6eaed !important;
      background-color: #ffffff;
      display: inline-flex;
      align-items: center;
      text-transform: uppercase;
      position: relative;
      color: #20434e;
      &:active,
      &:focus,
      &:hover {
        color: #20434e;
        outline: 0;
        background-color: #ffffff;
        border-color: rgba(32, 67, 78, 0.15);
        box-shadow: none;
      }
      span {
        position: absolute;
        top: 22px;
        right: 5px;
      }
    }
    .dropdown-toggle {
      min-width: 65px;
      font-size: 15px;
      position: relative;
      &:after {
        position: absolute;
        right: 6px;
        top: calc(50% - 1px);
      }
    }
    &-menu {
      min-width: initial;
      padding: 0;
      width: 100%;
      border-radius: 0;
    }
    &-item {
      height: 45px;
      width: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      color: gray;
      text-transform: uppercase;
      &:not(:last-of-type) {
        border-bottom: 1px solid rgba(32, 67, 78, 0.15);
      }
    }
  }
}

.date-time-select {
  .form-unit__addon-icon {
    display: none;
    @media only screen and (min-width: 992px) {
      display: flex;
    }
  }
  input.form-unit__input {
    @media only screen and (max-width: 991px) {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
  }
}
.form-unit--addon {
  .help-tooltip {
    margin-bottom: -3px;
  }
}
.form-unit__addon-wrap {
  .link-error,
  .link-success {
    font-size: 12px;
    position: absolute;
    bottom: -20px;
    right: 0;
  }
  .link-error {
    color: #f26e66;
  }
  .link-success {
    color: #3cc7a0;
  }
  .link-validate {
    color: $fd;
    display: inline-flex;
    align-items: center;
    height: 44px;
    position: absolute;
    top: 3px;
    right: 5px;
    > img {
      display: block;
      width: auto;
      height: 100%;
    }
  }
}
.time-zone-info {
  width: 100%;
  margin-bottom: 15px;
  display: inline-block;
  font-size: 16px;
  color: $fd;
  .change-time-zone-button {
    color: $bb-100;
    text-decoration: underline;
    &:hover,
    &:focus {
      color: $bb-80;
    }
  }
}
</style>
