<template>
  <div class="color-basic__container">
    <b-row class="color-basic__pickers">
      <b-col cols="4" class="col-auto">
        <h6>{{ $t("edit_campaign.color-tool_primary_color", "Primary Color") }}</h6>
        <color-picker :set-color="primary" @changeColor="updatePrimary" />
      </b-col>
      <b-col cols="4" class="col-auto">
        <h6>{{ $t("edit_campaign.color-tool_secondary_color", "Secondary Color") }}</h6>
        <color-picker :set-color="secondary" @changeColor="updateSecondary" />
      </b-col>
    </b-row>
    <button class="bttn bttn--lg bttn--orange bttn--mw255" @click="saveColor()">
      {{ $t("edit_campaign.color-tool_button_save", "Save Colors") }}
    </button>
  </div>
</template>

<script>
import colorPicker from "./colorPicker.vue";
import axios from "axios";
import { mapState } from "vuex";
import urls from "@/constants/urls";

export default {
  props: {},
  data() {
    return {
      primary: "",
      secondary: "",
      metaId: "0",
    };
  },
  components: {
    colorPicker,
  },
  created() {
    this.getColor();
  },
  computed: {
    ...mapState({
      campaignId: (state) => state.campaign.campaign.data.id,
      orgId: (state) => state.account.orgId || localStorage.getItem("orgId"),
    }),
    validateColor() {
      let regExp = /^$|^#[0-9A-F]{6}$/i; //regex for validating hexcode (will only accept 6 digit hexcode or empty string)
      return regExp.test(this.primary) && regExp.test(this.secondary);
    },
  },
  methods: {
    updatePrimary(color) {
      this.primary = color;
    },
    updateSecondary(color) {
      this.secondary = color;
    },
    async getColor() {
      let token = localStorage.getItem("jwt_token") || null;
      await axios
        .get(urls.getColor.replace(":orgId", this.orgId).replace(":campaignId", this.campaignId), {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then(({ data }) => {
          if (data.data.length > 0) {
            const color = JSON.parse(data.data[0].attributes.meta_data);
            this.updatePrimary(color?.colors?.primary);
            this.updateSecondary(color?.colors?.secondary);
            this.metaId = data.data[0].id;
          }
        })
        .catch(this.$_notificationsMixin_handleCatch);
    },
    async saveColor() {
      if (!this.validateColor) {
        this.$_notificationsMixin_makeToast(
          "Save Failed",
          this.$t(
            "edit_campaign.failure_hexcode",
            "Please enter a valid hexcode for both primary and secondary color"
          ),
          "danger"
        );
        return;
      }
      let token = localStorage.getItem("jwt_token") || null;
      const metaData = {
        colors: {
          primary: this.primary,
          secondary: this.secondary,
        },
      };
      const payload = {
        data: {
          type: "campaign_meta",
          id: this.metaId,
          attributes: {
            campaign_id: parseInt(this.campaignId),
            meta_name: "campaign_colors",
            meta_data: JSON.stringify(metaData),
          },
        },
      };
      if (payload.data.id === "0") {
        await axios
          .post(
            urls.saveColor.replace(":orgId", this.orgId).replace(":campaignId", this.campaignId),
            payload,
            { headers: { Authorization: `Bearer ${token}` } }
          )
          .then(() => {
            this.$_notificationsMixin_makeToast(
              "Success",
              this.$t("edit_campaign.success_edit"),
              "success"
            );
          })
          .catch(this.$_notificationsMixin_handleCatch);
      } else {
        await axios
          .put(
            urls.saveColor.replace(":orgId", this.orgId).replace(":campaignId", this.campaignId),
            payload,
            { headers: { Authorization: `Bearer ${token}` } }
          )
          .then(() => {
            this.$_notificationsMixin_makeToast(
              "Success",
              this.$t("edit_campaign.success_edit"),
              "success"
            );
          })
          .catch(this.$_notificationsMixin_handleCatch);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.color-basic {
  &__container {
    padding: 10px 0px;
  }
  &__pickers {
    padding-bottom: 20px;
  }
}
</style>
