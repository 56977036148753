<template>
  <div class="card-body">
    <slot name="above-form"></slot>

    <form-generetor
      v-model="settindFormData"
      :form="`form-${name}`"
      :schemeName="name"
      :scheme="scheme"
      :submit="save"
      :components="customComponents"
    />

    <div class="d-flex justify-content-end">
      <slot
        name="extra-buttons"
        :setting-form-data="settindFormData"
        :setting-data="setting"
        :isActive="isActive"
      ></slot>

      <b-btn
        class="bttn bttn--sm bttn--orange"
        :form="`form-${name}`"
        type="submit"
        :disabled="loading"
        :style="{ gap: '10px' }"
      >
        <b-spinner v-if="loading" small></b-spinner>
        {{ $t("edit_campaign.setting_save", "Save") }}
      </b-btn>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { notificationsMixin } from "@/mixins";
import formGeneretor from "@/common-components/form-generetor/form-generetor";
import { OpenMediaStoragePreview, OnOkMediaStoragePreview } from "@/constants/providers";
import axios from "axios";
import urls from "@/constants/urls";
import GoalCategoriesSection from "./goal-categories-section.vue";
import UpsellAmountEnum from "./upsell-amount-enum.vue";
import TeamMultiselectSearch from "./team-multiselect-search.vue";
import LanguageSelect from "./language-select.vue";
import CurrenciesSelect from "./currencies-select.vue";
import GatewaySettingsSelect from "./gateway-settings-select.vue";
import { deepCopy } from "@/helpers/calendarModule";
import { useCampaignSettings } from "@/composables/campaign-settings";
import { defineComponent } from "vue";

export default defineComponent({
  setup() {
    const { createSetting, updateSetting, getSetting } = useCampaignSettings();

    return {
      createSetting,
      updateSetting,
      getSetting,
    };
  },
  inject: {
    OpenMediaStoragePreview,
    OnOkMediaStoragePreview,
  },

  components: { formGeneretor },

  props: {
    name: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    scheme: {
      type: Object,
      default: () => null,
    },
    remap: {
      type: Function,
      default: (schemaData) => schemaData,
    },
  },

  mixins: [notificationsMixin],

  data() {
    return {
      setting: null,
      settindFormData: {},
      loading: false,
    };
  },

  computed: {
    ...mapState({
      oID: () => localStorage.getItem("orgId"),
      cID: (state) => state.campaign.campaign.data.id,
    }),

    customComponents() {
      return {
        GoalCategoriesSection,
        UpsellAmountEnum,
        TeamMultiselectSearch,
        LanguageSelect,
        CurrenciesSelect,
        GatewaySettingsSelect,
      };
    },

    hasFilePreview() {
      let hasFilePreviewAtleastOnOneEntry = null;
      const schemeProperties = Object.entries(this.scheme?.properties || {});

      for (const [k, v] of schemeProperties) {
        if (v?.type === "image" && v?.preview && this.settindFormData[k] instanceof File) {
          hasFilePreviewAtleastOnOneEntry = k;
          break;
        }
      }

      return hasFilePreviewAtleastOnOneEntry;
    },

    isActive() {
      const b = this.setting;
      return b?.attributes?.meta_data?.value || false;
    },
  },

  mounted() {
    this.wrapGetSetting();
  },

  methods: {
    async wrapGetSetting() {
      try {
        const setting = await this.getSetting(
          {
            cid: this.cID,
            oid: this.oID,
            extraParams: {
              falback_to_meta_dir: 1,
            },
          },
          this.name
        );

        if (!setting) {
          return;
        }

        if (setting.id != "0") {
          this.setting = setting;
        }
        if (
          setting.id === "0" &&
          Object.keys(setting.attributes.default_meta_value_off).length > 0
        ) {
          this.settindFormData = setting.attributes.default_meta_value_off;
        } else {
          this.settindFormData = deepCopy(setting.attributes.meta_data);
        }

        if (this.setting) {
          this.settindFormData = deepCopy(this.setting.attributes.meta_data);
        }

        return setting;
      } catch (e) {
        this.$_notificationsMixin_handleCatch(e);
        throw e;
      }
    },

    save() {
      const __save = async () => {
        this.loading = true;

        if (this.setting && this.setting.id != "0") {
          try {
            return await this.wrapUpdateSetting();
          } finally {
            this.loading = false;
          }
        }

        try {
          return await this.wrapAddSetting();
        } finally {
          this.loading = false;
        }
      };

      if (this.hasFilePreview) {
        this.OpenMediaStoragePreview(
          this.settindFormData[this.hasFilePreview],
          this.$t(
            "edit_campaign.setting_dspc_banner_image_size",
            "Recommended image size is 200x200 pixels"
          )
        );
        this.OnOkMediaStoragePreview(__save);

        return;
      }

      if (!this.scheme?.noValidateChild) {
        const parentForm = document.getElementById(`form-${this.name}`);
        const childForm = parentForm.querySelectorAll("form");
        for (const form of childForm) {
          if (!form?.checkValidity()) {
            return form?.reportValidity();
          }
        }
      }

      __save();
    },

    getMediaUrl(file) {
      const sendFileOrBlob = async (file) => {
        const formData = new FormData();
        formData.append("file", file);

        const response = await axios.post(urls.loadImageQuill, formData);
        return response.data.src;
      };

      return new Promise((resolve) => {
        if (file instanceof File) {
          sendFileOrBlob(file).then((src) => {
            resolve({ url: src, file_name: file.name });
          });
        } else if (file instanceof Blob) {
          sendFileOrBlob(file).then((src) => resolve(src));
        } else {
          resolve(file);
        }
      });
    },

    async customDataMap(data, scheme) {
      const newData = this.remap(data);

      for (const [dataKey, dataValue] of Object.entries(newData)) {
        const propValue = scheme.properties[dataKey] || {};
        if (dataValue instanceof File) {
          const media = await this.getMediaUrl(dataValue);

          if (propValue?.fileName) {
            newData[dataKey] = media;
          } else {
            newData[dataKey] = media?.url || media;
          }
        } else if (Object.hasOwn(propValue, "$if")) {
          if (!propValue.$if(data)) {
            newData[dataKey] = propValue.default;
          }
        } else {
          newData[dataKey] = dataValue;
        }
      }

      return newData;
    },

    async wrapAddSetting() {
      const data = await this.customDataMap(this.settindFormData, this.scheme);

      try {
        const setting = await this.createSetting({ cid: this.cID, oid: this.oID }, this.name, data);

        this.$_notificationsMixin_makeToast(
          "Success",
          this.$t("edit_campaign.setting_saved", "Saved successfully"),
          "success"
        );
        this.setting = setting;
        if (this.setting) {
          this.settindFormData = deepCopy(this.setting.attributes.meta_data);
        }

        return setting;
      } catch (e) {
        this.$_notificationsMixin_handleCatch(e);
        throw e;
      }
    },

    async wrapUpdateSetting() {
      const data = await this.customDataMap(this.settindFormData, this.scheme);

      try {
        const setting = await this.updateSetting(
          { cid: this.cID, oid: this.oID },
          this.name,
          this.setting.id,
          data
        );

        this.$_notificationsMixin_makeToast(
          "Success",
          this.$t("edit_campaign.setting_saved", "Saved successfully"),
          "success"
        );
        this.setting = setting;
        if (this.setting) {
          this.settindFormData = deepCopy(this.setting.attributes.meta_data);
        }

        return setting;
      } catch (e) {
        this.$_notificationsMixin_handleCatch(e);
        throw e;
      }
    },
  },
});
</script>

<style lang="scss" scoped></style>
