<template>
  <multiselect-search
    v-model="optionSelected"
    :open-prefetch="true"
    :allow-empty="allowEmpty"
    :search-url="getSearchUrl"
    :search-options="searchOptions"
    :taggable="taggable"
    :multiple="multiple"
    :multiselectClass="{ 'multiselect-charidy': !defaultStyles }"
    skip-external-serching
    :closeOnSelect="true"
    class="w-100"
    v-if="!initialLoading"
  >
  </multiselect-search>
</template>

<script>
import urls from "@/constants/urls";
import { mapGetters, mapState } from "vuex";
import MultiselectSearch from "@/components/multiselect-search.vue";
import axios from "axios";
import { getFavoriteCampaign, setFavoriteCampaign } from "@/helpers/storage";

const extendParams = [
  "campaign_stats",
  "media",
  "meta",
  "donation_levels",
  "donation_streams",
  "url_alias",
];

export default {
  components: {
    MultiselectSearch,
  },
  props: {
    value: {
      type: [Number, String, Object, Array],
      default: 0,
    },
    getFullCampaign: Boolean,
    defaultStyles: Boolean,
    preselectFirst: Boolean,
    allowEmpty: Boolean,
    taggable: Boolean,
    multiple: Boolean,
    perPage: {
      type: Number,
      default: 100,
    },
    general: Boolean,
  },

  data() {
    return {
      innerSelected: null,
      initialLoading: false,
    };
  },

  created() {
    this.initialLoading = true;
    const cmpId = getFavoriteCampaign();
    if (cmpId) {
      this.getCampaignData(cmpId).finally(() => {
        this.initialLoading = false;
      });
    }
  },

  methods: {
    async getCampaignData(cid) {
      const orgID = localStorage.getItem("orgId");

      const { data } = await axios.get(
        urls.getCampaignData.replace(":orgId", orgID).replace(":campaignId", cid)
      );

      if (data.data) {
        this.innerSelected = {
          id: data.data.id,
          ...data.data.attributes,
        };
        this.optionSelected = this.innerSelected;
        setFavoriteCampaign(data.data.id);
        this.$store.commit("tools/setCampaign", data.data);
      }
    },
  },

  computed: {
    ...mapState({
      toolsCampaigns: (s) => s.tools.campaigns,
    }),
    ...mapGetters({}),

    campaigns() {
      return this.toolsCampaigns || [];
    },

    optionSelected: {
      set(val) {
        this.innerSelected = val;
        setFavoriteCampaign(val.id);
        this.$emit("input", val.id);
        this.$store.commit("tools/setCampaign", val);
      },
      get() {
        return this.innerSelected;
      },
    },

    getSearchUrl() {
      const orgID = localStorage.getItem("orgId");

      return urls.getCampaignList.replace(":orgId", orgID);
    },

    searchOptions() {
      return {
        placeholder: "Select campaign",
        changeParams: ({ page }) => ({
          page: page,
          perPage: this.perPage,
          sort_by: ["-startdate", "campaign_mode_dashboard"],
          extend: extendParams,
        }),
        customLabel: (e) => `[${e.id}] ${e.title}`,
        processResults: (data) =>
          data.map((el) => ({
            id: el.id,
            ...el.attributes,
            __origin: el,
          })),
        onListUpdate: (data) => {
          if (data?.length >= this.campaigns?.length) {
            const list = data.map((el) => el.__origin);
            this.$store.commit("setCampaigns", list);
          }
        },
        onIncludedUpdate: (data) => {
          this.$store.commit("setIncludedMedia", data);
        },
      };
    },
  },

  watch: {
    value: {
      handler(cid) {
        if (cid && typeof cid === "number" && this.innerSelected?.id !== cid) {
          this.getCampaignData(cid);
        }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .multiselect-charidy {
  width: 100%;
  min-height: 60px;
  box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.1);
  cursor: pointer;
  .multiselect__select {
    height: 58px;
    &:before {
      top: 60%;
    }
  }
  .multiselect__option span {
    display: block;
    word-wrap: break-word;
    width: 100%;
    white-space: normal;
  }
  .multiselect__tags {
    min-height: 60px;
  }
  .multiselect__single,
  .multiselect__placeholder {
    font-size: 20px;
    min-height: 40px;
    line-height: 40px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @media only screen and (min-width: 768px) {
      font-size: 26px;
    }
    @media only screen and (min-width: 992px) {
      font-size: 30px;
    }
  }
  .multiselect__input {
    min-height: 40px;
    line-height: 40px;
  }
}
</style>
