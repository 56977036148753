<template>
  <div>
    <slot />
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { ValidateShortlink, LinkValidationConfigWizard } from "@/constants/providers";
import { useShortlinkValidation } from "@/composables/shortlink-validation";

export default {
  provide() {
    return {
      [ValidateShortlink]: this.validate,
      [LinkValidationConfigWizard]: this.getLinkValidationConfigWizard,
    };
  },
  setup() {
    const { validateByCampaign, validateByOrg } = useShortlinkValidation();

    return {
      validateByCampaign,
      validateByOrg,
    };
  },

  computed: {
    ...mapState({
      orgId: (state) => state.account.orgId,
    }),
    ...mapGetters({
      getCurrentlyEditedInWizard: "getCurrentlyEditedInWizard",
    }),
  },

  methods: {
    async validate(shortLink, config) {
      const {
        data: {
          data: { attributes },
        },
      } = await this.getValidationPromise(shortLink, config);
      return [attributes.available, attributes.shortlink];
    },
    getValidationPromise(shortLink, config) {
      if (config?.type === "campaign") {
        return this.validateByCampaign({
          oid: config.orgId,
          cid: config.campaignId,
          shortLink,
        });
      }
      if (config?.type === "org") {
        return this.validateByOrg({
          oid: config.orgId,
          shortLink,
        });
      }
      return Promise.reject();
    },
    getLinkValidationConfigWizard() {
      const orgId = this.orgId || localStorage.getItem("orgId");

      if (this.getCurrentlyEditedInWizard) {
        return {
          type: "campaign",
          orgId: orgId,
          campaignId: this.getCurrentlyEditedInWizard,
        };
      }

      return { type: "org", orgId };
    },
  },
};
</script>
