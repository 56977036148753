<script>
import SelectDonorCampaign from "./select-donor-campaign.vue";

export default {
  components: {
    SelectDonorCampaign,
  },
  props: {
    getProtectedDonorReport: {
      type: Function,
      required: true,
    },
    generateFileProtectedDonorReport: {
      type: Function,
      required: true,
    },
    computedReportParams: {
      type: Object,
      required: true,
    },
    setReportParams: {
      type: Function,
      required: true,
    },
    loadingAction: {
      type: Function,
      required: true,
    },
    page: {
      type: Number,
      required: true,
    },
    perPage: {
      type: Number,
      required: true,
    },
    setPagination: {
      type: Function,
      required: true,
    },
    total: {
      type: Number,
      default: 0,
    },
    list: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    checkDuplicatesOptions: {
      type: Array,
      default: () => [],
    },
    teamId: {
      type: Number,
      default: 0,
    },
    loadersCanBeUnlocked: Boolean,
  },
  data() {
    return {
      pastCampaignList: [],
      selectedRows: [],
      opt: {
        headers: [
          "$selected",
          "id",
          "billing_name",
          "display_name",
          "charge_amount",
          "effective_amount",
          "email",
          "phone",
          "teams",
          "date",
          "actions",
        ],
        headerKey: (h) => h?.key || h,
        headersTkFallbacks: [
          "Selected",
          "Donation ID",
          "Donor Billing Name",
          "Display Name",
          "Amount charged",
          "Effective Amount",
          "Email",
          "Phone",
          "Teams",
          "Donation date",
          "Actions",
        ],
        classes: {
          teams: "wp-pre-line",
        },
        formatters: {
          charge_amount(value, key, item) {
            return `${value} ${item.currency_code}`;
          },
          effective_amount(value, key, item) {
            return `${value} ${item.currency_code}`;
          },
          date(v) {
            const d = new Date(v);
            return d.toLocaleString("en-US");
          },
          teams(value, key, item) {
            return (item.team_list || [])
              .map((t) => {
                return `${t.id}: ${t.name}`;
              })
              .join("\n");
          },
        },
      },
    };
  },
  computed: {
    headers() {
      const getForrmater = (h) => {
        return this.opt.formatters?.[h];
      };
      const getClass = (h) => {
        return this.opt.classes?.[h];
      };

      return this.opt.headers.map((h, i) => ({
        key: this.opt.headerKey?.(h) || `attributes.${h}`,
        label: this.$t(`reports.${h}`, this.opt.headersTkFallbacks?.[i]),
        formatter: getForrmater(h) || this.formatter,
        class: getClass(h) || "",
        sortable: h?.sortable || false,
      }));
    },
  },
  methods: {
    onGenerateFileProtectedDonorReport() {
      this.loadingAction(this.generateFileProtectedDonorReport());
    },
    onGetProtectedDonorReport() {
      this.loadingAction(this.getProtectedDonorReport());
    },
    setPastCampaignList(value) {
      this.pastCampaignList = value;
      this.setReportParams(
        "past_campaign_id",
        value.map((e) => e.campaign_id)
      );
    },
    onRowSelected(items) {
      this.selectedRows = items;
    },
  },
};
</script>

<template>
  <div>
    <portal to="report-past-campaign-donors-outreach-pagination">
      <b-pagination
        :value="page"
        @input="$emit('input', setPagination($event, perPage))"
        :total-rows="total"
        :per-page="perPage"
        aria-controls="report-past-campaign-donors-outreach-table"
        class="m-0 justify-content-start"
        :disabled="!loadersCanBeUnlocked"
      ></b-pagination>
    </portal>
    <portal to="report-past-campaign-donors-outreach-actions">
      <button
        @click="onGetProtectedDonorReport()"
        class="bttn bttn--sm bttn--orange"
        :disabled="loading || !loadersCanBeUnlocked"
      >
        <b-spinner v-if="loading" small class="mr-2"></b-spinner>
        {{ $t("reports.reload_btn", "Load") }}
      </button>
      <b-button
        class="mx-1 bttn bttn--sm bttn--orange text-nowrap"
        @click="onGenerateFileProtectedDonorReport()"
        :disabled="loading || !loadersCanBeUnlocked"
      >
        <b-spinner class="mr-2" v-if="loading" small></b-spinner>
        {{ $t("reports.download_btn", "Download") }}
      </b-button>
    </portal>

    <b-form-group
      id="reports-fieldset-select-campaign"
      :label="$t('reports.filters_outreach_title', 'Select campaign')"
      label-for="reports-input-select-campaign"
      class="my-4 form-unit--multiselect"
    >
      <SelectDonorCampaign
        :value="computedReportParams.campaign_id"
        disabled
        default-styles
        general
        :per-page="50"
        :teamId="teamId"
      />
    </b-form-group>
    <b-form-group
      id="reports-fieldset-select-campaign"
      :label="$t('reports.past_campaign_title', 'Select past campaign')"
      class="my-4 form-unit--multiselect"
    >
      <SelectDonorCampaign
        :value="pastCampaignList"
        @input="setPastCampaignList($event)"
        default-styles
        general
        :multiple="true"
        :taggable="true"
        :allow-empty="true"
        :per-page="50"
        :teamId="teamId"
      />
    </b-form-group>
    <b-form-group #default="{ ariaDescribedby }">
      <b-form-radio-group
        :value="computedReportParams.compare_by"
        :checked="computedReportParams.compare_by"
        @input="setReportParams('compare_by', $event)"
        :options="checkDuplicatesOptions"
        :aria-describedby="ariaDescribedby"
        stacked
      ></b-form-radio-group>
    </b-form-group>
    <b-table
      bordered
      responsive
      :fields="headers"
      :items="list"
      :busy="loading"
      selectable
      no-local-sorting
      @row-selected="onRowSelected"
    >
      <template #cell($selected)="{ rowSelected }">
        <template v-if="rowSelected">
          <b-form-checkbox
            :checked="true"
            :value="true"
            :unchecked-value="false"
            :disabled="true"
          />
          <span class="sr-only">Selected</span>
        </template>
        <template v-else>
          <b-form-checkbox
            :checked="false"
            :value="true"
            :unchecked-value="false"
            :disabled="true"
          />
          <span class="sr-only">Not selected</span>
        </template>
      </template>
      <template v-slot:table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong class="mx-2">Loading...</strong>
        </div>
      </template>
    </b-table>
  </div>
</template>

<style lang="scss" scoped>
.messsaging-btns {
  display: flex;
  @media screen and (max-width: 1440px) {
    flex-direction: column;
    .bttn.bttn-round {
      margin: 2px 0;
    }
  }
}
</style>
