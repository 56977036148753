<script>
import { mapState } from "vuex";
import SelectCampaign from "@/views/dashboard/subcomponents/selectCampaign.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  components: {
    SelectCampaign,
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    state: { type: Object, default: () => ({}) },
    setState: { type: Function, default: () => {} },
    submitByFormId: { type: String, default: "" },
    options: { type: Object, default: () => ({}) },
  },
  data() {
    return {
      showAdditionalBankAccountFields: false,
    };
  },
  computed: {
    ...mapState({
      frabaf: (state) => state.featureHide.foundationRequestAdditionalBankAccountFields,
    }),
    foundationRequestAdditionalBankAccountFields() {
      if (!this.frabaf && this.showAdditionalBankAccountFields) {
        return true;
      }

      return this.frabaf;
    },
  },
  methods: {
    handleSubmit(event, validate) {
      const { submitter } = event;
      const context = {
        continue: submitter.dataset.context === "continue",
      };

      return validate()
        .then(() => this.$emit("can-continue", context))
        .finally(() => this.$emit("loading", false));
    },
    setStateFormData(key, value) {
      const formData = {
        ...this.state.formData,
        [key]: value,
      };
      this.setState({
        ...this.state,
        formData: formData,
      });
    },
    errorFormat(error, msg) {
      return error && typeof error === "string" && error?.replace("{field}", msg);
    },
  },
};
</script>

<template>
  <div v-bind="{}">
    <ValidationObserver v-slot="{ validate }">
      <form :id="submitByFormId" @submit.prevent="handleSubmit($event, validate)">
        <div class="row">
          <div class="col-12">
            <p>
              {{ $t("account.request_foundation_modal_form_data", "Please fill the form below") }}
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <ValidationProvider
              v-slot="{ errors }"
              rules="required"
              :persist="true"
              vid="campaign_id"
            >
              <b-form-group
                :state="errors.length === 0"
                :invalid-feedback="
                  errorFormat(errors[0], $t('account.select_campaign', 'Select campaign'))
                "
              >
                <label class="tw-w-full">
                  {{ $t("account.select_campaign", "Select campaign") }}
                  <SelectCampaign
                    name="campaign_id"
                    :allowEmpty="true"
                    defaultStyles
                    v-model="state.formData.campaign_id"
                  />
                </label>
              </b-form-group>
            </ValidationProvider>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <ValidationProvider
              v-slot="{ errors }"
              rules="required"
              :persist="true"
              vid="organization_name"
            >
              <b-form-group
                :state="errors.length === 0"
                :invalid-feedback="
                  errorFormat(errors[0], $t('account.organization_name', 'Organization name'))
                "
              >
                <label class="tw-w-full">
                  {{ $t("account.organization_name", "Organization name") }}
                  <b-form-input
                    name="organization_name"
                    v-model="state.formData.organization_name"
                  />
                </label>
              </b-form-group>
            </ValidationProvider>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <ValidationProvider v-slot="{ errors }" rules="required" :persist="true" vid="ngo_id">
              <b-form-group
                :state="errors.length === 0"
                :invalid-feedback="errorFormat(errors[0], $t('account.ngo_id', 'NGO ID'))"
              >
                <label class="tw-w-full">
                  {{ $t("account.ngo_id", "NGO ID") }}
                  <b-form-input name="ngo_id" v-model="state.formData.ngo_id" type="text" />
                </label>
              </b-form-group>
            </ValidationProvider>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <ValidationProvider
              v-slot="{ errors }"
              rules="required"
              :persist="true"
              vid="bank_account_name"
            >
              <b-form-group
                :state="errors.length === 0"
                :invalid-feedback="
                  errorFormat(errors[0], $t('account.bank_account_name', 'Account name'))
                "
              >
                <label class="tw-w-full">
                  {{ $t("account.bank_account_name", "Account name") }}
                  <b-form-input
                    name="bank_account_name"
                    v-model="state.formData.bank_account_name"
                  />
                </label>
              </b-form-group>
            </ValidationProvider>
          </div>
        </div>
        <div class="row" v-if="!frabaf">
          <div class="col-12">
            <b-form-group>
              <b-form-checkbox
                id="foundation_request_us_based_toggle"
                v-model="showAdditionalBankAccountFields"
                name="foundation_request_us_based_toggle"
              >
                {{ $t("account.foundation_request_us_based_toggle", "US based?") }}
              </b-form-checkbox>
            </b-form-group>
          </div>
        </div>
        <div class="row" v-if="foundationRequestAdditionalBankAccountFields">
          <div class="col-12">
            <ValidationProvider
              v-slot="{ errors }"
              rules="required"
              :persist="true"
              vid="bank_account_number"
            >
              <b-form-group
                :state="errors.length === 0"
                :invalid-feedback="
                  errorFormat(
                    errors[0],
                    $t('account.bank_account_number', 'Account number or IBAN#')
                  )
                "
              >
                <label class="tw-w-full">
                  {{ $t("account.bank_account_number", "Account number or IBAN#") }}
                  <b-form-input
                    name="bank_account_number"
                    v-model="state.formData.bank_account_number"
                  />
                </label>
              </b-form-group>
            </ValidationProvider>
          </div>
        </div>
        <div class="row" v-if="foundationRequestAdditionalBankAccountFields">
          <div class="col-12">
            <ValidationProvider
              v-slot="{ errors }"
              rules="required"
              :persist="true"
              vid="bank_account_swift"
            >
              <b-form-group
                :state="errors.length === 0"
                :invalid-feedback="
                  errorFormat(errors[0], $t('account.bank_account_swift', 'ABA# or SWIFT Code'))
                "
              >
                <label class="tw-w-full">
                  {{ $t("account.bank_account_swift", "ABA# or SWIFT Code") }}
                  <b-form-input
                    name="bank_account_swift"
                    v-model="state.formData.bank_account_swift"
                  />
                </label>
              </b-form-group>
            </ValidationProvider>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6">
            <ValidationProvider
              v-slot="{ errors }"
              rules="required"
              :persist="true"
              vid="bank_address"
            >
              <b-form-group
                :state="errors.length === 0"
                :invalid-feedback="
                  errorFormat(errors[0], $t('account.bank_address', 'Bank address'))
                "
              >
                <label class="tw-w-full">
                  {{ $t("account.bank_address", "Bank address") }}
                  <b-form-input name="bank_address" v-model="state.formData.bank_address" />
                </label>
              </b-form-group>
            </ValidationProvider>
          </div>
          <div class="col-12 col-md-6">
            <ValidationProvider
              v-slot="{ errors }"
              rules="required"
              :persist="true"
              vid="bank_address_zip_code"
            >
              <b-form-group
                :state="errors.length === 0"
                :invalid-feedback="
                  errorFormat(errors[0], $t('account.bank_address_zip_code', 'Bank ZIP code'))
                "
              >
                <label class="tw-w-full">
                  {{ $t("account.bank_address_zip_code", "Bank ZIP code") }}
                  <b-form-input
                    name="bank_address_zip_code"
                    v-model="state.formData.bank_address_zip_code"
                  />
                </label>
              </b-form-group>
            </ValidationProvider>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <ValidationProvider
              v-slot="{ errors }"
              rules="required"
              :persist="true"
              vid="appeal_reason"
            >
              <b-form-group
                :state="errors.length === 0"
                :invalid-feedback="
                  errorFormat(errors[0], $t('account.appeal_reason', 'Appeal reason'))
                "
              >
                <label class="tw-w-full">
                  {{ $t("account.appeal_reason", "Appeal reason") }}
                  <b-form-input name="appeal_reason" v-model="state.formData.appeal_reason" />
                </label>
              </b-form-group>
            </ValidationProvider>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>
