<script>
import { notificationsMixin } from "@/mixins";
import axios from "axios";
import urls from "@/constants/urls";
import { mapState } from "vuex";
import { useCampaignSettings } from "@/composables/campaign-settings";
import { defineComponent } from "vue";

export default defineComponent({
  setup() {
    const { createSetting, updateSetting, getSetting } = useCampaignSettings();

    return {
      createSetting,
      updateSetting,
      getSetting,
    };
  },
  mixins: [notificationsMixin],
  props: {
    settingScheme: {
      type: Object,
      default: () => ({}),
    },
    remap: {
      type: Function,
      default: (schemaData) => schemaData,
    },
  },
  data() {
    return {
      settingsPool: {},
      loading: false,
      firstTimeLoad: false,
    };
  },
  computed: {
    ...mapState({
      oID: () => localStorage.getItem("orgId"),
      cID: (state) => state.campaign.campaign.data.id,
    }),
    settings() {
      return Object.keys(this.settingScheme);
    },
  },
  mounted() {
    this.firstTimeLoad = true;
    this.setSetting().finally(() => {
      this.firstTimeLoad = false;
    });
  },
  methods: {
    async setSetting() {
      try {
        const settings = await Promise.all(
          this.settings.map((name) =>
            this.getSetting(
              {
                cid: this.cID,
                oid: this.oID,
                extraParams: {
                  falback_to_meta_dir: 1,
                },
              },
              name
            ).then((setting) => ({ name, setting }))
          )
        );

        for (const { name, setting } of settings) {
          this.settingsPool[name] = {};

          if (setting?.id != "0") {
            this.settingsPool[name].data = setting;
          }

          if (setting?.id === "0" && setting.attributes.default_meta_value_off) {
            this.settingsPool[name].formData = setting.attributes.default_meta_value_off;
          }

          const settingData = this.settingsPool[name].data;
          if (settingData) {
            this.settingsPool[name].formData = setting.attributes.meta_data;
          }
        }

        return settings;
      } catch (e) {
        this.$_notificationsMixin_handleCatch(e);
        throw e;
      }
    },
    getMediaUrl(file) {
      const sendFileOrBlob = async (file) => {
        const formData = new FormData();
        formData.append("file", file);

        const response = await axios.post(urls.loadImageQuill, formData);
        return response.data.src;
      };

      return new Promise((resolve) => {
        if (file instanceof File) {
          sendFileOrBlob(file).then((src) => {
            resolve({ url: src, file_name: file.name });
          });
        } else if (file instanceof Blob) {
          sendFileOrBlob(file).then((src) => resolve(src));
        } else {
          resolve(file);
        }
      });
    },
    async customDataMap(data, scheme) {
      const newData = this.remap(data);
      const schemeProperties = Object.entries(scheme.properties);

      for (const [dataKey, dataValue] of Object.entries(newData)) {
        if (dataValue instanceof File) {
          const [, propValue] = schemeProperties.find(([schemeKey]) => schemeKey === dataKey);
          const media = await this.getMediaUrl(dataValue);

          if (propValue?.fileName) {
            newData[dataKey] = media;
          } else {
            newData[dataKey] = media?.url || media;
          }
        } else {
          newData[dataKey] = dataValue;
        }
      }

      return newData;
    },
    async addSetting(name) {
      try {
        if (!this.settingsPool[name]) {
          return Promise.reject();
        }

        const data = await this.customDataMap(
          this.settingsPool[name].formData,
          this.settingScheme[name]
        );
        const setting = await this.createSetting({ cid: this.cID, oid: this.oID }, name, data);

        this.$_notificationsMixin_makeToast(
          "Success",
          this.$t("edit_campaign.setting_saved", "Saved successfully"),
          "success"
        );

        const settingData = this.settingsPool[name].data;
        this.settingsPool[name].data = setting;
        if (settingData) {
          this.settingsPool[name].formData = setting.attributes.meta_data;
        }

        return setting;
      } catch (e) {
        this.$_notificationsMixin_handleCatch(e);
        throw e;
      }
    },
    async editSetting(name) {
      try {
        if (!this.settingsPool[name]) {
          return Promise.reject();
        }

        const data = await this.customDataMap(
          this.settingsPool[name].formData,
          this.settingScheme[name]
        );
        const setting = await this.updateSetting(
          { cid: this.cID, oid: this.oID },
          name,
          this.settingsPool[name].data.id,
          data
        );

        this.$_notificationsMixin_makeToast(
          "Success",
          this.$t("edit_campaign.setting_saved", "Saved successfully"),
          "success"
        );

        const settingData = this.settingsPool[name].data;
        this.settingsPool[name].data = setting;
        if (settingData) {
          this.settingsPool[name].formData = setting.attributes.meta_data;
        }

        return setting;
      } catch (e) {
        this.$_notificationsMixin_handleCatch(e);
        throw e;
      }
    },
    async save() {
      const __save = async () => {
        this.loading = true;

        try {
          return await Promise.all(
            this.settings.map((name) => {
              if (this.settingsPool[name]?.data) {
                return this.editSetting(name);
              }

              return this.addSetting(name);
            })
          );
        } finally {
          this.loading = false;
        }
      };

      return await __save();
    },
    formData(name) {
      return this.settingsPool[name]?.formData || {};
    },
  },
});
</script>

<template>
  <div v-if="!firstTimeLoad">
    <slot :settingsPool="settingsPool" :formData="formData" :save="save" :loading="loading" />
  </div>
</template>
