<template>
  <div>
    <div v-if="!disableTabs" class="inner-tabs">
      <div
        class="inner-tabs__unit"
        :class="{ active: layoutStepComp === '1' }"
        @click="setLayoutStep('1')"
      >
        {{ $t("edit_campaign.layout_images_tab") }}
      </div>
      <div
        class="inner-tabs__unit"
        :class="{ active: layoutStepComp === '2' }"
        @click="setLayoutStep('2')"
      >
        {{ $t("edit_campaign.layout_template_tab") }}
      </div>
    </div>

    <div v-if="layoutStepComp === '2'">
      <campaign-templates
        v-model="selectedTemplate"
        :current="campaignTemplate"
        :data="campaignTemplates"
      />

      <div class="templates-section-controls">
        <button
          :disabled="!hasAccess || selectedTemplate === campaignTemplate"
          class="bttn bttn--lg bttn--orange bttn--mw255"
          @click="saveSelectedTemplate"
        >
          {{ $t("edit_campaign.generalinfo_save") }}
        </button>
      </div>
    </div>

    <div v-if="layoutStepComp === '1'" class="campaign-images">
      <error v-if="errors" :errors="errors"></error>

      <div class="drop-down-lang">
        <p>{{ $t("edit_campaign.select_lang_image") }}</p>
        <b-dropdown
          variant="-sm bttn--blue"
          right
          :text="
            campaignLangImagesSelect === ''
              ? $t('edit_campaign.lang_image_default')
              : getFullLang(campaignLangImagesSelect)
          "
        >
          <b-dropdown-item @click="$emit('update:campaignLangImagesSelect', '')">
            {{ $t("edit_campaign.lang_image_default") }}
          </b-dropdown-item>
          <b-dropdown-item
            v-for="(lang, index) in langs"
            :key="index"
            @click="$emit('update:campaignLangImagesSelect', lang.short)"
          >
            {{ lang.long }}
          </b-dropdown-item>
        </b-dropdown>
      </div>

      <b-row>
        <b-col>
          {{ $t("edit_campaign.layout_video_description") }}
        </b-col>
      </b-row>
      <b-row class="campaign-video">
        <CampaignVideoLinkInput
          tag="video"
          :title="$t('edit_campaign.campaign_video')"
          :disabled="!hasAccess"
          :placeholder="$t('edit_campaign.campaign_video_placeholder')"
          :langImagesSelect="campaignLangImagesSelect"
          :campaignId="campaignId"
          :disableVideoLinksButtons="disableVideoLinksButtons"
          :value="campaignVideoLink"
          @input="$emit('update:campaignVideoLink', $event)"
        />
      </b-row>
      <b-row class="campaign-video">
        <CampaignVideoLinkInput
          tag="countdown_video"
          :title="$t('edit_campaign.countdown_video', 'Countdown video link:')"
          :disabled="!hasAccess"
          :placeholder="$t('edit_campaign.countdown_video_placeholder', 'Enter video link')"
          :langImagesSelect="campaignLangImagesSelect"
          :campaignId="campaignId"
          :disableVideoLinksButtons="disableVideoLinksButtons"
          :value="campaignCountdownVideoLink"
          @input="$emit('update:campaignCountdownVideoLink', $event)"
        />
      </b-row>

      <div class="images-section">
        <h4 class="images-section__title">{{ $t("edit_campaign.count_down_image") }}</h4>

        <div class="images-preview">
          <div class="images-preview__sliders">
            <div class="item-slot item-slot--s-desktop">
              <slider-laptop
                tag="countdown_hero"
                :imageset="countDownImage"
                @cUpdFile="changeFile"
                @cDelFile="removeFile"
                @cUplFile="processFile"
                @changeOrderUp="changeOrderUp"
                @changeOrderDown="changeOrderDown"
                :load="load"
                screen="t0"
              >
              </slider-laptop>
            </div>

            <div class="item-slot item-slot--s-mobile">
              <slider-mobile
                tag="countdown_hero_mobile"
                :imageset="countDownMobileImage"
                @cUpdFile="changeFile"
                @cDelFile="removeFile"
                @cUplFile="processFile"
                @changeOrderUp="changeOrderUp"
                @changeOrderDown="changeOrderDown"
                :load="load"
                screen="m0"
              >
              </slider-mobile>
            </div>

            <div class="item-slot item-slot--c-desktop">
              <ButtonUpload
                :hasAccess="hasAccess"
                id="countdown_hero"
                @change="processFile($event, 'countdown_hero')"
              />
              <span class="img-cimages__size-text">{{
                $t("edit_campaign.image_for_desktop_sizes")
              }}</span>
            </div>

            <div class="item-slot item-slot--c-mobile">
              <ButtonUpload
                :hasAccess="hasAccess"
                mobile
                id="countdown_hero_mobile"
                @change="processFile($event, 'countdown_hero_mobile')"
              />
              <span class="img-cimages__size-text">{{
                $t("edit_campaign.image_for_mobile_sizes")
              }}</span>
            </div>
          </div>
        </div>
      </div>

      <!-- STANDARD SECTION -->
      <div
        class="images-section"
        v-if="someTemplate(['standard', 'standard2', 'standard-v2', 'super-givingday'])"
      >
        <h4 class="images-section__title">{{ $t("edit_campaign.standart_template_image") }}</h4>

        <div class="images-preview">
          <div class="images-preview__sliders">
            <div class="item-slot item-slot--s-desktop">
              <slider-laptop
                tag="slider"
                :imageset="sliderImage"
                @cUpdFile="changeFile"
                @cDelFile="removeFile"
                @cUplFile="processFile"
                @changeOrderUp="changeOrderUp"
                @changeOrderDown="changeOrderDown"
                :load="load"
                screen="t1"
              >
              </slider-laptop>
            </div>

            <div class="item-slot item-slot--s-mobile">
              <slider-mobile
                tag="slider"
                :imageset="sliderImage"
                @cUpdFile="changeFile"
                @cDelFile="removeFile"
                @cUplFile="processFile"
                @changeOrderUp="changeOrderUp"
                @changeOrderDown="changeOrderDown"
                :load="load"
                screen="m0"
              >
              </slider-mobile>
            </div>

            <div class="item-slot item-slot--c-common">
              <ButtonUpload
                :hasAccess="hasAccess"
                id="slider"
                shared
                @change="processFile($event, 'slider')"
              >
                <template #label>
                  {{ $t("edit_campaign.upload_image_slider", "Upload slider image") }}
                </template>
              </ButtonUpload>
              <span class="img-cimages__size-text">{{
                $t(
                  "edit_campaign.image_for_standard_slider",
                  "for the best quality, recommended size 1200px x 630px"
                )
              }}</span>
            </div>
          </div>
        </div>
      </div>

      <!-- UNIDY SECTION -->
      <div class="images-section" v-if="someTemplate(['unidy', 'unidy2', 'unidy-v2'])">
        <h4 class="images-section__title">{{ $t("edit_campaign.select_campaign_image") }}</h4>

        <div class="images-preview">
          <div class="images-preview__sliders">
            <div class="item-slot item-slot--s-desktop">
              <slider-laptop
                tag="slider"
                :imageset="heroImage"
                @cUpdFile="changeFile"
                @cDelFile="removeFile"
                @cUplFile="processFile"
                @changeOrderUp="changeOrderUp"
                @changeOrderDown="changeOrderDown"
                :load="load"
                screen="t2"
              >
              </slider-laptop>
            </div>

            <div class="item-slot item-slot--s-mobile">
              <slider-mobile
                tag="campaign_hero_mobile"
                :imageset="heroMobile"
                @cUpdFile="changeFile"
                @cDelFile="removeFile"
                @cUplFile="processFile"
                @changeOrderUp="changeOrderUp"
                @changeOrderDown="changeOrderDown"
                :load="load"
                screen="m0"
              >
              </slider-mobile>
            </div>

            <div class="item-slot item-slot--c-desktop">
              <ButtonUpload
                :hasAccess="hasAccess"
                id="slider"
                @change="processFile($event, 'slider')"
              />
              <span class="img-cimages__size-text">{{
                $t("edit_campaign.image_for_desktop_sizes")
              }}</span>
            </div>

            <div class="item-slot item-slot--c-mobile">
              <ButtonUpload
                :hasAccess="hasAccess"
                mobile
                id="campaign_hero_mobile"
                @change="processFile($event, 'campaign_hero_mobile')"
              />
              <span class="img-cimages__size-text">{{
                $t("edit_campaign.image_for_mobile_sizes")
              }}</span>
            </div>
          </div>
        </div>
      </div>

      <!-- DINNER SPARKLE -->
      <div class="images-section" v-if="campaignTemplate === 'dinner-sparkle'">
        <h4 class="images-section__title">
          {{ $t("edit_campaign.dinner_sparkle_top_right_image") }}
        </h4>

        <div class="images-preview">
          <div class="images-preview__sliders">
            <div class="item-slot item-slot--s-desktop">
              <slider-laptop
                tag="dinner_top_right"
                screen="t3"
                only-one
                :imageset="dinnerTopRight"
                @cUpdFile="changeFile"
                @cDelFile="removeFile"
                @cUplFile="processFile"
                @changeOrderUp="changeOrderUp"
                @changeOrderDown="changeOrderDown"
                :load="load"
              >
              </slider-laptop>
            </div>

            <div class="item-slot item-slot--s-mobile">
              <slider-mobile
                tag="dinner_top_right"
                screen="m0"
                only-one
                :imageset="dinnerTopRight"
                @cUpdFile="changeFile"
                @cDelFile="removeFile"
                @cUplFile="processFile"
                @changeOrderUp="changeOrderUp"
                @changeOrderDown="changeOrderDown"
                :load="load"
              >
              </slider-mobile>
            </div>

            <div class="item-slot item-slot--c-common">
              <ButtonUpload
                v-if="dinnerTopRight == 0"
                :hasAccess="hasAccess"
                id="dinner_top_right"
                @change="processFile($event, 'dinner_top_right')"
              />
              <span class="img-cimages__size-text text-break">
                {{ $t("edit_campaign.image_for_dinner_sparkle_top_right_sizes") }}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="images-section">
        <h4 class="images-section__title">{{ $t("edit_campaign.shared_image") }}</h4>
        <p class="tw-text-orange-500">
          {{
            $t(
              "edit_campaign.shared_image_mobile_placeholder",
              "Shared images for mobile use the same images and controls as desktop"
            )
          }}
        </p>

        <div class="images-preview">
          <div class="images-preview__sliders">
            <div class="item-slot item-slot--s-desktop">
              <slider-laptop
                tag="shared_image"
                :imageset="shareImage"
                @cUpdFile="changeFile"
                @cDelFile="removeFile"
                @cUplFile="processFile"
                @changeOrderUp="changeOrderUp"
                @changeOrderDown="changeOrderDown"
                :load="load"
                screen="t3"
              >
              </slider-laptop>
            </div>

            <div class="item-slot item-slot--s-mobile">
              <slider-mobile
                tag="shared_image"
                :imageset="shareImage"
                @cUpdFile="changeFile"
                @cDelFile="removeFile"
                @cUplFile="processFile"
                @changeOrderUp="changeOrderUp"
                @changeOrderDown="changeOrderDown"
                :load="load"
                screen="m1"
              >
              </slider-mobile>
            </div>

            <div class="item-slot item-slot--c-common mb-4">
              <ButtonUpload
                :hasAccess="hasAccess"
                id="shared_image"
                shared
                @change="processFile($event, 'shared_image')"
              />
              <span class="img-cimages__size-text">{{
                $t("edit_campaign.image_for_desktop_mobile_sizes")
              }}</span>
            </div>

            <FacebookGraphScrapeRefresh
              :campaignId="campaignId"
              v-slot="{ refreshImages, loading, error }"
              class="item-slot item-slot--c-common"
            >
              <ButtonRefresh @click="refreshImages" :loading="loading" />
              <span v-if="!!error" class="img-cimages__size-text text-danger">
                {{ error }}
              </span>
              <span v-else class="img-cimages__size-text">
                {{
                  $t(
                    "edit_campaign.refresh_image_description",
                    "Using this button you can refresh sharing images"
                  )
                }}
              </span>
            </FacebookGraphScrapeRefresh>
          </div>
        </div>
      </div>
    </div>

    <div class="loading-image" v-if="loadingImage">
      <img src="https://editionsdelarose.com/wp-content/themes/edr/img/loading.gif" />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import axios from "axios";
import urls from "@/constants/urls.js";
import error from "@/components/errors.vue";
import ClickOutside from "vue-click-outside";
import sliderLaptop from "@/views/campaigns/campaign-images/sliderLaptopBig";
import sliderMobile from "@/views/campaigns/campaign-images/sliderMobileBig";
import { confirmMixin, notificationsMixin } from "@/mixins";
import CampaignTemplates from "./campaignTemplates.vue";
import ButtonUpload from "@/views/campaigns/campaign-images/buttonUpload.vue";
import ButtonRefresh from "@/views/campaigns/campaign-images/buttonRefresh.vue";
import CampaignVideoLinkInput from "./campaignVideoLinkInput.vue";
import { processImageFile, blobToFile } from "@/helpers/processImageFile";
import FacebookGraphScrapeRefresh from "@/components/facebook-graph-scrape-refresh.vue";

export default {
  props: {
    disableTabs: Boolean,
    disableVideoLinksButtons: Boolean,
    layoutStep: {
      type: String,
      default: "1",
    },

    campaignVideoLink: {
      type: String,
      default: "",
    },
    campaignCountdownVideoLink: {
      type: String,
      default: "",
    },
    campaignLangImagesSelect: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      load: false,
      loadingImage: false,
      errors: [],
      colors: {
        primary: "",
        secondary: "",
      },
      primaryPicker: false,
      secondaryPicker: false,
      layoutStepLocal: "1",
      selectedTemplate: null,
    };
  },
  mixins: [notificationsMixin, confirmMixin],
  components: {
    error: error,
    sliderLaptop: sliderLaptop,
    sliderMobile: sliderMobile,
    CampaignTemplates,
    CampaignVideoLinkInput,
    FacebookGraphScrapeRefresh,
    ButtonUpload,
    ButtonRefresh,
  },

  created() {
    this.getCampaignTemplates();
  },
  mounted() {
    this.selectedTemplate = this.campaignTemplate;
  },
  computed: {
    ...mapState({
      campaignId: (state) => {
        return state.campaign.campaign.data.id;
      },

      hasAccess: (state) => {
        return state.account.access.CanEditCampaignMedia;
      },

      campaignTemplate: (state) => {
        return state.campaign.campaign.data.attributes.template;
      },

      campaignTemplates: (state) => {
        return state.campaign.campaignTemplates;
      },

      orgId: function () {
        return localStorage.getItem("orgId");
      },

      langs() {
        return this.getOldLangsVariant;
      },

      allMediaArr: (state) => {
        let relationships = state.campaign.campaign.data.relationships.campaign_media.data;

        let included = state.campaign.campaign.included;

        let arrMedia = [];

        for (let i = 0; i < relationships.length; i++) {
          let el = included.filter((el) => {
            return el.id == relationships[i].id && el.type == relationships[i].type;
          });

          arrMedia.push(el[0]);
        }

        return arrMedia;
      },

      sliderImage: function () {
        return this.filterAllMedia("slider");
      },

      heroImage: function () {
        const sliderMedia = this.filterAllMedia("slider");
        const campaignHeroMedia = this.filterAllMedia("campaign_hero");

        return [...campaignHeroMedia, ...sliderMedia];
      },

      heroMobile: function () {
        return this.filterAllMedia("campaign_hero_mobile");
      },

      countDownImage: function () {
        return this.filterAllMedia("countdown_hero");
      },

      countDownMobileImage: function () {
        return this.filterAllMedia("countdown_hero_mobile");
      },

      shareImage: function () {
        return this.filterAllMedia("shared_image");
      },

      dinnerTopRight() {
        return this.filterAllMedia("dinner_top_right");
      },
    }),
    ...mapGetters({
      getOldLangsVariant: "getOldLangsVariant",
    }),
    layoutStepComp: {
      get() {
        if (this.layoutStepLocal !== this.layoutStep) {
          return this.layoutStepLocal;
        }
        return this.layoutStep;
      },
      set(v) {
        this.setLayoutStep(v);
      },
    },
  },

  methods: {
    ...mapActions({
      getCampaignTemplates: "getCampaignTemplates",
      updateSelectedTemplate: "updateSelectedTemplate",
      updateCampaignData: "updateCampaignData",
    }),

    someTemplate(templates) {
      return templates.some((t) => t === this.campaignTemplate);
    },

    filterAllMedia(tag) {
      const arr = this.allMediaArr.filter((el) => {
        return (
          el.attributes.tag === tag &&
          el.attributes.lang === this.campaignLangImagesSelect &&
          el.attributes.team_id === 0
        );
      });

      arr.sort((a, b) => b.attributes.order - a.attributes.order);

      return arr;
    },

    getFullLang(short) {
      const langSenected = this.getOldLangsVariant.filter((el) => el.short === short);
      return langSenected.length > 0 ? langSenected[0].long : "Default";
    },

    async saveSelectedTemplate() {
      let data = {
        data: {
          attributes: {
            code: this.selectedTemplate,
          },
        },
      };

      let orgId = localStorage.getItem("orgId");

      if (
        !(await this.$_confirmMixin_genericConfirm({
          message: this.$t(
            "edit_campaign.template_confirm_replace_images",
            "Do you want to replace all conflicting template media in this campaign?"
          ),
        }))
      ) {
        return;
      }

      const { status } = await axios.post(
        urls.campaignSetupTemplate.replace(":orgId", orgId).replace(":campaignId", this.campaignId),
        data
      );
      if (status === 200) {
        this.$_notificationsMixin_makeToast("Success", this.$t("account.success_edit"));
        this.updateCampaignData();
      }
    },

    setLayoutStep(value) {
      this.layoutStepLocal = value;
    },

    checkForFileSize(fileSize, tag) {
      switch (tag) {
        case "shared_image": {
          if (fileSize > 307200) {
            this.$bvModal.msgBoxOk(
              this.$t(
                "edit_campaign.upload_file_too_big_300kb",
                "File too big! Reduce the file size to 300KB or less."
              ),
              {
                title: this.$t("edit_campaign.upload_file_too_big_title", "Upload failed!"),
                okVariant: "danger",
                centered: true,
              }
            );

            return false;
          }

          break;
        }

        default: {
          if (fileSize > 2621440) {
            this.$bvModal.msgBoxOk(
              this.$t(
                "edit_campaign.upload_file_too_big_2_5mb",
                "File too big! Reduce the file size to 2.5MB or less."
              ),
              {
                title: this.$t("edit_campaign.upload_file_too_big_title", "Upload failed!"),
                okVariant: "danger",
                centered: true,
              }
            );

            return false;
          }

          break;
        }
      }

      return true;
    },

    processFile(fileInputEvent, tag) {
      this.load = true;
      this.errors = [];

      let fileInput = fileInputEvent.target.files[0];
      if (!fileInput) {
        return;
      }

      if (this.checkForFileSize(fileInput.size, tag)) {
        this.processFileCall(fileInputEvent, fileInput, tag);
      }
    },

    async processFileCall(e, fileInput, tag) {
      this.loadingImage = true;
      this.load = true;

      let form_data = new FormData();

      if (tag === "shared_image") {
        form_data.append("file", fileInput);
      } else {
        const originalName = fileInput.name;
        const webpImageBlob = await processImageFile(fileInput);
        const type = "image/webp";
        const ext = "webp";
        const file = blobToFile(webpImageBlob, `${originalName}.${ext}`, type);
        form_data.append("file", file);
      }

      try {
        const response = await axios.post(
          urls.loadImageQuill.replace(":OrgId", this.orgId),
          form_data
        );
        await this.createNewMediaFile(response.data.src, tag);

        e.target.type = "text";
        e.target.type = "file";
      } catch (e_1) {
        return this.handleCatch(e_1);
      } finally {
        this.loadingImage = false;
        this.load = false;
      }
    },

    async createNewMediaFile(src, tagImage) {
      this.errors = [];

      let mediaFile = {
        data: {
          attributes: {
            campaign_id: parseInt(this.campaignId),
            src: src,
            tag: String(tagImage),
            order: 1,
            lang: this.campaignLangImagesSelect,
          },
        },
      };

      await axios.post(
        urls.loadImage.replace(":orgId", this.orgId).replace(":campaignId", this.campaignId),
        mediaFile
      );
      this.$_notificationsMixin_makeToast("Success", this.$t("edit_campaign.success_add_image"));

      let extend = "?extend=campaign_stats&extend=content&extend=media&extend=matchers";
      await this.$store.dispatch("updateCampaign", extend);
    },

    async removeFile(image) {
      this.load = true;
      this.errors = [];

      try {
        await axios.delete(
          urls.loadImage.replace(":orgId", this.orgId).replace(":campaignId", this.campaignId) +
            "/" +
            image.id
        );
        this.$_notificationsMixin_makeToast(
          "Success",
          this.$t("edit_campaign.success_delete_image")
        );

        let extend = "?extend=campaign_stats&extend=content&extend=media&extend=matchers";
        await this.$store.dispatch("updateCampaign", extend);
      } catch (e) {
        return this.handleCatch(e);
      } finally {
        this.load = false;
      }
    },

    async changeFile(file, image) {
      this.errors = [];

      let imageData = {
        data: {
          attributes: image.attributes,
        },
      };

      if (this.checkForFileSize(file.size, image.attributes.tag)) {
        let form_data = new FormData();
        form_data.append("file", file);

        this.load = true;
        this.loadingImage = true;

        try {
          const response = await axios.post(
            urls.loadImageQuill.replace(":OrgId", localStorage.getItem("orgId")),
            form_data
          );
          imageData.data.attributes.src = response.data.src;
          await this.loadEditFile(imageData, image.id);
        } catch (e) {
          return this.handleCatch(e);
        } finally {
          this.loadingImage = false;
          this.load = false;
        }
      }
    },

    async loadEditFile(data, id) {
      let mediaTag = data.data.attributes.tag;
      await axios.put(
        `${urls.loadImage
          .replace(":orgId", this.orgId)
          .replace(":campaignId", this.campaignId)}/${id}`,
        data
      );
      this.$_notificationsMixin_makeToast(
        "Success",
        mediaTag !== "video" || mediaTag !== "countdown_video"
          ? this.$t("edit_campaign.success_edit_image")
          : this.$t("edit_campaign.success_edit_video")
      );

      let extend = "?extend=campaign_stats&extend=content&extend=media&extend=matchers";
      await this.$store.dispatch("updateCampaign", extend);
    },

    async changeOrder(image, order) {
      this.load = true;
      this.errors = [];

      let imageData = {
        data: {
          attributes: image.attributes,
        },
      };

      imageData.data.attributes.order = order;

      try {
        await axios.put(
          `${urls.loadImage
            .replace(":orgId", this.orgId)
            .replace(":campaignId", this.campaignId)}/${image.id}`,
          imageData
        );

        let extend = "?extend=campaign_stats&extend=content&extend=media&extend=matchers";
        await this.$store.dispatch("updateCampaign", extend);
      } catch (e) {
        return this.handleCatch(e);
      } finally {
        this.load = false;
      }
    },

    async changeOrderUp(image) {
      let order = image.attributes.order + 1;
      await this.changeOrder(image, order);
    },

    async changeOrderDown(image) {
      let order = image.attributes.order - 1;
      await this.changeOrder(image, order);
    },

    handleCatch(e) {
      if (e?.response?.data) {
        if (e?.response?.data?.error) {
          this.errors.push(e?.response?.data?.error);
        } else {
          this.errors.push(this.$t("template.error_default"));
        }
      } else {
        this.errors.push(this.$t("template.error_default"));
      }
    },

    pickerToggle(e) {
      e.target.classList.toggle("show");
    },

    primaryHide() {
      this.$refs.pickerP.classList.remove("show");
    },

    secondaryHide() {
      this.$refs.pickerS.classList.remove("show");
    },
  },

  directives: {
    ClickOutside,
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_vars.scss";

::v-deep .input-group-prepend,
::v-deep .input-group-append {
  align-items: center;
  margin: 0px 1em;
}

.bttn-round {
  margin-right: 10px;
}

.inner-tabs {
  display: flex;
  margin-bottom: 40px;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid rgba(196, 196, 196, 0.5);
  @media only screen and (min-width: 768px) {
    padding-left: 0;
    padding-right: 0;
    align-items: initial;
    // flex-direction: row;
  }
  &__unit {
    width: 100%;
    max-width: 140px;
    padding: 0 25px;
    height: 38px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 0;
    border: none; // 1px solid $bb-100;
    cursor: pointer;
    font-size: 14px;
    line-height: 1.125;
    @media only screen and (min-width: 768px) {
      font-size: 16px;
      max-width: 260px;
    }
    &:not(:last-of-type) {
      margin-bottom: 0;
      @media only screen and (min-width: 768px) {
        margin-bottom: initial;
        margin-right: 0;
      }
    }
    &.active {
      color: initial;
      background-color: initial;
      position: relative;
      &:after {
        content: "";
        display: block;
        width: 100%;
        height: 3px;
        background-color: #107598;
        position: absolute;
        bottom: -2px;
      }
    }
  }
}

.he {
  .inner-tabs {
    &__unit {
      &:not(:last-of-type) {
        @media only screen and (min-width: 768px) {
          margin-right: initial;
          margin-left: 0;
        }
      }
    }
  }
}

.loading-image {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.7);
  img {
    width: 150px;
  }
}

.img-cimages > label.img-cimages__label .desktop-mobile svg {
  margin-left: 5px;
  margin-right: 5px;
}

.load-image-input {
  display: none !important;
}

.inner-image {
  background-position: center center;
  background-size: cover;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  &__action {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 160px;
    .bttn-round {
      &:nth-of-type(2) {
        margin-right: 10px;
      }
      &:nth-of-type(1) {
        margin-right: auto;
      }
      &:nth-of-type(4) {
        margin-left: auto;
      }
    }
  }
}

.images-preview {
  &__sliders {
    display: flex;
    flex-wrap: wrap;
    .item-slot {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      @media only screen and (min-width: 992px) {
        width: 50%;
      }
      &--s-desktop,
      &--s-mobile {
        margin-bottom: 20px;
      }
      &--c-desktop {
        flex-direction: column;
        align-items: center;
        text-align: center;
        > span {
          width: 100%;
          max-width: 280px;
          font-size: 12px;
        }
      }
      &--c-mobile {
        flex-direction: column;
        align-items: center;
        text-align: center;
        > span {
          width: 100%;
          max-width: 280px;
          font-size: 12px;
        }
      }
      &--c-common {
        width: 100%;
        flex-direction: column;
        align-items: center;
        text-align: center;
        > span {
          width: 100%;
          max-width: 280px;
          font-size: 12px;
        }
      }
    }
    .item-slot--s-desktop {
      order: 0;
      @media only screen and (min-width: 1360px) {
        width: 60%;
      }
    }
    .item-slot--s-mobile {
      order: 2;
      @media only screen and (min-width: 992px) {
        order: initial;
      }
      @media only screen and (min-width: 1360px) {
        width: 40%;
      }
    }
    .item-slot--c-desktop {
      order: 1;
      margin-bottom: 15px;
      @media only screen and (min-width: 992px) {
        order: initial;
      }
      @media only screen and (min-width: 1360px) {
        width: 60%;
      }
    }
    .item-slot--c-mobile {
      order: 3;
      //marging-bottom: 15px;
      @media only screen and (min-width: 992px) {
        order: initial;
      }
      @media only screen and (min-width: 1360px) {
        width: 40%;
      }
    }
    .item-slot--c-common {
      order: 3;
      //marging-bottom: 15px;
      @media only screen and (min-width: 992px) {
        order: initial;
      }
    }
  }
}

.templates-section-controls {
  padding: 40px 15px 0 15px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.template-preview {
  display: inline-flex;
  padding: 0;
  background: transparent !important;
  border: none !important;
  color: $bb-100;
  font-size: 14px;
  text-decoration: underline;
  position: absolute;
  top: 5.7%;
  right: 10%;
}
.color-section {
  margin-bottom: 40px;
  &__inner {
    display: flex;
  }
  &__unit {
    width: 300px;
  }
}
.picker-block {
  width: 225px;
  height: 36px;
  position: relative;
  border: 1px solid #333333;
  cursor: pointer;
  &.show {
    .picker {
      display: block;
    }
  }
}

.picker {
  display: none;
  position: absolute;
  top: 102%;
  left: 0;
  z-index: 2;
}

.drop-down-lang {
  margin-bottom: 40px;
  width: 100%;
  flex-direction: column;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  p {
    margin: 0;
  }
}

.campaign-video {
  margin: 15px 0px 15px 0px;
  label {
    font-family: "VitoExtended-Bold";
    margin-top: 6px;
  }
}
</style>
