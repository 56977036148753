<template>
  <div>
    <b-card class="account-card" ref="accountSettings" v-if="accountSettings">
      <h2 slot="header" class="account-card__title">
        {{ $t("account.acc_setting") }}
      </h2>

      <div slot="footer" class="account-card__footer text-right" ref="account-card-footer">
        <button
          class="bttn bttn--sm bttn--orange"
          form="account-settings"
          :disabled="
            load || !hasAccess || vErrors.has('short_link') || vErrors.has('required_org_name')
          "
          type="submit"
        >
          {{ $t("account.save_change") }}
        </button>
      </div>

      <form id="account-settings" class="card-text" @submit.prevent="updateDetails">
        <error v-if="errors" :errors="errors"></error>

        <div class="account-card__selects">
          <div class="row">
            <div class="col-12">
              <b-form-group
                :label="$t('account.language')"
                label-for="language-select"
                label-class="form-unit__label--upper-sm"
                class="account-card__select account-card__inputs"
              >
                <b-form-select id="language-select" v-model="language" :options="languageList" />
              </b-form-group>
            </div>
            <div class="col-12">
              <b-form-group
                :label="$t('account.organization_language')"
                label-for="organization-language-select"
                label-class="form-unit__label--upper-sm"
                class="account-card__select account-card__inputs"
              >
                <b-form-select
                  :disabled="!hasAccess"
                  id="organization-language-select"
                  v-model="accountSettings.data.attributes.lang"
                  :options="languageList"
                />
              </b-form-group>
            </div>
            <div class="col-12">
              <div class="account-card__select account-card__inputs">
                <label
                  class="col-form-label pt-0 not-required form-unit__label form-unit__label--upper-sm"
                  >{{ $t("account.timezone") }}</label
                >

                <multiselect
                  :disabled="!hasAccess"
                  v-model="multiselectData"
                  :options="timezone"
                  label="text"
                  track-by="text"
                >
                </multiselect>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <h3 class="account-card__block-title">{{ $t("account.org_detalis") }} [{{ orgId }}]</h3>
          </div>
        </div>

        <div class="account-card__organization">
          <div class="row">
            <div class="col-12 col-lg-4">
              <div class="form-group account-card__upload">
                <label disabled class="form-unit__label--upper-sm"
                  >{{ $t("account.logo") }}
                  <div class="fileUpload" style="display: none">
                    <input
                      type="file"
                      :disabled="!hasAccess"
                      @change="uploadOrgLogo"
                      id="orgLogo"
                      accept="image/png, image/jpg, image/jpeg"
                    />
                  </div>

                  <div class="img-thumbnail img-thumbnail-wrap">
                    <div class="loading-image" v-if="loadImage">
                      <img
                        src="https://editionsdelarose.com/wp-content/themes/edr/img/loading.gif"
                      />
                    </div>
                    <img
                      v-if="accountSettings.data.attributes.logo"
                      :src="accountSettings.data.attributes.logo"
                      :alt="accountSettings.data.attributes.name"
                    />
                  </div>
                </label>
                <div class="image-controll">
                  <label
                    v-if="accountSettings.data.attributes.logo"
                    role="button"
                    :disabled="!hasAccess"
                    @click="deleteLogo"
                  >
                    <img :src="require('../../../assets/img/basket_white.png')" />
                  </label>
                  <label role="button" :class="{ disabled: !hasAccess }" for="orgLogo">
                    <!-- <img :src="require('../../../assets/img/cloud_white.png')"> -->
                    <b-icon-cloud-upload-fill variant="light" font-scale="1.3" />
                  </label>
                  <label
                    v-if="accountSettings.data.attributes.logo"
                    role="button"
                    :class="{ disabled: !hasAccess }"
                    @click="
                      openImage(
                        accountSettings.data.attributes.logo,
                        `${accountSettings.data.attributes.name}_logo`
                      )
                    "
                  >
                    <b-icon-cloud-download-fill variant="light" font-scale="1.3" />
                  </label>
                </div>
              </div>
            </div>

            <div class="col-12 col-lg-8">
              <b-form-group
                :label="$t('account.org_site')"
                label-for="org-url"
                label-class="form-unit__label--upper-sm"
                class="account-card__inputs"
              >
                <b-form-input
                  id="org-url"
                  :disabled="!hasAccess"
                  v-model="accountSettings.data.attributes.website"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                :label="$t('account.org_name')"
                label-for="org-name"
                label-class="form-unit__label--upper-sm"
                class="account-card__inputs"
              >
                <b-form-input
                  id="org-name"
                  :disabled="!hasAccess"
                  data-vv-as="field"
                  v-validate="'required'"
                  name="required_org_name"
                  :state="!hasRequiredOrgName"
                  aria-describedby="required_org_name_feedback"
                  v-model="accountSettings.data.attributes.name"
                ></b-form-input>
                <b-form-invalid-feedback id="required_org_name_feedback">
                  {{ $t("account.required_org_name_error") }}
                </b-form-invalid-feedback>
              </b-form-group>
            </div>

            <div class="col-12">
              <h3 class="account-card__block-title">
                {{ $t("account.org_languages", "Organization name for different languages") }}
              </h3>
              <div class="account-card__languages-add">
                <orgLanguages :hasAccess="hasAccess" />
              </div>
            </div>

            <div class="col-12">
              <b-form-group
                :label="$t('account.org_about')"
                label-for="org-about"
                label-class="form-unit__label--upper-sm"
                class="account-card__inputs"
              >
                <b-form-textarea
                  :disabled="!hasAccess"
                  id="org-about"
                  v-model="accountSettings.data.attributes.about"
                  :rows="3"
                  :max-rows="6"
                  :no-resize="true"
                >
                </b-form-textarea>
              </b-form-group>
            </div>
            <div class="col-12 form-unit switch-public">
              <label class="form-unit__label--upper-sm">
                {{ $t("account.switch_public_account") }}
              </label>
              <div class="switch-public--switch">
                <input
                  type="checkbox"
                  v-model="isPublic"
                  id="switch_public_account"
                  :disabled="!hasAccess"
                />
                <label for="switch_public_account"></label>
              </div>
            </div>

            <div class="col-12 form-unit sort-link">
              <b-form-group
                :label="$t('account.org_short_link')"
                label-for="short_link"
                label-class="form-unit__label--upper-sm"
                class="account-card__inputs"
                label-cols-sm="4"
                label-cols-lg="3"
                content-cols-sm
                content-cols-lg="7"
              >
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text>
                      <a class="text-lowercase">charidy.com/</a>
                    </b-input-group-text>
                  </template>
                  <b-form-input
                    type="text"
                    @input="validationLink"
                    id="short_link"
                    v-validate="'alpha_dash'"
                    name="short_link"
                    :disabled="!hasAccess"
                    v-model="accountSettings.data.attributes.short_link"
                  />
                </b-input-group>
                <div
                  v-show="!vErrors.has('short_link')"
                  class="link-error"
                  v-if="validationShortLink == 'false'"
                >
                  {{ $t("edit_campaign.generalinfo_short_link_allowe") }}
                </div>
                <div
                  v-show="!vErrors.has('short_link')"
                  class="link-validate"
                  v-if="validationShortLink == 'loading'"
                >
                  {{ $t("edit_campaign.generalinfo_short_link_validating") }}
                </div>
                <div
                  v-show="!vErrors.has('short_link')"
                  class="link-success"
                  v-if="validationShortLink == 'true'"
                >
                  {{ $t("edit_campaign.generalinfo_short_link_awailable") }}
                </div>
                <span v-show="vErrors.has('short_link')" class="v-help is-danger">
                  {{ $t("account.org_short_link_error_error") }}
                </span>
              </b-form-group>
            </div>
          </div>
        </div>

        <div class="account-card__entities">
          <div class="row">
            <div class="col-12">
              <h3 class="account-card__block-title">
                {{ $t("account.entities") }}
              </h3>
              <div class="account-card__entities-add">
                <FoundationRequestModal
                  v-if="showRequestCharidyFoundation"
                  ref="foundationRequestModal"
                />
                <entities-modal ref="entitiesModal"></entities-modal>
              </div>

              <error v-if="errorsEntities" :errors="errorsEntities"></error>

              <div v-for="(item, index) in entitiesList" :key="index" class="entities-row">
                <p class="entities-row__title">
                  <span>
                    [{{ item.id }} - {{ item.attributes.country }}] {{ item.attributes.name }}
                  </span>
                  <span class="entities-row__primary-badge" v-if="item.attributes.primary">
                    <b-badge variant="c-success"> {{ $t("account.primary", "Primary") }} </b-badge>
                  </span>
                </p>
                <div class="controll-button">
                  <button
                    type="button"
                    class="bttn-round btn-edit-entity"
                    :disabled="!hasAccess"
                    @click="editEntitie(item)"
                    name="button"
                  >
                    <i class="fa fa-pencil" aria-hidden="true" />
                  </button>
                  <button
                    type="button"
                    class="bttn-round btn-remove-entity"
                    :disabled="!hasAccess"
                    @click="deleteEntitie(item)"
                    name="button"
                  >
                    <i class="fa fa-trash-o" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="account-card__cprofile">
          <div class="row">
            <div class="col-12">
              <h3 class="account-card__block-title">
                {{ $t("account.contacts_title") }}
              </h3>

              <div class="account-card__cprofile-add">
                <cprofile-modal ref="contactModal"></cprofile-modal>
              </div>

              <div class="cprofile-row" v-for="(contact, index) in contactsLists" :key="index">
                <div class="cprofile-role">
                  {{ $t("account.contacts_type_" + contact.attributes.type) }}
                </div>

                <div class="cprofile-name">
                  {{ contact.attributes.first_name }}
                  {{ contact.attributes.last_name }}
                </div>

                <div class="controll-button">
                  <button
                    type="button"
                    :disabled="!hasAccess"
                    class="bttn-round btn-edit-contact"
                    @click="editContact(contact)"
                    name="button"
                  >
                    <i class="fa fa-pencil" aria-hidden="true" />
                  </button>

                  <button
                    type="button"
                    :disabled="!hasAccess"
                    class="bttn-round btn-remove-contact"
                    @click="deleteContact(contact)"
                    name="button"
                  >
                    <i class="fa fa-trash-o" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <error v-if="errors" :errors="errors"></error>
        </div>
      </form>
    </b-card>
    <b-card
      class="account-card account-card--global"
      v-if="accountCardFooterVisible"
      :style="{ '--_width': accountCardFooterVisibleWidth }"
    >
      <div slot="footer" class="account-card__footer text-right">
        <button
          class="bttn bttn--sm bttn--orange"
          form="account-settings"
          :disabled="
            load || !hasAccess || vErrors.has('short_link') || vErrors.has('required_org_name')
          "
          type="submit"
        >
          {{ $t("account.save_change") }}
        </button>
      </div>
    </b-card>
  </div>
</template>

<script>
import debounce from "lodash/debounce";
import entitiesModal from "./entitiesModal.vue";
import orgLanguages from "./orgLanguages.vue";
import cprofileModal from "./cprofileModal.vue";
import urls from "@/constants/urls.js";
import error from "@/components/errors.vue";
import { mapState } from "vuex";
import axios from "axios";
import Cookies from "js-cookie";
import Multiselect from "vue-multiselect";
import { getCookie } from "@/helpers/cookie.js";
import { errorsMixin, notificationsMixin } from "@/mixins";
import FoundationRequestModal from "../foundation-request/foundationRequestModal.vue";

export default {
  components: {
    entitiesModal,
    cprofileModal,
    error: error,
    Multiselect,
    orgLanguages,
    FoundationRequestModal,
  },

  mixins: [notificationsMixin, errorsMixin],

  data() {
    return {
      accountSettings: null,
      entitiesList: [],
      validationShortLink: "",
      shortLink: "",
      load: false,
      errors: [],
      errorsEntities: [],
      multiselectData: null,
      loadImage: false,
      isPublic: null,
      language: null,
      accountCardFooterVisible: false,
      accountCardFooterVisibleWidth: "calc(47% + 19px)",
    };
  },

  mounted() {
    this.language = getCookie("language");
    if (!this.language) {
      this.language = this.langSelected;
    }

    if (!this.accountSettings.data.attributes.lang) {
      this.accountSettings.data.attributes.lang = "en";
    }

    this.onIntersection(this.$refs["account-card-footer"], (visible) => {
      this.accountCardFooterVisible = !visible;
    });

    new ResizeObserver((entries) => {
      this.accountCardFooterVisibleWidth = `${entries[0].contentRect.width | 0}px`;
    }).observe(this.$refs.accountSettings);
  },

  computed: mapState({
    loading: (state) => {
      return state.account.loadingAccount;
    },

    hasAccess: (state) => {
      return state.account.access.CanEditOrganizationDetail;
    },

    organizationData: (state) => {
      return state.account.account;
    },

    loadingEntities: (state) => {
      return state.account.loadingEntities;
    },

    entitiesLists: (state) => {
      return state.account.entities;
    },

    loadingContacts: (state) => {
      return state.account.loadingCotacts;
    },

    contactsLists: (state) => {
      return state.account.contacts;
    },

    orgId: function () {
      return localStorage.getItem("orgId");
    },

    languageList: (state) => {
      let langArr = state.account.languages;

      let resultArr = [];

      for (var i = 0; i < langArr.length; i++) {
        let obj = {
          text: langArr[i].attributes.name,
          value: langArr[i].attributes.code,
        };

        resultArr.push(obj);
      }

      return resultArr;
    },

    timezone: (state) => {
      let timeZone = state.account.timeZones;

      let resultArr = [];

      for (var i = 0; i < timeZone.length; i++) {
        let obj = {
          text: timeZone[i].attributes.name,
          value: timeZone[i].attributes.timezone,
        };

        resultArr.push(obj);
      }

      return resultArr;
    },

    langSelected: (state) => state.translation.locate,

    hasRequiredOrgName() {
      return this.vErrors.has("required_org_name");
    },

    showRequestCharidyFoundation: (state) => state.featureHide.showRequestCharidyFoundation,
  }),

  watch: {
    timezone: function () {
      this.setTimezone();
    },

    isPublic(newVal, oldVal) {
      if (oldVal !== null) {
        this.changePublic();
      }
    },

    loadingEntities: function () {
      if (!this.loadingEntities) {
        this.setEntities();
      }
    },

    organizationData: function () {
      this.setOrganizationData();
      this.setPublic();
    },

    entitiesLists: function () {
      this.setEntities();
    },

    shortLink: function () {
      if (this.shortLink.length > 0) {
        this.startValidation();
      }
    },
  },

  methods: {
    onIntersection(element, callback) {
      new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          if (entry.intersectionRatio > 0) {
            callback(true, { element, observer });
          } else {
            callback(false, { element, observer });
          }
        });
      }).observe(element);
    },

    openImage(src) {
      const link = document.createElement("a");
      link.href = src;
      link.target = "_blank";
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      link.remove();
    },

    validationLink: debounce(function () {
      this.shortLink = this.accountSettings.data.attributes.short_link;
    }, 100),

    setPublic() {
      const { included = [] } = this.organizationData;
      const value = included.filter((el) => el.attributes.type === "org_public_page");
      if (value.length > 0) {
        this.isPublic = value[0].attributes.val_bool;
      }
    },

    changePublic() {
      const data = {
        data: {
          type: "customer_setting",
          attributes: {
            val_bool: this.isPublic,
            type: "org_public_page",
          },
        },
      };
      axios.post(urls.organizationAccountSettings.replace(":orgId", this.orgId), data).then(() => {
        this.$_notificationsMixin_makeToast("Success", this.$t("account.success_edit"));

        this.$store.dispatch("updateAccountOrg");
      });
    },

    startValidation: function () {
      this.validationShortLink = "loading";
      axios
        .get(urls.validationShortLinkOrg.replace(":orgId", this.orgId), {
          params: { q: this.shortLink, exclude_org_id: this.orgId },
        })
        .then((response) => {
          this.validationShortLink = response.data.data.attributes.available ? "true" : "false";
        })
        .catch((e) => {
          this.errors = this.$_errorsMixin_handleCatch(e);
        });
    },

    updateDetails() {
      this.load = true;

      this.errors = [];

      if (this.multiselectData) {
        this.accountSettings.data.attributes.timezone = this.multiselectData.value;
      }

      axios
        .patch(`${urls.editOrgInfo}/${localStorage.getItem("orgId")}`, this.accountSettings)
        .then(() => {
          this.languageChange(this.language);

          this.$_notificationsMixin_makeToast("Success", this.$t("account.success_edit"));

          this.$store.dispatch("updateAccountOrg");
        })
        .catch((e) => {
          this.errors = this.$_errorsMixin_handleCatch(e);
        })
        .finally(() => {
          this.load = false;
        });
    },

    setOrganizationData: function () {
      this.accountSettings = this.organizationData;

      if (!this.accountSettings.data.attributes.lang) {
        this.accountSettings.data.attributes.lang = Cookies.get("language");
      }

      this.setTimezone();
    },

    setTimezone: function () {
      if (this.timezone && !this.loading) {
        let timeForMultiselect = this.timezone.filter((el) => {
          return el.value == this.accountSettings.data.attributes.timezone;
        });

        this.multiselectData = timeForMultiselect[0];
      }
    },

    setEntities: function () {
      this.entitiesList = this.entitiesLists;
    },

    uploadOrgLogo: function (e) {
      this.errors = [];

      this.loadImage = true;

      let file = e.target.files[0];

      if (!file) return;

      let formData = new FormData();
      formData.append("file", file);

      axios
        .post(urls.uploadOrgLogo.replace(":orgId", this.orgId), formData)
        .then(() => {
          this.$store.dispatch("updateAccountOrg");

          this.$_notificationsMixin_makeToast(
            "Success",
            this.$t("account.success_edit"),
            "success"
          );
        })
        .catch((e) => {
          this.errors = this.$_errorsMixin_handleCatch(e);
        })
        .finally(() => {
          this.loadImage = false;
        });
    },

    deleteLogo: function () {
      this.errors = [];

      axios
        .delete(urls.uploadOrgLogo.replace(":orgId", this.orgId))
        .then(() => {
          this.$store.dispatch("updateAccountOrg");

          this.$_notificationsMixin_makeToast("Success", this.$t("account.success_edit"));
        })
        .catch((e) => {
          this.errors = this.$_errorsMixin_handleCatch(e);
        });
    },

    editEntitie: function (item) {
      this.$refs.entitiesModal.editEntities(item);
    },

    editContact: function (item) {
      this.$refs.contactModal.editContacts(item);
    },

    deleteEntitie: function (item) {
      this.errorsEntities = [];

      let message = this.$t("account.confirm_delete_entitie");

      let options = {
        okText: this.$t("account.confirm_delete"),
        cancelText: this.$t("account.confirm_cancel"),
      };

      this.$dialog.confirm(message, options).then(() => {
        axios
          .delete(`${urls.editEntitie.replace(":orgId", this.orgId)}/${item.id}`)
          .then(() => {
            this.$store.dispatch("getEntities");

            this.$_notificationsMixin_makeToast("Success", this.$t("account.success_delete"));
          })
          .catch((e) => {
            this.errorsEntities = this.$_errorsMixin_handleCatch(e);
          });
      });
    },

    deleteContact: function (item) {
      let message = this.$t("account.confirm_delete_contact");

      let options = {
        okText: this.$t("account.confirm_delete"),
        cancelText: this.$t("account.confirm_cancel"),
      };

      this.$dialog.confirm(message, options).then(() => {
        let orgId = localStorage.getItem("orgId");
        axios
          .delete(`${urls.putContact.replace(":orgId", orgId)}/${item.id}`)
          .then(() => {
            this.$store.dispatch("getContacts");

            this.$_notificationsMixin_makeToast("Success", this.$t("account.success_delete"));
          })
          .catch((e) => {
            this.errors = this.$_errorsMixin_handleCatch(e);
          });
      });
    },

    languageChange(lang) {
      this.$store.dispatch("setTranslationPermanent", lang);
    },
  },

  created: function () {
    this.setOrganizationData();

    this.setTimezone();

    this.setPublic();
  },
};
</script>

<style lang="scss" scoped>
@import "../../../styles/_vars.scss";
.he .account-card {
  &__inputs {
    &--shortlink {
      flex-direction: row-reverse;
    }
    &--static {
      direction: ltr;
    }
  }
}

.account-card {
  &--global {
    --_width: calc(47% + 19px);
    position: fixed;
    bottom: 0;
    margin-bottom: 0px;
    width: var(--_width);
    ::v-deep .card-body {
      display: none;
    }
  }

  &__block-title {
    margin: 0 0 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid lightgray;
    font-family: $vito-bold;
    font-size: 18px;
    color: $fd;
    text-transform: capitalize;
  }

  &__inputs {
    &--shortlink {
      display: flex;
      align-items: center;
      justify-content: center;
      input {
        width: auto;
        min-width: 60%;
        border-left: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
    &--static {
      min-width: 60px;
      height: 50px;
      padding: 0 10px;
      border-top: 1px solid #e6eaed;
      border-bottom: 1px solid #e6eaed;
      border-left: 1px solid #e6eaed;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      font-size: 14px;
      text-transform: uppercase;
      background-color: #f5faff;
      line-height: 50px;
    }
  }

  &__upload {
    label {
      display: inline-flex;
      flex-direction: column;
      align-items: flex-start;
      color: gray;
      cursor: pointer;
      position: relative;
      .img-thumbnail-wrap {
        width: 140px;
        height: 140px;
        position: relative;
      }
      img {
        height: 100%;
        width: 100%;
      }
      .loading-image {
        position: absolute;
        left: 5px;
        right: 5px;
        bottom: 5px;
        top: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(0, 0, 0, 0.6);
      }
    }
  }

  &__selects {
    margin-bottom: 20px;
    label {
      margin-bottom: 0;
    }
    select {
      height: 50px !important;
    }
  }

  &__organization {
    margin-bottom: 20px;
  }

  &__entities,
  &__cprofile {
    margin-bottom: 20px;
    .account-card__block-title {
      margin-bottom: 10px;
    }
  }

  &__entities-add,
  &__cprofile-add {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 30px;
  }
}

#app.he {
  .image-controll {
    justify-items: right;
  }
}

.image-controll {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 140px;
  label[role="button"],
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    background-color: #0084af;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 18px;
    border: none;
    margin-bottom: 0px;
    img {
      padding: 5px;
    }
    cursor: pointer;
    &:hover {
      background-color: lighten(#0084af, 10%);
    }
  }
  button[disabled] {
    opacity: 0.7;
    cursor: auto;
    &:hover {
      background-color: #0084af;
    }
  }
}

.entities-row,
.cprofile-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #f0f1f5;
  p {
    font-family: $vito-light;
    margin: 0;
  }
  .controll-button {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .bttn-round {
      margin-left: 10px;
    }
  }
}

.entities-row {
  &__title {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
  }

  &__primary-badge {
    &:deep(.badge) {
      color: white;
      font-size: inherit;
    }
  }
}

.sort-link {
  position: relative;
}

.link-error,
.link-success {
  font-size: 12px;
}
.link-error {
  color: #f26e66;
}
.switch-public {
  display: flex;
  justify-content: space-between;
  align-items: center;
  &--switch {
    input {
      display: block;
      opacity: 0;
    }
    label {
      width: 60px;
      height: 30px;
      cursor: pointer;
      display: inline-block;
      position: relative;
      background: rgb(189, 189, 189);
      border-radius: 30px;

      transition: background-color 0.4s;
      -moz-transition: background-color 0.4s;
      -webkit-transition: background-color 0.4s;
    }
    label:after {
      left: 0;
      width: 20px;
      height: 20px;
      margin: 5px;
      content: "";
      position: absolute;
      background: #fff;
      border-radius: 10px;
    }
    input:checked + label {
      background: #3cc7a0;
    }
    input:checked + label:after {
      left: auto;
      right: 0;
    }
  }
}
</style>
../foundation-request/foundationRequestModal.vue
