<script>
import AppSwitchToggle from "@/common-components/ui-elements/app-switch-toggle.vue";
import { useCampaignSettings } from "@/composables/campaign-settings";
import { notificationsMixin } from "@/mixins";
import { defineComponent } from "vue";
import { mapState } from "vuex";

export default defineComponent({
  setup() {
    const { createSetting, updateSetting, getSetting } = useCampaignSettings();

    return {
      createSetting,
      updateSetting,
      getSetting,
    };
  },
  props: {
    emailType: {
      type: String,
      required: true,
    },
  },
  components: {
    AppSwitchToggle,
  },
  mixins: [notificationsMixin],
  data() {
    return {
      metaData: {
        do_not_send_donation_confirmation_email: {
          value: false,
        },
        do_not_send_email_receipt: {
          value: false,
        },
        attach_pdf_to_receipt_email: {
          value: false,
        },
        do_not_send_team_creation_email: {
          value: false,
          allow_from_public_page: false,
        },
        do_not_send_new_linked_team_account_email: {
          value: false,
        },
      },
      metaDataServer: {},
      loading: false,
      firstLoading: true,
    };
  },
  computed: {
    ...mapState({
      cID: (state) => state.campaign.campaign.data.id,
    }),
    orgID() {
      return localStorage.getItem("orgId");
    },
  },
  mounted() {
    this.fetchMetaData().finally(() => {
      this.firstLoading = false;
    });
  },
  methods: {
    async fetchMetaData() {
      const promises = [];
      for (const key of Object.keys(this.metaData)) {
        promises.push(this.setMetaData(key));
      }
      this.loading = true;
      try {
        await Promise.allSettled(promises);
      } finally {
        this.loading = false;
      }
    },
    async setMetaData(key) {
      const setting = await this.getSetting(
        {
          cid: this.cID,
          oid: this.orgID,
          extraParams: {
            falback_to_meta_dir: 1,
          },
        },
        key
      );
      let data = null;
      if (
        Number(setting.id) === 0 &&
        Object.keys(setting.attributes.default_meta_value_off).length > 0
      ) {
        if (key === "attach_pdf_to_receipt_email") {
          data = setting.attributes.default_meta_value_on;
        } else {
          data = setting.attributes.default_meta_value_off;
        }
      } else {
        data = setting.attributes.meta_data;
      }
      this.metaData[key] = data;
      this.metaDataServer[key] = setting;
    },
    async onChangeAppSwitchToggle(key) {
      if (!this.metaDataServer[key]) {
        console.warn(`No meta data server for ${key}`);
        return;
      }

      this.loading = true;
      if (this.metaDataServer[key].id === "0") {
        try {
          await this.createSetting({ cid: this.cID, oid: this.orgID }, key, this.metaData[key]);
          this.$_notificationsMixin_makeToast("Success", this.$t("account.success_edit"));
          await this.fetchMetaData();
          return;
        } catch (e) {
          this.$_notificationsMixin_handleCatch(e);
          return;
        } finally {
          this.loading = false;
        }
      }

      try {
        await this.updateSetting(
          { cid: this.cID, oid: this.orgID },
          key,
          this.metaDataServer[key].id,
          this.metaData[key]
        );
        this.$_notificationsMixin_makeToast("Success", this.$t("account.success_edit"));
        await this.fetchMetaData();
        return;
      } catch (e) {
        this.$_notificationsMixin_handleCatch(e);
        return;
      } finally {
        this.loading = false;
      }
    },
  },
});
</script>

<template>
  <div v-if="!firstLoading" class="w-25">
    <div v-if="emailType === 'confirmation_email'" class="d-flex flex-column">
      <AppSwitchToggle
        v-if="metaDataServer['do_not_send_donation_confirmation_email']"
        :label="
          $t(
            'edit_campaign.email_template_toggle_do_not_send_donation_confirmation_email',
            'Do not send'
          )
        "
        v-model="metaData.do_not_send_donation_confirmation_email.value"
        @change="onChangeAppSwitchToggle('do_not_send_donation_confirmation_email')"
        plate
        :disabled="loading"
        :loading="loading"
        class="my-1"
      />
    </div>
    <div v-else-if="emailType === 'receipt_email'" class="d-flex flex-column">
      <AppSwitchToggle
        v-if="metaDataServer['attach_pdf_to_receipt_email']"
        :label="
          $t(
            'edit_campaign.email_template_toggle_include_receipt_in_the_email',
            'Include receipt in the email'
          )
        "
        v-model="metaData.attach_pdf_to_receipt_email.value"
        @change="onChangeAppSwitchToggle('attach_pdf_to_receipt_email')"
        plate
        :disabled="loading"
        :loading="loading"
        class="my-1"
      />
      <AppSwitchToggle
        v-if="metaDataServer['do_not_send_email_receipt']"
        :label="
          $t('edit_campaign.email_template_toggle_do_not_send_email_receipt', 'Do not send email')
        "
        v-model="metaData.do_not_send_email_receipt.value"
        @change="onChangeAppSwitchToggle('do_not_send_email_receipt')"
        plate
        :disabled="loading"
        :loading="loading"
        class="my-1"
      />
    </div>
    <div v-else-if="emailType === 'team_confirmation_email'" class="d-flex flex-column">
      <AppSwitchToggle
        v-if="metaDataServer['do_not_send_new_linked_team_account_email']"
        :label="
          $t(
            'edit_campaign.email_template_toggle_do_not_send_new_linked_team_account_email',
            'Do not send new linked team account email'
          )
        "
        v-model="metaData.do_not_send_new_linked_team_account_email.value"
        @change="onChangeAppSwitchToggle('do_not_send_new_linked_team_account_email')"
        plate
        :disabled="loading"
        :loading="loading"
        class="my-1"
      />
      <AppSwitchToggle
        v-if="metaDataServer['do_not_send_team_creation_email']"
        :label="
          $t('edit_campaign.email_template_toggle_do_not_send_team_creation_email', 'Do not send')
        "
        v-model="metaData.do_not_send_team_creation_email.value"
        @change="onChangeAppSwitchToggle('do_not_send_team_creation_email')"
        plate
        :disabled="loading"
        :loading="loading"
        class="my-1"
      />
      <AppSwitchToggle
        v-if="metaDataServer['do_not_send_team_creation_email']"
        :label="
          $t('edit_campaign.email_template_toggle_allow_from_public_page', 'Allow from public page')
        "
        v-model="metaData.do_not_send_team_creation_email.allow_from_public_page"
        @change="onChangeAppSwitchToggle('do_not_send_team_creation_email')"
        plate
        :disabled="loading || !metaData.do_not_send_team_creation_email.value"
        :loading="loading"
        class="my-1"
      />
    </div>
  </div>
  <div v-else class="d-flex flex-column">
    <b-skeleton class="my-1" type="button" />
    <b-skeleton class="my-1" type="button" />
  </div>
</template>
