import { dashboardApiHost } from "@/constants/urls.js";
import axios from "axios";
import { ref } from "vue";

async function getProtectedMyTeams(params) {
  const url = `${dashboardApiHost}/orgarea/api/v1/donor_account/logins_to_teams/teams`;
  const { data } = await axios.get(url, {
    params,
  });

  return {
    data,
  };
}

async function getProtectedMyTeamsStats(params) {
  const url = `${dashboardApiHost}/orgarea/api/v1/donor_account/logins_to_teams/teams_stats`;
  const { data } = await axios.get(url, {
    params,
  });

  return {
    data,
  };
}

export function useProctectedMyTeamsList() {
  const teams = ref([]);
  const loading = ref(false);
  const teamStats = ref({});

  async function fetchProtectedMyTeams(params) {
    loading.value = true;

    try {
      const { data } = await getProtectedMyTeams(params);
      teams.value = data;

      return data;
    } catch (e) {
      console.error(e);
      throw e;
    } finally {
      loading.value = false;
    }
  }

  async function fetchProtectedMyTeamsStats(params) {
    loading.value = true;

    try {
      const { data } = await getProtectedMyTeamsStats(params);
      teamStats.value = data;
    } catch (e) {
      console.error(e);
      throw e;
    } finally {
      loading.value = false;
    }
  }

  return {
    teams,
    teamStats,
    loading,
    fetchProtectedMyTeams,
    fetchProtectedMyTeamsStats,
  };
}
