<script>
import { peerMatchThresholdsValidation } from "./peerMatchThresholds.js";
import axios from "axios";
import { mapState } from "vuex";
import urls from "@/constants/urls";
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    donationAmount: {
      type: Number,
      required: true,
    },
    donationCurrencyCode: {
      type: String,
      required: true,
    },
    campaignCurrencyCode: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      internalValue: false,
      internalLockedType: "",
      internalValueInt: undefined,

      minAmountThresholdChangeRate: 0,
      maxAmountThresholdChangeRate: 0,
      thresholdChangeRateLoading: false,
    };
  },
  computed: {
    ...mapState({
      metas: (state) => state.campaign.metas,
    }),

    allowConditionalDonations() {
      const data = this.metas?.allow_conditional_donations;

      if (data?.value) {
        return data;
      }

      return {};
    },

    minAmountThreshold() {
      return (
        this.minAmountThresholdChangeRate || this.allowConditionalDonations?.min_amount_threshold
      );
    },
    maxAmountThreshold() {
      return (
        this.maxAmountThresholdChangeRate || this.allowConditionalDonations?.max_amount_threshold
      );
    },

    hasThresholdsError() {
      const validation = peerMatchThresholdsValidation(
        {
          minAmountThreshold: this.minAmountThreshold,
          maxAmountThreshold: this.maxAmountThreshold,
          donationCurrency: this.donationCurrencyCode,
        },
        this.donationAmount
      );

      if (validation.valid) {
        return "";
      }

      return validation.data.message;
    },

    show() {
      if (this.donateAnonymously) {
        return false;
      }

      return true;
    },
  },
  watch: {
    hasThresholdsError() {
      this.onLockedChange("", false);
    },
    donateAnonymously(v) {
      if (v) {
        this.onLockedChange("", false);
      }
    },
    donationCurrencyCode() {
      this.getThresholdExchangeRate();
    },
  },
  mounted() {
    this.getThresholdExchangeRate();
  },
  methods: {
    emitChanges(event, ...args) {
      this.$emit(event, ...args);
    },
    resetLocked() {
      this.internalValue = false;
      this.internalLockedType = "";

      this.emitChanges("setLocked", false);
      this.emitChanges("setLockedType", "");
      this.emitChanges("deleteLockedVarInt");
    },
    onLockedChange(type, value) {
      this.resetLocked();

      switch (type) {
        case "team_reach_x":
          this.internalValue = value;
          this.internalLockedType = value ? "team_reach_x" : "";

          this.emitChanges("setLocked", this.internalValue);
          this.emitChanges("setLockedType", this.internalLockedType);
          break;

        case "peer_match":
          this.internalValue = value;
          this.internalLockedType = value ? "peer_match" : "";
          this.internalValueInt = undefined;

          this.emitChanges("setLocked", this.internalValue);
          this.emitChanges("setLockedType", this.internalLockedType);
          this.emitChanges("deleteLockedVarInt");
          break;

        default:
          this.resetLocked();
          break;
      }
    },
    onLockedVarIntInput(event) {
      let value;
      if (event.target && event.target.value) {
        value = event.target.value;
      } else {
        value = Number(event);
      }

      const number = Number(value);
      if (Number.isNaN(number)) {
        return;
      }

      this.internalValueInt = number;
      this.emitChanges("setLockedVarInt", number);
    },
    async getThresholdExchangeRate() {
      this.thresholdChangeRateLoading = true;

      const thresholdCurr = this.campaignCurrencyCode.toLowerCase();
      const donCurr = this.donationCurrencyCode.toLowerCase();
      const minAmountThreshold = this.allowConditionalDonations?.min_amount_threshold;
      const maxAmountThreshold = this.allowConditionalDonations?.max_amount_threshold;

      if (donCurr === thresholdCurr) {
        this.thresholdChangeRateLoading = false;
        return;
      }

      try {
        if (minAmountThreshold) {
          const {
            data: { data },
          } = await axios.get(urls.getExchange, {
            params: {
              amount: minAmountThreshold,
              from: thresholdCurr,
              to: donCurr,
            },
          });
          const { attributes: attr } = data;
          this.minAmountThresholdChangeRate = attr.amount_out;
        }

        if (maxAmountThreshold) {
          const {
            data: { data },
          } = await axios.get(urls.getExchange, {
            params: {
              amount: maxAmountThreshold,
              from: thresholdCurr,
              to: donCurr,
            },
          });
          const { attributes: attr } = data;
          this.maxAmountThresholdChangeRate = attr.amount_out;
        }
      } finally {
        this.thresholdChangeRateLoading = false;
      }
    },
  },
});
</script>

<template>
  <b-list-group v-if="show">
    <slot
      :internal-value="internalValue"
      :internal-locked-type="internalLockedType"
      :internal-value-int="internalValueInt"
      :has-thresholds-error="hasThresholdsError"
      :on-locked-change="onLockedChange"
      :on-locked-var-int-input="onLockedVarIntInput"
      :threshold-change-rate-loading="thresholdChangeRateLoading"
    />
  </b-list-group>
</template>

<style lang="scss" scoped></style>
