<template>
  <div>
    <b-modal
      id="utm-link-form-modal"
      size="lg"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      @hide="clearData"
    >
      <div slot="modal-header">
        <h2>{{ $t(`dashboard.utm_${type}_modal_title`) }}</h2>
      </div>

      <form id="utm-form" @submit.prevent="submit">
        <b-row>
          <b-col cols="12">
            <b-form-group>
              <template v-slot:label>
                <label class="form-group__label form-group__label--required">
                  {{
                    $t("dashboard.utm_form_result_url_select_label", "Select campaign short link")
                  }}
                </label>
                <info-icon
                  class="mx-2"
                  sign="?"
                  :tooltip-msg="$t('dashboard.utm_form_result_url_select_tooltip')"
                  type="bootstrap"
                />
              </template>
              <b-form-select
                required
                v-model="currentUrl"
                :options="resultUrlOptions"
              ></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="6">
            <b-form-group>
              <template v-slot:label>
                <label class="form-group__label form-group__label--required">
                  {{ $t("dashboard.utm_form_medium_label") }}
                </label>
                <info-icon
                  class="mx-2"
                  sign="?"
                  :tooltip-msg="$t('dashboard.utm_form_medium_tooltip')"
                  type="bootstrap"
                />
              </template>
              <b-form-input v-model="form.medium" required> </b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group>
              <template v-slot:label>
                <label class="form-group__label form-group__label--required">
                  {{ $t("dashboard.utm_form_source_label") }}
                </label>
                <info-icon
                  class="mx-2"
                  sign="?"
                  :tooltip-msg="$t('dashboard.utm_form_source_tooltip')"
                  type="bootstrap"
                />
              </template>
              <b-form-input v-model="form.source" required> </b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <b-form-group>
              <template v-slot:label>
                <label class="form-group__label form-group__label--required">
                  {{ $t("dashboard.utm_form_cmp_name_label") }}
                </label>
                <info-icon
                  class="mx-2"
                  sign="?"
                  :tooltip-msg="$t('dashboard.utm_form_cmp_name_tooltip')"
                  type="bootstrap"
                />
              </template>
              <b-form-input v-model="form.campaign" required> </b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="6">
            <b-form-group>
              <template v-slot:label>
                <label class="form-group__label">
                  {{ $t("dashboard.utm_form_content_label") }}
                </label>
                <info-icon
                  class="mx-2"
                  sign="?"
                  :tooltip-msg="$t('dashboard.utm_form_content_tooltip')"
                  type="bootstrap"
                />
              </template>
              <b-form-input v-model="form.content"> </b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group>
              <template v-slot:label>
                <label class="form-group__label">
                  {{ $t("dashboard.utm_form_term_label") }}
                </label>
                <info-icon
                  class="mx-2"
                  sign="?"
                  :tooltip-msg="$t('dashboard.utm_form_term_tooltip')"
                  type="bootstrap"
                />
              </template>
              <b-form-input v-model="form.term"> </b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-form-group>
              <template v-slot:label>
                <label class="form-group__label">
                  {{ $t("dashboard.utm_form_result_url_label") }}
                </label>
              </template>
              <div class="d-flex align-items-center">
                <div class="final-url">{{ resultUrl }}</div>
                <div>
                  <b-popover target="popover-copied" placement="top" :show.sync="copied">
                    {{ $t("dashboard.utm_link_copied_to_clipboard") }}
                  </b-popover>
                  <button
                    id="popover-copied"
                    type="button"
                    class="bttn bttn-round ml-2"
                    @click="copy"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      width="24"
                      height="24"
                    >
                      <path
                        fill="#ffffff"
                        d="M326.612 185.391c59.747 59.809 58.927 155.698.36 214.59-.11.12-.24.25-.36.37l-67.2 67.2c-59.27 59.27-155.699 59.262-214.96 0-59.27-59.26-59.27-155.7 0-214.96l37.106-37.106c9.84-9.84 26.786-3.3 27.294 10.606.648 17.722 3.826 35.527 9.69 52.721 1.986 5.822.567 12.262-3.783 16.612l-13.087 13.087c-28.026 28.026-28.905 73.66-1.155 101.96 28.024 28.579 74.086 28.749 102.325.51l67.2-67.19c28.191-28.191 28.073-73.757 0-101.83-3.701-3.694-7.429-6.564-10.341-8.569a16.037 16.037 0 0 1-6.947-12.606c-.396-10.567 3.348-21.456 11.698-29.806l21.054-21.055c5.521-5.521 14.182-6.199 20.584-1.731a152.482 152.482 0 0 1 20.522 17.197zM467.547 44.449c-59.261-59.262-155.69-59.27-214.96 0l-67.2 67.2c-.12.12-.25.25-.36.37-58.566 58.892-59.387 154.781.36 214.59a152.454 152.454 0 0 0 20.521 17.196c6.402 4.468 15.064 3.789 20.584-1.731l21.054-21.055c8.35-8.35 12.094-19.239 11.698-29.806a16.037 16.037 0 0 0-6.947-12.606c-2.912-2.005-6.64-4.875-10.341-8.569-28.073-28.073-28.191-73.639 0-101.83l67.2-67.19c28.239-28.239 74.3-28.069 102.325.51 27.75 28.3 26.872 73.934-1.155 101.96l-13.087 13.087c-4.35 4.35-5.769 10.79-3.783 16.612 5.864 17.194 9.042 34.999 9.69 52.721.509 13.906 17.454 20.446 27.294 10.606l37.106-37.106c59.271-59.259 59.271-155.699.001-214.959z"
                      ></path>
                    </svg>
                  </button>
                </div>
              </div>
            </b-form-group>
          </b-col>
        </b-row>

        <error :response.sync="error" :alert="true"></error>
      </form>

      <div slot="modal-footer">
        <button class="bttn bttn--lg bttn--blue" @click="close">
          {{ $t(`dashboard.cancel_btn`) }}
        </button>
        <button type="submit" class="bttn bttn--lg bttn--orange" form="utm-form">
          {{ $t(`dashboard.utm_${type}_modal_btn`) }}
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import { notificationsMixin } from "@/mixins";

import error from "@/components/error-message.vue";
import infoIcon from "@/components/icon-info.vue";

export default {
  components: {
    error,
    infoIcon,
  },

  mixins: [notificationsMixin],

  props: {
    page: {
      type: Number,
      default: 1,
    },
    perPage: {
      type: Number,
      default: 20,
    },
  },

  data() {
    return {
      type: "add",
      id: 0,
      form: {
        medium: "",
        source: "",
        campaign: "",
        content: "",
        term: "",
        destination_url: "",
        result_url: "",
      },
      submiting: false,
      error: null,
      copied: false,
      currentUrl: "",
      cmpId: 0,
      shortLink: "",
      urlAlias: [],
    };
  },

  computed: {
    ...mapState({}),

    utmParams() {
      const { source, medium, campaign, content, term } = this.form;

      const params = new URLSearchParams();

      if (source) {
        params.set("utm_source", source);
      }
      if (medium) {
        params.set("utm_medium", medium);
      }
      if (campaign) {
        params.set("utm_campaign", campaign);
      }
      if (content) {
        params.set("utm_content", content);
      }
      if (term) {
        params.set("utm_term", term);
      }

      return params;
    },

    resultUrl() {
      const url = URL.parse(this.currentUrl);
      if (!url) {
        return "";
      }

      url.search = this.utmParams.toString();

      return url.toString();
    },

    resultUrls() {
      const startsWithStarDot = (str) => {
        if (str.length < 2) return false;
        return str[0] === "*" && str[1] === ".";
      };

      const urls = [
        `https://www.charidy.com/${this.shortLink}`,
        ...this.urlAlias.map((alias) => {
          if (alias.attributes.short_url) {
            const url = alias.attributes.short_url;
            if (startsWithStarDot(url)) {
              return false;
            }

            if (url.includes("http://") || url.includes("https://")) {
              return url;
            }

            return `https://${url}`;
          }

          return `https://www.charidy.com/${alias.attributes.keyword}`;
        }),
      ];

      const uniqueUrls = [...new Set(urls.filter((url) => Boolean(url)))];

      return uniqueUrls.map((url) => URL.parse(url)).filter((url) => Boolean(url));
    },

    resultUrlOptions() {
      return this.resultUrls.map((url) => {
        return {
          text: url.toString(),
          value: url.toString(),
        };
      });
    },
  },

  watch: {
    resultUrlOptions: {
      handler(newVal) {
        if (this.type === "edit") {
          return;
        }

        this.currentUrl = newVal?.[0]?.value || "";
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    ...mapActions({
      fetchUTMs: "tools/utm/fetchUTMs",
      fetchCreateUTM: "tools/utm/fetchCreateUTM",
      fetchUpdateUTM: "tools/utm/fetchUpdateUTM",
    }),

    open(type, item, campaign) {
      this.cmpId = campaign?.id;
      this.shortLink = campaign?.attributes?.short_link;
      this.urlAlias = campaign?.relationships?.campaign_url_alias?.data || [];

      if (type === "edit") {
        this.type = type;
        this.id = item.id;
        this.form = item.attributes;
        this.currentUrl = `${new URL(item.attributes.result_url).origin}/`;
      }
      this.$bvModal.show("utm-link-form-modal");
    },

    close() {
      this.$bvModal.hide("utm-link-form-modal");
    },

    copy() {
      const textarea = document.createElement("textarea");
      textarea.textContent = this.resultUrl;
      document.body.appendChild(textarea);

      const selection = document.getSelection();
      const range = document.createRange();
      range.selectNode(textarea);
      selection.removeAllRanges();
      selection.addRange(range);

      this.copied = document.execCommand("copy");
      selection.removeAllRanges();

      document.body.removeChild(textarea);
      setTimeout(() => {
        this.copied = false;
      }, 1500);
    },

    submit() {
      this.submiting = true;
      this.error = null;

      this.form.result_url = this.resultUrl;

      if (this.type === "add") {
        this.fetchCreateUTM([this.cmpId, this.form])
          .then(this.successHandler)
          .catch(this.errorHandler);
      }

      if (this.type === "edit") {
        this.fetchUpdateUTM([this.cmpId, this.id, this.form])
          .then(this.successHandler)
          .catch(this.errorHandler);
      }
    },

    getUTMs() {
      const params = {
        page: this.page,
        per_page: this.perPage,
      };
      this.fetchUTMs([this.cmpId, params]);
    },

    successHandler() {
      this.submiting = false;
      this.close();
      this.getUTMs();

      this.$_notificationsMixin_makeToast(
        "Success!",
        this.$t("dashboard.utm_link_success_save", "Saved successfully"),
        "success"
      );
    },

    errorHandler(err) {
      this.submiting = false;
      this.error = err.response;
    },

    clearData() {
      this.type = "add";
      this.error = null;
      this.form = {
        medium: "",
        source: "",
        campaign: "",
        content: "",
        term: "",
        destination_url: "",
        result_url: "",
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.final-url {
  color: #107598;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 600;
}
.bttn-round {
  width: 50px;
  height: 50px;
}
</style>
