<template>
  <multiselect-search
    v-if="!firstLoading"
    v-model="optionSelected"
    :open-prefetch="true"
    :allow-empty="allowEmpty"
    :search-url="getSearchUrl"
    :search-options="searchOptions"
    :taggable="taggable"
    :multiple="multiple"
    :multiselectClass="{ 'multiselect-charidy': !defaultStyles }"
    skip-external-serching
    :disabled="disabled"
    :closeOnSelect="true"
    renderPrefetch
    class="w-100"
  >
  </multiselect-search>
</template>

<script>
import urls from "@/constants/urls";
import MultiselectSearch from "@/components/multiselect-search.vue";
import axios from "axios";

export default {
  components: {
    MultiselectSearch,
  },
  props: {
    value: {
      type: [Number, String, Object, Array],
      default: 0,
    },
    getFullCampaign: Boolean,
    defaultStyles: Boolean,
    preselectFirst: Boolean,
    allowEmpty: Boolean,
    taggable: Boolean,
    multiple: Boolean,
    perPage: {
      type: Number,
      default: 100,
    },
    general: Boolean,
    disabled: Boolean,
    teamId: {
      type: Number,
      default: 0,
    },
    trackBy: {
      type: String,
      default: "campaign_id",
    },
  },

  data() {
    return {
      innerSelected: null,
      campaignList: [],
      firstLoading: false,
    };
  },

  created() {},
  methods: {},

  computed: {
    optionSelected: {
      set(val) {
        this.innerSelected = val;
        if (this.multiple) {
          this.$emit("input", val);
        } else {
          this.$emit("input", val.campaign_id);
        }
      },
      get() {
        return this.innerSelected;
      },
    },

    getSearchUrl() {
      return urls.getProtectedListUserTeamCampaignList(this.teamId);
    },

    searchOptions() {
      return {
        trackBy: this.trackBy,
        placeholder: "Select campaign",
        changeParams: ({ page }) => ({
          page: page,
          per_page: this.perPage,
        }),
        customLabel: (e) => `[${e.campaign_id}] ${e.campaigntitle}`,
        processResults: (data) =>
          (data || []).map((el) => ({
            ...el,
          })),
        onListUpdate: (data) => {
          if (data?.length >= this.campaignList?.length) {
            this.campaignList = data;
          }
        },
      };
    },
  },

  watch: {
    value: {
      handler(cid) {
        if (cid && typeof cid === "number" && this.innerSelected?.id !== cid) {
          axios.get(urls.getProtectedListUserTeamCampaign(this.teamId, cid)).then(({ data }) => {
            if (data.data) {
              this.innerSelected = data.data;
              this.optionSelected = this.innerSelected;
            }
          });
        }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .multiselect-charidy {
  width: 100%;
  min-height: 60px;
  box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.1);
  cursor: pointer;
  .multiselect__select {
    height: 58px;
    &:before {
      top: 60%;
    }
  }
  .multiselect__option span {
    display: block;
    word-wrap: break-word;
    width: 100%;
    white-space: normal;
  }
  .multiselect__tags {
    min-height: 60px;
  }
  .multiselect__single,
  .multiselect__placeholder {
    font-size: 20px;
    min-height: 40px;
    line-height: 40px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @media only screen and (min-width: 768px) {
      font-size: 26px;
    }
    @media only screen and (min-width: 992px) {
      font-size: 30px;
    }
  }
  .multiselect__input {
    min-height: 40px;
    line-height: 40px;
  }
}
</style>
