import { dashboardApiHost } from "@/constants/urls.js";
import { mapIncludedToData } from "@/helpers/jsonapi";
import axios from "axios";
import { ref } from "vue";

function extractCatch(error) {
  const res = error && error.response;

  if (res && res.data.errors) {
    return res.data.errors;
  }

  if (res && res.data.error) {
    return res.data.error;
  }

  return error;
}

async function getProtectedListUserCampaigns(params) {
  const url = `${dashboardApiHost}/orgarea/api/v1/donor_account/logins_to_teams/campaigns`;
  const { data, headers } = await axios.get(url, { params });
  return {
    data: mapIncludedToData(data?.data || [], data?.included || []),
    count: Number(headers["x-total-records"]) || 0,
  };
}

export function useProctectedListUserCampaigns() {
  const campaigns = ref([]);
  const count = ref(0);
  const loading = ref(false);
  const errors = ref(null);

  async function getCampaigns(params) {
    loading.value = true;
    try {
      const { data, count: countData } = await getProtectedListUserCampaigns(params);
      campaigns.value = data;
      count.value = countData;

      return data;
    } catch (e) {
      console.error(e);
      errors.value = extractCatch(e);
      throw e;
    } finally {
      loading.value = false;
    }
  }

  return {
    campaigns,
    count,
    loading,
    getCampaigns,
  };
}
