import { dashboardApiHost } from "@/constants/urls";
import axios from "axios";
import { ref } from "vue";

export const CharidyCampaignGiftsClaimStatusProcessed = "Processed";
export const CharidyCampaignGiftsClaimStatusSentToCarrier = "Sent to carrier";
export const CharidyCampaignGiftsClaimStatusCurrentlyShipping = "Currently shipping";
export const CharidyCampaignGiftsClaimStatusDelivered = "Delivered";
export const CharidyCampaignGiftsClaimStatusCanceled = "Canceled";
export const CharidyCampaignGiftsClaimStatusOther = "Other";

async function getProtectedCampaignGiftClaimListByOrg(orgId, campaignId, params) {
  const url = `${dashboardApiHost}/orgarea/api/v1/organization/${orgId}/campaign/${campaignId}/campaign-gift-claim/list`;

  const { data, headers } = await axios.get(url, {
    params,
  });

  return {
    data,
    count: Number(headers["x-total-records"] || 0),
  };
}

async function getProtectedCampaignGiftClaimListByIDByOrg(orgId, campaignId, id, params) {
  const url = `${dashboardApiHost}/orgarea/api/v2/organization/${orgId}/campaign/${campaignId}/campaign-gift-claim/${id}`;

  const { data } = await axios.get(url, {
    params,
  });

  return {
    data,
  };
}

async function putProtectedUpdateCampaignGiftClaim(orgId, campaignId, id, payload) {
  const url = `${dashboardApiHost}/orgarea/api/v1/organization/${orgId}/campaign/${campaignId}/campaign-gift-claim/${id}`;

  const { data } = await axios.put(url, payload);

  return {
    data,
  };
}

async function deleteProtectedCampaignGiftClaim(orgId, campaignId, id) {
  const url = `${dashboardApiHost}/orgarea/api/v1/organization/${orgId}/campaign/${campaignId}/campaign-gift-claim/${id}`;

  const { data } = await axios.delete(url);

  return {
    data,
  };
}

export function useCampaignGiftClaim() {
  const orgId = localStorage.getItem("orgId");
  const items = ref([]);
  const loading = ref(false);
  const total = ref(0);
  const item = ref(null);

  async function fetchOrgCampaignGiftClaimListByOrg(campaignId, params) {
    loading.value = true;

    try {
      const { data, count } = await getProtectedCampaignGiftClaimListByOrg(
        orgId,
        campaignId,
        params
      );
      items.value = data;
      total.value = count;
    } catch (e) {
      console.error(e);
      throw e;
    } finally {
      loading.value = false;
    }
  }

  async function fetchOrgCampaignGiftClaimByIDByOrg(campaignId, id, params) {
    loading.value = true;

    try {
      const { data } = await getProtectedCampaignGiftClaimListByIDByOrg(
        orgId,
        campaignId,
        id,
        params
      );
      item.value = data;
    } catch (e) {
      console.error(e);
      throw e;
    } finally {
      loading.value = false;
    }
  }

  async function updateOrgCampaignGiftClaim(campaignId, id, payload) {
    loading.value = true;

    try {
      await putProtectedUpdateCampaignGiftClaim(orgId, campaignId, id, payload);
    } catch (e) {
      console.error(e);
      throw e;
    } finally {
      loading.value = false;
    }
  }

  async function deleteOrgCampaignGiftClaim(campaignId, id) {
    loading.value = true;

    try {
      await deleteProtectedCampaignGiftClaim(orgId, campaignId, id);
    } catch (e) {
      console.error(e);
      throw e;
    } finally {
      loading.value = false;
    }
  }

  return {
    items,
    item,
    total,
    loading,
    fetchOrgCampaignGiftClaimListByOrg,
    fetchOrgCampaignGiftClaimByIDByOrg,
    updateOrgCampaignGiftClaim,
    deleteOrgCampaignGiftClaim,
  };
}
