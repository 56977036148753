import { dashboardApiHost } from "@/constants/urls";
import { mapIncludedToData } from "@/helpers/jsonapi";
import axios from "axios";
import { ref } from "vue";

export async function getProtectedCampaignGiftClaimListByTeam(campaignId, teamId, params) {
  const url = `${dashboardApiHost}/orgarea/api/v1/organization/0/campaign/${campaignId}/team/${teamId}/campaign-gift-claim/list`;
  const { data } = await axios.get(url, { params });

  return {
    data: mapIncludedToData(data.data, data.included),
  };
}

export async function postProtectedCampaignGiftClaim(campaignId, teamId, payload, params) {
  const url = `${dashboardApiHost}/orgarea/api/v1/organization/0/campaign/${campaignId}/team/${teamId}/campaign-gift-claim`;
  const { data } = await axios.post(url, payload, { params });
  return {
    data,
  };
}

export function useProtectedCampaignGiftClaim() {
  const items = ref([]);
  const loading = ref(false);

  async function fetchProtectedCampaignGiftClaimListByTeam(campaignId, teamId, params) {
    loading.value = true;

    try {
      const { data } = await getProtectedCampaignGiftClaimListByTeam(campaignId, teamId, params);
      items.value = data;
    } catch (e) {
      console.error(e);
      throw e;
    } finally {
      loading.value = false;
    }
  }

  async function createProtectedCampaignGiftClaim(campaignId, teamId, payload, params) {
    loading.value = true;

    try {
      await postProtectedCampaignGiftClaim(campaignId, teamId, payload, params);
      await fetchProtectedCampaignGiftClaimListByTeam(campaignId, 0, {});
    } catch (e) {
      console.error(e);
      throw e;
    } finally {
      loading.value = false;
    }
  }

  return {
    items,
    loading,
    fetchProtectedCampaignGiftClaimListByTeam,
    createProtectedCampaignGiftClaim,
  };
}
