import { mapState } from "vuex";

const STATIC_EMAIL = "noemail@charidy.com";

const emailGettersMixin = {
  computed: {
    ...mapState({
      $_emailGettersMixin_metas: (state) => state.campaign.metas,
    }),

    // [boolean, v-validate]
    $_emailGettersMixin_getEmailValidations() {
      const offlineDonation = this.$_emailGettersMixin_metas.offline_donation;
      if (offlineDonation && offlineDonation.value) {
        if (!offlineDonation.email_optional) {
          return [true, "required|email"];
        }
      }
      return [false, "email"];
    },
    // [inTemplate, inAction]
    $_emailGettersMixin_getStaticEmail() {
      return (donation) => {
        const originalEmail = donation.attributes.email;
        const noop = ["", STATIC_EMAIL];
        const userEnteredData = [originalEmail, originalEmail];
        // user entered email
        if (originalEmail !== STATIC_EMAIL && originalEmail !== "") {
          return userEnteredData;
        }
        // user not entered email and email is optional
        if (!this.$_emailGettersMixin_getEmailValidations[0]) {
          return noop;
        }
        // email was set to static
        if (originalEmail === STATIC_EMAIL) {
          return noop;
        }
        return userEnteredData;
      };
    },
  },
};

export default emailGettersMixin;
