import { dashboardApiHost } from "@/constants/urls.js";
import axios from "axios";
import { ref } from "vue";

async function getProtectedCampaignGiftList(orgId, cId, params) {
  const url = `${dashboardApiHost}/orgarea/api/v1/organization/${orgId}/campaign/${cId}/campaign-gift/list`;

  const { data, headers } = await axios.get(url, { params });

  return {
    data,
    count: Number(headers["x-total-records"] || 0),
  };
}

async function getProtectedCampaignGiftByID(orgId, cId, id, params) {
  const url = `${dashboardApiHost}/orgarea/api/v1/organization/${orgId}/campaign/${cId}/campaign-gift/${id}`;

  const { data } = await axios.get(url, { params });

  return {
    data,
  };
}

async function putProtectedCampaignGift(orgId, cId, id, data, params) {
  const url = `${dashboardApiHost}/orgarea/api/v1/organization/${orgId}/campaign/${cId}/campaign-gift/${id}`;

  const { data: response } = await axios.put(url, data, { params });

  return {
    data: response,
  };
}

async function deleteProtectedCampaignGift(orgId, cId, id, params) {
  const url = `${dashboardApiHost}/orgarea/api/v1/organization/${orgId}/campaign/${cId}/campaign-gift/${id}`;

  const { data: response } = await axios.delete(url, { params });

  return {
    data: response,
  };
}

async function postProtectedCampaignGift(orgId, cId, data, params) {
  const url = `${dashboardApiHost}/orgarea/api/v1/organization/${orgId}/campaign/${cId}/campaign-gift`;

  const { data: response } = await axios.post(url, data, { params });

  return {
    data: response,
  };
}

export function useCampaignGift() {
  const orgId = localStorage.getItem("orgId");

  const list = ref([]);
  const total = ref(0);
  const item = ref(null);
  const loading = ref(false);

  async function fetchOrgCampaignGiftList(cId, params) {
    loading.value = true;

    try {
      const { data, count } = await getProtectedCampaignGiftList(orgId, cId, params);
      list.value = data;
      total.value = count;

      return data;
    } catch (e) {
      console.error(e);
      throw e;
    } finally {
      loading.value = false;
    }
  }

  async function fetchOrgCampaignGiftByID(cId, id, params) {
    loading.value = true;

    try {
      const { data } = await getProtectedCampaignGiftByID(orgId, cId, id, params);
      item.value = data;

      return data;
    } catch (e) {
      console.error(e);
      throw e;
    } finally {
      loading.value = false;
    }
  }

  async function createOrgCampaignGift(cId, payload, params) {
    loading.value = true;

    try {
      const { data } = await postProtectedCampaignGift(orgId, cId, payload, params);

      return data;
    } catch (e) {
      console.error(e);
      throw e;
    } finally {
      loading.value = false;
    }
  }

  async function updateOrgCampaignGift(cId, id, payload, params) {
    loading.value = true;

    try {
      const { data } = await putProtectedCampaignGift(orgId, cId, id, payload, params);

      return data;
    } catch (e) {
      console.error(e);
      throw e;
    } finally {
      loading.value = false;
    }
  }

  async function deleteOrgCampaignGift(cId, id, params) {
    loading.value = true;

    try {
      const { data } = await deleteProtectedCampaignGift(orgId, cId, id, params);

      return data;
    } catch (e) {
      console.error(e);
      throw e;
    } finally {
      loading.value = false;
    }
  }

  return {
    list,
    item,
    total,
    loading,
    fetchOrgCampaignGiftList,
    fetchOrgCampaignGiftByID,
    createOrgCampaignGift,
    updateOrgCampaignGift,
    deleteOrgCampaignGift,
  };
}
