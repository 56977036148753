<template>
  <header class="tw-container tw-mx-auto header">
    <a :href="appHost" class="header__logo router-link-exact-active router-link-active">
      <CdnLogoByLang class="logo-main" v-if="logoType === 'main'" />
      <img
        v-else-if="logoType === 'academy'"
        class="logo-academy"
        src="https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_academy/website/charidy-academy-logo.png"
        alt
      />
    </a>
    <a href="https://charidy.com/all-campaigns" class="bttn bttn--orange bttn--sm live-campaigns">{{
      $t("header.live_campaigns_live", "Live campaigns")
    }}</a>
    <div class="lg:tw-hidden burger" :class="{ open: isOpen }" role="button" @click="menuToggle">
      <div class="burger__bar" />
      <div class="burger__bar" />
      <div class="burger__bar" />
    </div>

    <div class="lg:tw-hidden header__backdrop" :class="{ open: isOpen }" @click="menuClose" />

    <nav class="header__nav" :class="{ open: isOpen }">
      <slot v-if="!isLoggedIn" name="auth-actions"></slot>
      <slot v-else name="short-profile" :menuClose="menuClose"></slot>
    </nav>
  </header>
</template>

<script>
import CdnLogoByLang from "@/common-components/cdn-v2/cdn-logo-by-lang.vue";
import { dashboardHost, appHost } from "@/constants/urls.js";
import { mapGetters, mapState } from "vuex";

export default {
  components: {
    CdnLogoByLang,
  },
  props: {
    logoType: {
      type: String,
      default: "main",
    },
  },
  data() {
    return {
      dashboardHost,
      appHost,
      windowWidth: "1100",
      isOpen: false,
    };
  },

  computed: {
    ...mapState({
      langSelected: (state) => state.translation.locate,
    }),

    ...mapGetters({
      getOldLangsVariant: "getOldLangsVariant",
    }),
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    langs() {
      return this.getOldLangsVariant;
    },
    language() {
      const result = this.langs.filter((el) => el.short === this.langSelected)[0];
      if (result) {
        return result.long;
      }
      return "English";
    },
  },

  watch: {
    windowWidth(newVal) {
      if (newVal > 991) {
        this.isOpen = false;
      }
    },
  },

  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },

  methods: {
    logout: function () {
      this.$store.dispatch("unsetUser");
      this.$router.push({ path: "/donor-log-in" });
    },

    handleResize() {
      this.windowWidth = window.innerWidth;
    },

    menuToggle() {
      this.isOpen = !this.isOpen;
    },

    menuClose() {
      this.isOpen = false;
    },
    openChat() {
      this.$intercom.show();
      this.menuClose();
    },
    languageChange(lang) {
      this.$store.dispatch("setTranslationPermanent", lang.short);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_vars.scss";
.header {
  height: 90px;
  width: 100%;
  margin-bottom: 40px;
  padding: 0 15px 0 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  position: relative;
  z-index: 2;
  &__logo {
    display: inline-flex;
    align-items: center;
    height: 80px;
    width: 116px;
    line-height: 0;

    .logo-main {
      height: 100%;
    }
    .logo-academy {
      display: block;
      height: 80px;
      width: auto;
    }
    .logo-mehadrin {
      display: block;
      height: 54px;
      width: auto;
    }
  }
}
.live-campaigns {
  margin-left: 10px;
}

.burger {
  width: 28px;
  height: 28px;
  padding: 5px 0;
  position: fixed;
  top: calc(45px - 14px);
  right: 15px;
  z-index: 1000;
  cursor: pointer;
  &__bar {
    width: 28px;
    height: 4px;
    border-radius: 2px;
    background-color: $bb-100;
    position: relative;
    transition: all 0.2s ease-in-out;
    &:not(:last-of-type) {
      margin-bottom: 3px;
    }
  }
  &.open {
    .burger__bar {
      &:nth-of-type(1) {
        background-color: #ffffff;
        top: 7px;
        transform: rotate(-45deg);
      }
      &:nth-of-type(2) {
        opacity: 0;
        visibility: hidden;
      }
      &:nth-of-type(3) {
        background-color: #ffffff;
        bottom: 7px;
        transform: rotate(45deg);
      }
    }
  }
}
.header__backdrop {
  background-color: rgba(0, 0, 0, 0);
  transition: all 0.2s ease-in-out;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -100;
  display: none;
  &.open {
    display: block;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 100;
  }
}

.header__nav {
  @media only screen and (max-width: 1023px) {
    width: 270px;
    min-width: 270px;
    padding: 72px 16px 10px 16px;
    background-color: rgba(255, 255, 255, 1);
    transition: all 0.2s ease-in-out;
    position: fixed;
    top: 0;
    bottom: 0;
    left: -270px;
    z-index: 1000;
  }
  @media only screen and (min-width: 1024px) {
    width: initial;
    min-width: initial;
    position: static;
    margin-left: auto;
    height: 46px;
  }
  &.open {
    left: 0;
    padding: 0;
  }
  .side-logo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 72px;
    display: flex;
    align-items: center;
    padding-left: 16px;
    background-color: #f5f9ff;
    @media only screen and (min-width: 1024px) {
      display: none;
    }
    svg {
      fill: $bb-100;
    }
  }
}
.menu-top {
  width: 100%;
  min-height: 100%;
  display: inline-flex;
  flex-direction: column;
  list-style: none;
  padding: 15px 0 0 0;
  margin: 0;
  @media only screen and (min-width: 1024px) {
    padding: 0;
    min-height: initial;
    width: initial;
    flex-direction: row;
    height: 46px;
    margin-right: 10px;
  }
  li {
    display: flex;
    min-height: 46px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    order: 1;
    .logout {
      text-align: left;
      font-family: $vito-light;
      font-size: 16px;
      line-height: 1.125;
      color: $fd;
      padding: 10px 5px;
      svg {
        margin-right: 5px;
      }
      path {
        fill: $bb-100;
      }
    }
    @media only screen and (min-width: 1024px) {
      flex-direction: initial;
      justify-content: initial;
      order: initial;
    }
    &:nth-last-of-type(2) {
      margin-bottom: 15px;
      @media only screen and (min-width: 1024px) {
        margin-bottom: initial;
        margin-right: 15px;
      }
    }
  }

  &__item {
    display: inline-flex;
    @media only screen and (min-width: 1024px) {
      margin-right: 45px;
    }
    a {
      width: initial;
      display: inline-flex;
      align-items: center;
      font-family: $vito-light;
      font-size: 16px;
      line-height: 1.125;
      color: $fd;
      text-transform: capitalize;
      transition: all 0.2s ease-in-out;
      @media only screen and (min-width: 1024px) {
        font-family: $vito-bold;
      }
      &:hover,
      &:focus {
        color: $bb-80;
        text-decoration: none;
      }
    }
  }

  &__item--live {
    order: 0 !important;
    @media only screen and (min-width: 1024px) {
      order: initial !important;
    }
    @media only screen and (max-width: 1023px) {
      a {
        height: 46px;
        padding: 0 30px;
        display: inline-flex;
        align-items: center;
        background-color: $lr-100;
        color: #ffffff !important;
        font-family: $vito-bold;
        align-self: baseline;
        border-radius: 23px;
        margin-bottom: 15px;
      }
    }
  }
  &__item--user {
    order: 0 !important;
    @media only screen and (min-width: 1024px) {
      order: initial !important;
    }
  }
  &__item--about {
    @media only screen and (max-width: 1023px) {
      display: none !important;
    }
  }

  &__item--dropdown {
    order: 0 !important;
    position: relative;
    @media only screen and (min-width: 1024px) {
      margin-bottom: initial !important;
      margin-right: 30px;
      order: initial;
    }
    &:hover,
    &:focus {
      .dd-button {
        color: $bb-80;
      }
      .dd-wrap {
        display: none;
        @media only screen and (min-width: 1024px) {
          display: block;
        }
      }
    }
  }

  &__item--chat,
  &__item--location {
    a {
      @media only screen and (max-width: 1023px) {
        padding-left: 35px;
        position: relative;
      }
      > svg {
        transform: translate3d(-50%, -50%, 0);
        position: absolute;
        top: 50%;
        left: 17px;
        @media only screen and (min-width: 1024px) {
          display: none;
        }
      }
    }
  }
  &__item--chat {
    @media only screen and (min-width: 1024px) {
      display: none !important;
    }
  }
  &__login,
  &__signup {
    width: 130px;
    height: 46px;
    padding: 0 23px;
    border-radius: 23px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-family: $vito-bold;
    font-size: 16px;
    text-transform: capitalize;
    transition: all 0.2s ease-in-out;
  }

  &__login {
    color: $bb-100;
    border: 1px solid $bb-100;
    background-color: #ffffff;
    @media only screen and (max-width: 1023px) {
      margin-top: 15px;
      margin-bottom: 15px;
    }
    &:hover,
    &:focus {
      color: $bb-80;
      border-color: $bb-80;
      text-decoration: none;
    }
  }

  &__signup {
    color: #ffffff;
    background-color: $bb-100;
    &:hover,
    &:focus {
      //color: $bb-80;
      background-color: $bb-80;
      text-decoration: none;
    }
  }

  &__app {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    @media only screen and (min-width: 1024px) {
      display: none !important;
    }
    > a {
      display: inline-block;
      width: 180px;
      height: auto;
      &:not(:last-of-type) {
        margin-bottom: 20px;
      }
      > img {
        display: block;
        width: 100%;
        height: auto;
      }
    }
  }
}

.dd-button {
  padding: 0;
  border: none;
  background-color: transparent;
  font-family: $vito-bold;
  font-size: 16px;
  color: $fd;
  text-transform: capitalize;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  &:hover,
  &:focus,
  &:active {
    outline: 0;
  }
}

.dd-wrap {
  display: none;
  padding-top: 4px;
  position: absolute;
  left: 0;
  top: 100%;
  z-index: 999;
}

.dd-list {
  width: 100%;
  background-color: #ffffff;
  padding: 0;
  overflow: hidden;
  @media only screen and (min-width: 1024px) {
    width: 250px;
    box-shadow: $dd-shadow;
    border-radius: 4px;
  }
  > li {
    padding: 0;
    width: 100%;
    display: flex;
    @media only screen and (min-width: 1024px) {
      height: 45px;
    }
    a {
      width: 100%;
      padding: 15px 0px;
      line-height: 1.12;
      position: relative;
      @media only screen and (min-width: 1024px) {
        padding: 0 30px;
      }
      &:hover,
      &:focus {
        background-color: #f2f8ff;
        color: $fd;
      }
    }
    &:not(:last-of-type) {
      a {
        &:after {
          content: "";
          display: block;
          width: calc(100% - 60px);
          height: 1px;
          background-color: #e6eaed;
          position: absolute;
          bottom: 0;
          left: 30px;
        }
      }
    }
  }
}

::v-deep .btn-group {
  .btn-bordered {
    width: 130px;
    height: 46px;
    padding: 0 23px;
    border-radius: 23px;
    display: inline-flex;
    flex-shrink: 0;
    flex-grow: 0;
    align-items: center;
    justify-content: center;
    font-family: $vito-bold;
    font-size: 16px;
    text-transform: capitalize;
    transition: all 0.2s ease-in-out;
    color: $bb-100;
    border: 1px solid $bb-100;
    background-color: #ffffff;
    @media only screen and (max-width: 1023px) {
      margin-top: 15px;
      margin-bottom: 15px;
    }
    &:hover,
    &:focus {
      outline: 0;
      color: $bb-80;
      border-color: $bb-80;
      text-decoration: none;
      box-shadow: none;
    }
  }
  .btn-signup {
    width: 130px;
    height: 46px;
    padding: 0 23px;
    border-radius: 23px;
    display: inline-flex;
    flex-shrink: 0;
    flex-grow: 0;
    align-items: center;
    justify-content: center;
    font-family: $vito-bold;
    font-size: 16px;
    text-transform: capitalize;
    transition: all 0.2s ease-in-out;
    color: #ffffff;
    border: 1px solid $bb-100;
    background-color: $bb-100;
    @media only screen and (max-width: 1023px) {
      margin-top: 15px;
      margin-bottom: 15px;
    }
    &:hover,
    &:focus {
      outline: 0;
      color: #ffffff !important;
      border-color: $bb-80;
      background-color: $bb-80;
      text-decoration: none;
      box-shadow: none;
    }
  }
}

.btn-bttn-collapse {
  justify-content: initial;
  padding: 0 0 0 35px;
  height: 46px;
  display: flex;
  align-items: center;
  font-family: $vito-light;
  position: relative;
  &:focus,
  &:active {
    outline: 0;
    box-shadow: none !important;
  }
  > svg {
    transform: translate3d(-50%, -50%, 0);
    position: absolute;
    top: 50%;
    left: 17px;
  }
}
.dd-list-mobile {
  width: 100%;
  background-color: #ffffff;
  padding: 0 0 15px 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  li {
    padding: 10px 0;
    margin: 0 !important;
  }
  a {
    padding: 0 0 0 25px !important;
  }
}

.he {
  .header {
    &__nav {
      margin-left: initial;
      margin-right: auto;
      @media only screen and (max-width: 1023px) {
        right: -270px;
      }
      &.open {
        left: initial;
        right: 0;
      }
      .side-logo {
        padding-left: initial;
        padding-right: 16px;
      }
    }
  }
  .menu-top {
    @media only screen and (min-width: 1024px) {
      margin-right: initial;
      margin-left: 10px;
    }
    &__item {
      @media only screen and (min-width: 1024px) {
        margin-right: initial;
        margin-left: 45px;
      }
      &--chat {
        a {
          @media only screen and (max-width: 1023px) {
            padding-left: initial;
            padding-right: 36px;
            svg {
              left: initial;
              right: -17px;
            }
          }
        }
      }
      &--location {
        a {
          @media only screen and (max-width: 1023px) {
            padding-left: initial;
            padding-right: 36px;
            svg {
              left: initial;
              right: -9px;
            }
          }
        }
      }
    }
    li:nth-last-of-type(2) {
      @media only screen and (min-width: 1024px) {
        margin-right: initial;
        margin-left: 15px;
      }
    }
    .btn-bttn-collapse {
      padding: 0 35px 0 0;
      svg {
        left: initial;
        right: -17px;
      }
    }
  }
  .burger {
    right: initial;
    left: 15px;
  }
}

::v-deep {
  .btn-secondary:not(:disabled):not(.disabled):active,
  .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    background-color: transparent;
    border-color: transparent;
    color: $fd;
    box-shadow: none;
  }
  .btn-secondary:not(:disabled):not(.disabled):focus,
  .btn-secondary:not(:disabled):not(.disabled).focus,
  .show > .btn-secondary.dropdown-toggle {
    outline: 0;
    box-shadow: none;
  }
}

::v-deep .language-switch {
  .btn-secondary {
    background-color: transparent;
    border-color: transparent;
    font-family: $vito-bold;
    font-size: 16px;
    text-transform: capitalize;
    color: $fl;
    box-shadow: none;
  }
}

.language-switch {
  position: absolute;
  top: 25px;
  right: 35px;
  @media only screen and (min-width: 1024px) {
    position: static;
  }
  ::v-deep .btn {
    &:before {
      display: none;
    }
  }
  ::v-deep .dropdown-toggle {
    color: $fd;
  }
  ::v-deep .dropdown-menu {
    top: 0 !important;
    padding: 4px 0;
    border: none;
    box-shadow: $dd-shadow;
    min-width: auto;
    .dropdown-item {
      margin: 0;
      padding: 0 24px;
      height: 45px;
      width: auto;
      display: flex;
      align-items: center;
      font-family: $vito-light;
      font-size: 14px;
      position: relative;
      @media only screen and (min-width: 1024px) {
        font-family: $vito-bold;
      }
      &:not(:last-of-type):after {
        content: "";
        display: block;
        width: calc(100% - 48px);
        height: 1px;
        background-color: #e6eaed;
        position: absolute;
        bottom: 0;
        left: 24px;
      }
      &:hover,
      &:focus {
        background-color: #f2f8ff;
        color: $fd;
      }
    }
  }
}
</style>
