var render = function render(){var _vm=this,_c=_vm._self._c;return _c('SeferTorahPublicProvider',{scopedSlots:_vm._u([{key:"default",fn:function({
    list,
    loading,
    loadingSort,
    page,
    perPage,
    rows,
    updatePage,
    remove,
    changeItemSort,
    sortChange,
    searchChange,
    onRemoveMultiple,
    onAfterModalSubmit,
    getPages,
    currentFetchParams,
  }){return [_c('SeferTorahAddModal',{attrs:{"list":list,"currentFetchParams":currentFetchParams},on:{"submit":function($event){return _vm.onSubmit({ onAfterModalSubmit }, $event)}},scopedSlots:_vm._u([{key:"default",fn:function({ open: openAddModal }){return [_c('category-title',{attrs:{"title":_vm.$t('dashboard.sefer_torah_title', 'Sefer Torah'),"withActions":""}},[_c('b-button',{staticClass:"bttn bttn--lg bttn--orange mx-1",on:{"click":function($event){return openAddModal({})}}},[_vm._v(" "+_vm._s(_vm.$t("dashboard.sefer_torah_addnew_modal_open", "Add Sefer Torah Page"))+" ")]),_c('b-button',{staticClass:"bttn bttn--lg bttn--orange mx-1",on:{"click":_vm.openImport}},[_vm._v(" "+_vm._s(_vm.$t("dashboard.sefer_torah_import", "Import"))+" ")]),_c('b-button',{staticClass:"bttn bttn--lg bttn--orange mx-1",attrs:{"disabled":_vm.exportLoading},on:{"click":_vm.exportToFile}},[(_vm.exportLoading)?_c('b-spinner',{staticClass:"mr-2",attrs:{"variant":"light","small":""}}):_vm._e(),_vm._v(" "+_vm._s(_vm.$t("dashboard.sefer_torah_export", "Export"))+" ")],1)],1),_c('SeferTorahPublicTable',{attrs:{"page":page,"perPage":perPage,"rows":rows,"openAddModal":openAddModal,"list":list,"loading":loading,"loadingSort":loadingSort,"remove":remove},on:{"update:page":updatePage,"change-order":changeItemSort,"sort-change":sortChange,"search-change":searchChange,"remove-multiple":onRemoveMultiple}}),_c('import-modal',{ref:"import",on:{"success-upload":function($event){return _vm.onSuccessUpload({ getPages })}}})]}}],null,true)})]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }