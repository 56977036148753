var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"action-block"},[_c('button',{staticClass:"bttn bttn--sm bttn--orange",attrs:{"disabled":!_vm.hasAccess},on:{"click":_vm.openModal}},[_vm._v(" "+_vm._s(_vm.$t("gateways.add_gateways", "Add new"))+" ")]),_c('b-modal',{attrs:{"id":"gatewayadd","size":"lg","no-close-on-backdrop":true,"hide-footer":!_vm.gatewayStep,"footer-class":"gateway-footer"},on:{"hidden":_vm.clearData}},[_c('div',{attrs:{"slot":"modal-header"},slot:"modal-header"},[_c('h2',[(_vm.gatewayStep)?_c('span',{on:{"click":_vm.backToList}},[_c('svg',{attrs:{"version":"1.1","id":"Layer_1","xmlns":"http://www.w3.org/2000/svg","xmlns:xlink":"http://www.w3.org/1999/xlink","x":"0px","y":"0px","width":"24px","height":"24px","viewBox":"0 0 24 24","enable-background":"new 0 0 24 24","xml:space":"preserve"}},[_c('path',{attrs:{"d":"M15.41,16.59L10.83,12l4.58-4.59L14,6l-6,6l6,6L15.41,16.59z"}}),_c('path',{attrs:{"fill":"none","d":"M0,0h24v24H0V0z"}})])]):_vm._e(),_vm._v(" "+_vm._s(_vm.formTitle)+" ")]),_c('div',{staticClass:"modal-header__hide",on:{"click":_vm.modalClose}},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"24","height":"24","viewBox":"0 0 24 24"}},[_c('path',{attrs:{"d":"M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"}}),_c('path',{attrs:{"d":"M0 0h24v24H0z","fill":"none"}})])])]),_c('div',{attrs:{"slot":"modal-footer"},slot:"modal-footer"},[_c('portal-target',{attrs:{"slim":"","name":"gateway-add-footer"}})],1),(_vm.gatewayDataView)?_c('div',[_c('AccGatewayView',{ref:"modalViewData",attrs:{"gateway":_vm.gatewayDataView},on:{"update-step":_vm.modalClose}})],1):_c('div',[(!_vm.gatewayStep)?_c('div',[_c('ViewByOrgSetting',{staticClass:"w-100",attrs:{"org-setting":"dashboardGatewaysTabList"},scopedSlots:_vm._u([{key:"default",fn:function({ meta: dashboardGatewaysTabList }){return [(
              dashboardGatewaysTabList.value &&
              dashboardGatewaysTabList.meta &&
              Object.keys(dashboardGatewaysTabList.meta).length > 0
            )?_c('div',{staticClass:"w-100"},[_c('b-tabs',{staticClass:"w-100 mb-3",model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},_vm._l((Object.keys(dashboardGatewaysTabList.meta)),function(gatewayCategory,i){return _c('b-tab',{key:gatewayCategory,attrs:{"no-body":"","title-link-class":{
                  'tab--active': _vm.currentTab === i,
                  'text-dark': _vm.currentTab !== i,
                }},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t(`gateways.tab_${gatewayCategory}`))+" ")]},proxy:true}],null,true)},_vm._l((_vm.getGatewaysFromCategory(
                    dashboardGatewaysTabList.meta,
                    gatewayCategory
                  )),function(categoryGateways,i){return _c('div',{key:i},[(i === 0 && categoryGateways.length > 0)?_c('b-card',{staticClass:"gateways-add-select-preferred mb-2",attrs:{"title":_vm.$t(`gateways.tab_category_preferred`, 'Preferred gateways')}},[_c('div',{staticClass:"gateways-add-select"},_vm._l((categoryGateways),function([key, gateway]){return _c('GatewayAddItem',{key:key,attrs:{"gateway-key":key,"gateway":gateway},on:{"click":_vm.gatewaysHandle}})}),1)]):(i === 1)?_c('div',{staticClass:"gateways-add-select"},_vm._l((categoryGateways),function([key, gateway]){return _c('GatewayAddItem',{key:key,attrs:{"gateway-key":key,"gateway":gateway},on:{"click":_vm.gatewaysHandle}})}),1):_vm._e()],1)}),0)}),1)],1):_c('div',{staticClass:"gateways-add-select"},_vm._l((Object.entries(_vm.gatewayListFiltered)),function([key, gateway]){return _c('GatewayAddItem',{key:key,attrs:{"gateway-key":key,"gateway":gateway},on:{"click":_vm.gatewaysHandle}})}),1)]}}],null,false,4016150710)})],1):_c('div',[(_vm.currentComponent)?_c(_vm.currentComponent.component,{tag:"component",attrs:{"entities":_vm.entities,"entitiesFullList":_vm.entitiesFullList,"editdata":_vm.editGatewaData,"stripeAccounts":_vm.stripeAccounts,"gatewayName":_vm.currentComponent.gatewayName,"isBit":_vm.currentComponent.isBit,"isGooglePay":_vm.currentComponent.isGooglePay,"isAch":_vm.currentComponent.isAch},on:{"close-modal":function($event){return _vm.modalClose()},"back-to-list":_vm.backToList,"create-entity":_vm.createEntity,"create-stripe-acc":function($event){_vm.gatewayStep = 'stripe'}}}):_vm._e()],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }