<template>
  <div class="topstat-block" v-if="Object.keys(campaign.attributes).length > 0">
    <div class="count-block count-block--dropdown">
      <div class="count-block__img">
        <svg
          aria-hidden="true"
          data-prefix="fas"
          data-icon="arrow-up"
          class="svg-inline--fa fa-arrow-up fa-w-14"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 448 512"
        >
          <path
            fill=""
            d="M34.9 289.5l-22.2-22.2c-9.4-9.4-9.4-24.6 0-33.9L207 39c9.4-9.4 24.6-9.4 33.9 0l194.3 194.3c9.4 9.4 9.4 24.6 0 33.9L413 289.4c-9.5 9.5-25 9.3-34.3-.4L264 168.6V456c0 13.3-10.7 24-24 24h-32c-13.3 0-24-10.7-24-24V168.6L69.2 289.1c-9.3 9.8-24.8 10-34.3.4z"
          ></path>
        </svg>
      </div>
      <div class="count-block__data">
        <div class="count-block__title">{{ $t("dashboard.total") }}</div>
        <div class="count-block__moment" v-if="showInLastHour">
          {{ campaign.attributes.currency_sign }}
          {{ campaign.attributes.period_raised.toLocaleString() }}
          {{ $t("dashboard.in_last_hour") }}
        </div>
        <div class="count-block__total">
          {{ campaign.attributes.currency_sign }}
          {{ campaign.attributes.total_raised.toLocaleString() }}
        </div>
      </div>
      <div class="count-block__spread">
        <div class="online-total">
          <div class="online-total__title">
            {{ $t("dashboard.total_online") }}
          </div>
          <div class="online-total__result">
            {{ campaign.attributes.currency_sign }}
            {{
              (
                campaign.attributes.total_raised - campaign.attributes.total_raised_checks
              ).toLocaleString()
            }}
          </div>
        </div>
        <div class="offline-total">
          <div class="online-total__title">
            {{ $t("dashboard.total_offline") }}
          </div>
          <div class="online-total__result">
            {{ campaign.attributes.currency_sign }}
            {{ campaign.attributes.total_raised_checks.toLocaleString() }}
          </div>
        </div>
      </div>
      <dashboardTopStatDropDown
        v-if="campaign.attributes"
        :currency="campaign.attributes.currency_sign"
        :totals="dropDownTotals"
        :gateways="dropDownData()"
      />
    </div>

    <div class="topstat-block__section">
      <div class="count-block w-100">
        <div class="count-block__img">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path
              fill="#2883A2"
              d="M256 416c114.9 0 208-93.1 208-208S370.9 0 256 0 48 93.1 48 208s93.1 208 208 208zM233.8 97.4V80.6c0-9.2 7.4-16.6 16.6-16.6h11.1c9.2 0 16.6 7.4 16.6 16.6v17c15.5.8 30.5 6.1 43 15.4 5.6 4.1 6.2 12.3 1.2 17.1L306 145.6c-3.8 3.7-9.5 3.8-14 1-5.4-3.4-11.4-5.1-17.8-5.1h-38.9c-9 0-16.3 8.2-16.3 18.3 0 8.2 5 15.5 12.1 17.6l62.3 18.7c25.7 7.7 43.7 32.4 43.7 60.1 0 34-26.4 61.5-59.1 62.4v16.8c0 9.2-7.4 16.6-16.6 16.6h-11.1c-9.2 0-16.6-7.4-16.6-16.6v-17c-15.5-.8-30.5-6.1-43-15.4-5.6-4.1-6.2-12.3-1.2-17.1l16.3-15.5c3.8-3.7 9.5-3.8 14-1 5.4 3.4 11.4 5.1 17.8 5.1h38.9c9 0 16.3-8.2 16.3-18.3 0-8.2-5-15.5-12.1-17.6l-62.3-18.7c-25.7-7.7-43.7-32.4-43.7-60.1.1-34 26.4-61.5 59.1-62.4zM480 352h-32.5c-19.6 26-44.6 47.7-73 64h63.8c5.3 0 9.6 3.6 9.6 8v16c0 4.4-4.3 8-9.6 8H73.6c-5.3 0-9.6-3.6-9.6-8v-16c0-4.4 4.3-8 9.6-8h63.8c-28.4-16.3-53.3-38-73-64H32c-17.7 0-32 14.3-32 32v96c0 17.7 14.3 32 32 32h448c17.7 0 32-14.3 32-32v-96c0-17.7-14.3-32-32-32z"
            ></path>
          </svg>
        </div>
        <div class="count-block__data">
          <div class="count-block__title">
            {{ $t("dashboard.donation") }}
          </div>
          <div class="count-block__moment" v-if="showInLastHour">
            {{ campaign.attributes.period_donations }} {{ $t("dashboard.in_last_hour") }}
          </div>
          <div class="count-block__total">
            {{ campaign.attributes.total_donations }}
          </div>
        </div>
      </div>

      <!-- hide for now https://app.clickup.com/t/85zt885qb -->
      <!-- <div class="count-block">
        <div class="count-block__img">
          <svg
            aria-hidden="true"
            data-prefix="fas"
            data-icon="eye"
            class="svg-inline--fa fa-eye fa-w-18"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 576 512"
          >
            <path
              fill="#2883A2"
              d="M569.354 231.631C512.969 135.949 407.81 72 288 72 168.14 72 63.004 135.994 6.646 231.631a47.999 47.999 0 0 0 0 48.739C63.031 376.051 168.19 440 288 440c119.86 0 224.996-63.994 281.354-159.631a47.997 47.997 0 0 0 0-48.738zM288 392c-75.162 0-136-60.827-136-136 0-75.162 60.826-136 136-136 75.162 0 136 60.826 136 136 0 75.162-60.826 136-136 136zm104-136c0 57.438-46.562 104-104 104s-104-46.562-104-104c0-17.708 4.431-34.379 12.236-48.973l-.001.032c0 23.651 19.173 42.823 42.824 42.823s42.824-19.173 42.824-42.823c0-23.651-19.173-42.824-42.824-42.824l-.032.001C253.621 156.431 270.292 152 288 152c57.438 0 104 46.562 104 104z"
            ></path>
          </svg>
        </div>
        <div class="count-block__data">
          <div class="count-block__title">
            {{ $t("dashboard.page_visits") }}
          </div>
          <div class="count-block__moment" v-if="showInLastHour">
            {{ campaign.attributes.period_page_visits.toLocaleString() }}
            {{ $t("dashboard.in_last_hour") }}
          </div>
          <div class="count-block__total">
            {{
              parseInt(campaign.attributes.total_page_visits) > 0
                ? campaign.attributes.total_page_visits.toLocaleString()
                : "-"
            }}
          </div>
        </div>
      </div> -->

      <!--
      <div class="count-block">
        <div class="count-block__data">
          <div class="count-block__title">{{ $t('dashboard.conversion') }}</div>
          <div class="count-block__moment" v-if='showInLastHour && conversionPeriod'>{{ conversionPeriod }} {{ $t('dashboard.in_last_hour') }}</div>
          <div v-else-if='showInLastHour && !conversionPeriod'>
            {{ $t('dashboard.conversion_period_infinity') }}
          </div>
          <div class="count-block__total">{{ conversionTotal }}</div>
        </div>
      </div>-->
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import dashboardTopStatDropDown from "./dashboardTopStatDropDown.vue";

export default {
  components: {
    dashboardTopStatDropDown,
  },
  data() {
    return {
      showTotalDetails: false,
    };
  },
  computed: {
    ...mapState({
      campaign: (state) => {
        return state.dashboard.campaignDashboard?.data || { attributes: {} };
      },
    }),
    showInLastHour() {
      return this.campaign.attributes.mode == 1 || this.campaign.attributes.mode == 2;
    },
    conversionTotal() {
      if (this.campaign.attributes.total_page_visits) {
        let precent =
          (this.campaign.attributes.total_donations * 100) /
          this.campaign.attributes.total_page_visits;

        return precent ? `${precent.toFixed(2)}%` : "0%";
      } else {
        return this.$t("dashboard.conversion_total_infinity");
      }
    },
    conversionPeriod() {
      if (this.campaign.attributes.period_page_visits > 0) {
        let precent =
          (this.campaign.attributes.period_donations * 100) /
          this.campaign.attributes.period_page_visits;

        return precent ? `${precent.toFixed(2)}%` : "0%";
      } else {
        return false;
      }
    },
    dropDownTotals() {
      const { total_raised, total_raised_checks, gateway_amount } = this.campaign.attributes;

      const { total_real_raised, total_real_raised_checks } = Object.entries(
        gateway_amount || {}
      ).reduce(
        (res, [k, v]) => {
          if (k === "check") {
            res.total_real_raised_checks += v.real;
          }
          res.total_real_raised += v.real;
          return res;
        },
        {
          total_real_raised: 0,
          total_real_raised_checks: 0,
        }
      );

      return {
        online: total_raised - total_raised_checks,
        offline: total_raised_checks,

        onlineReal: total_real_raised - total_real_raised_checks || 0,
        offlineReal: total_real_raised_checks || 0,
      };
    },
  },
  methods: {
    dropDownData() {
      const data = this.campaign.attributes.gateway_amount;
      if (data === null || data === undefined) {
        return [];
      }
      return Object.entries(data).sort((a, b) => (b[1]?.real || 0) - (a[1]?.real || 0));
    },
  },
};
</script>

<style lang="scss" scoped>
.count-block {
  &--dropdown {
    position: relative;
    &:hover {
      ::v-deep .top-stat-dropdown {
        visibility: visible;
      }
    }
  }
}
</style>
