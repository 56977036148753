import { useCampaignSettings } from "@/composables/campaign-settings";

const { createSetting, getSetting, updateSetting } = useCampaignSettings();

export async function getGoogleTagManager({ cid, oid } = {}) {
  return getSetting({ cid, oid }, "google_tag_manager").then((data) => {
    const metaData = data?.attributes?.meta_data || {};
    const gtm = {
      id: metaData?.id || "",
      inject_as_text: metaData?.inject_as_text || false,
      __data: data,
    };
    return gtm;
  });
}

export async function updateGoogleTagManager({ cid, oid } = {}, gtm) {
  const { __data, ...data } = gtm;
  if (!__data) {
    return createSetting({ cid, oid }, "google_tag_manager", {
      value: true,
      ...data,
    }).then(getGoogleTagManager.bind(undefined, { cid, oid }));
  }

  return updateSetting({ cid, oid }, "google_tag_manager", gtm.__data.id, {
    value: true,
    ...data,
  }).then(getGoogleTagManager.bind(undefined, { cid, oid }));
}
