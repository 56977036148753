<template>
  <div class="recent-activity">
    <h2 class="dashboard-block__title">{{ $t("dashboard.recent_activity") }}</h2>

    <ul class="recent-list" v-if="recentActivity.length > 0">
      <li v-for="(item, i) in recentActivity" :key="`recent-activity-item-${i}`">
        <div class="recent-list-card">
          <div class="recent-list-card__left">
            <div class="recent-list-card__date">{{ setFormatDate(item.details.date) }}</div>

            <div class="recent-list-card__info">{{ setAgoDate(item.details.date) }}</div>
          </div>

          <div class="recent-list-card__content">
            <div class="recent-list-card__message">
              <span>
                {{ item.details.display_name }}
              </span>
              <span>{{ $t("dashboard.activity_donate") }}</span>
              <span>{{ item.details.currency_sign }}{{ item.details.charge_amount }}</span>
            </div>
            <!--
            <div class="recent-list-card__message">
              {{ item.details.dedication }}
            </div>
            -->
          </div>
        </div>
      </li>
    </ul>

    <div v-if="recentActivity.length == 0" class="empty">
      <p>
        {{ $t("dashboard.donations_empty") }}
      </p>
    </div>
    <!--
    <div class="recent-total">
      {{ $t('dashboard.activity_total', { count: recentActivity.length }, recentActivity.length) }}
    </div>
    -->
  </div>
</template>

<script>
import { mapState } from "vuex";

import moment from "moment";

export default {
  computed: mapState({
    recentActivity: (state) => {
      return state.dashboard.campaignDashboard.data.attributes.activity_list;
    },

    selectedLanguage: (state) => {
      return state.account.account.data.attributes.lang;
    },
  }),

  methods: {
    setFormatDate: function (date) {
      return moment(date).format("MMMM DD, YYYY") === moment().format("MMMM DD, YYYY")
        ? "Today"
        : moment(date).format("MMMM DD, YYYY");
    },

    setAgoDate: function (date) {
      return moment(date).locale(this.selectedLanguage).fromNow();
    },
  },
};
</script>

<style lang="scss">
@import "../../../styles/_vars.scss";

.empty {
  width: 100%;
  height: auto;
  padding-top: 50px;
  padding-bottom: 50px;
}

.recent-activity {
  width: 100%;
  max-height: 600px;
}

.recent-list {
  max-height: 320px;
  overflow-y: auto;
  list-style: none;
  padding: 2px 5px 2px 0;
  margin: 0;
  li:not(:first-of-type) {
    margin-top: -1px;
  }
}

.recent-list-card {
  width: 100%;
  min-height: 90px;
  display: flex;
  border: 1px solid #e6eaed;
  border-radius: 4px;
  overflow: hidden;
  &__left {
    padding-left: 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    width: 40%;
    background-color: $bg-50;
  }
  &__date {
    width: 100%;
    font-size: 14px;
    color: $fd;
  }
  &__info {
    color: $bb-100;
    font-size: 14px;
    text-align: left;
  }
  &__content {
    width: 40%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 0 5px 0 15px;
  }
  &__message {
    font-size: 14px;
    color: $fd;
    span {
      width: 100%;
      display: inline-block;
      &:last-of-type {
        color: $bb-100;
      }
    }
  }
}

.recent-total {
  text-align: center;
  color: #888888;
}
</style>
