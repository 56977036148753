<template>
  <div class="mobile-media">
    <SliderMobileScreen1 v-if="screen === 'm0'" />
    <SliderMobileScreen2 v-if="screen === 'm1'" />

    <div class="mobile-media__container">
      <div v-if="imageset == 0" class="media-placeholder">
        <div
          class="media-placeholder__unit"
          :class="[
            { 'media-placeholder__unit--m0': screen === 'm0' },
            { 'media-placeholder__unit--m0': screen === 'm1' },
          ]"
          style="
            background-image: url('http://hdimages.org/wp-content/uploads/2017/03/placeholder-image10.jpg');
          "
        ></div>
      </div>

      <swiper ref="mySwiper" :options="swiperOption" class="swiper-box">
        <swiper-slide
          class="swiper-item"
          v-for="(image, i) in imageset"
          :key="`smob-${i}`"
          :class="`${tag}`"
        >
          <img class="swiper-item__img" :src="image.attributes.src" width="160" height="96" />

          <div class="swiper-item__control">
            <div class="swiper-item__control-buttons" v-if="tag !== 'shared_image'">
              <button
                type="button"
                :disabled="!hasAccess || load"
                class="bttn-round"
                @click="changeOrderUp(image)"
                :title="$t('edit_campaign.image_change_order_up')"
              >
                <b-icon-arrow-up-short />
              </button>

              <button
                type="button"
                :disabled="!hasAccess"
                class="bttn-round"
                @click="delFile(image)"
                :title="$t('edit_campaign.image_delete')"
              >
                <IconDeleteFile />
              </button>

              <input
                type="file"
                :disabled="!hasAccess"
                :id="`update_file_${image.id}`"
                @change="updFile($event, image)"
              />
              <label
                class="bttn-round"
                :class="{
                  disabled: !hasAccess,
                }"
                :for="`load_file_${image.id}`"
              >
                <IconUpdateFile />
              </label>
              <input
                v-if="!isOnlyOne"
                type="file"
                :disabled="!hasAccess"
                :id="`load_file_${image.id}`"
                @change="uplFile($event)"
              />
              <label
                v-if="!isOnlyOne"
                class="bttn-round"
                :class="{
                  disabled: !hasAccess,
                }"
                :for="`load_file_${image.id}`"
              >
                <IconUploadFile />
              </label>

              <button
                type="button"
                :disabled="!hasAccess || load || currentImageOrder === 0"
                class="bttn-round"
                @click="changeOrderDown(image)"
                :title="$t('edit_campaign.image_change_order_down')"
              >
                <b-icon-arrow-down-short />
              </button>
            </div>
            <p class="swiper-item__control-order text-center" v-if="tag !== 'shared_image'">
              Order: {{ currentImageOrder }}
            </p>
          </div>
        </swiper-slide>

        <div
          v-if="tag !== 'shared_image'"
          v-show="imageset.length > 1"
          class="!tw-z-auto swiper-pagination"
          slot="pagination"
        ></div>

        <div v-show="imageset.length > 1" class="bttn-round slide-controls prev" slot="button-prev">
          <IconSlidePrev />
        </div>

        <div v-show="imageset.length > 1" class="bttn-round slide-controls next" slot="button-next">
          <IconSlideNext />
        </div>
      </swiper>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import IconSlidePrev from "@/views/campaigns/campaign-images/iconSlidePrev.vue";
import IconSlideNext from "@/views/campaigns/campaign-images/iconSlideNext.vue";
import IconDeleteFile from "@/views/campaigns/campaign-images/iconDeleteFile.vue";
import IconUpdateFile from "@/views/campaigns/campaign-images/iconUpdateFile.vue";
import IconUploadFile from "@/views/campaigns/campaign-images/iconUploadFile.vue";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import SliderMobileScreen1 from "./sliderMobileScreen1.vue";
import SliderMobileScreen2 from "./sliderMobileScreen2.vue";

export default {
  props: ["imageset", "screen", "tag", "onlyOne", "uploadImmediately", "load"], // container: set container="full" for full screen image on mobile
  data() {
    return {
      swiperOption: {
        slidePerView: 1,
        effect: "fade",
        allowTouchMove: false,
        pagination: {
          el: ".swiper-pagination",
          type: "fraction",
        },
        navigation: {
          nextEl: ".next",
          prevEl: ".prev",
          disabledClass: "disabled",
        },
      },
      currentSlide: 0,
    };
  },
  components: {
    swiper,
    swiperSlide,
    IconSlidePrev,
    IconSlideNext,
    IconDeleteFile,
    IconUpdateFile,
    IconUploadFile,
    SliderMobileScreen1,
    SliderMobileScreen2,
  },
  created() {
    if (this.screen === "full") {
      this.swiperOption.width = 71;
    }
    if (this.screen === "smm") {
      this.swiperOption.width = 60;
    }
  },
  computed: {
    ...mapState({
      hasAccess: (state) => {
        return state.account.access.CanEditCampaignMedia;
      },

      isOnlyOne() {
        return typeof this.onlyOne !== "undefined";
      },
    }),
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
    currentImageOrder() {
      return this.imageset[this.currentSlide]?.attributes.order || 0;
    },
  },

  watch: {
    imageset: function (n, o) {
      if (n.length !== o.length) {
        setTimeout(() => {
          this.slideTo(n.length);
        }, 500);
      }
    },
  },

  mounted() {
    this.slideTo(0);
    this.currentSlide = this.swiper.activeIndex;
    this.swiper.on("slideChange", () => {
      this.currentSlide = this.swiper.activeIndex;
    });
  },

  methods: {
    updFile(e, image) {
      if (e.target.files[0]) {
        this.$emit("cUpdFile", e.target.files[0], image);
        e.target.type = "text";
        e.target.type = "file";
      }
    },

    uplFile(e) {
      this.$emit("cUplFile", e, this.tag, this.uploadImmediately);
    },

    delFile: function (image) {
      let message = this.$t("edit_campaign.confirm_delete_image");

      let options = {
        okText: this.$t("account.confirm_delete"),
        cancelText: this.$t("account.confirm_cancel"),
      };

      this.$dialog.confirm(message, options).then(() => {
        this.$emit("cDelFile", image);
      });
    },

    changeOrderUp(image) {
      this.$emit("changeOrderUp", image);
    },

    changeOrderDown(image) {
      this.$emit("changeOrderDown", image);
    },

    slideTo(n) {
      this.swiper.slideTo(n, 200);
    },
  },
};
</script>

<style lang="scss" scoped>
.mobile-media {
  width: 400px;
  height: 320px;
  display: flex;
  flex-shrink: 0;
  overflow: hidden;
  position: relative;
  @media only screen and (min-width: 1360px) {
    width: 100%;
    height: 470px;
  }
  &::v-deep > svg {
    display: block;
    width: 233px;
    height: auto;
    transform: translateX(-50%) translateY(-50%) translateZ(0px);
    position: absolute;
    top: 53%;
    left: 50%;
    @media only screen and (min-width: 1360px) {
      width: 320px;
    }
    @media only screen and (min-width: 1600px) {
      width: 376px;
    }
  }
  &__container {
    width: 290px;
    height: 100%;
    display: flex;
    background-color: transparent;
    transform: translateX(-50%) translateZ(0px);
    position: absolute;
    top: 40px;
    left: 50%;
    @media only screen and (min-width: 1360px) {
      top: 69px;
    }
    @media only screen and (min-width: 1600px) {
      top: 38px;
    }
    &.full {
      width: 72px;
      height: 200px;
      top: 11px;
      left: 50%;
      ::v-deep .swiper-item {
        height: 150px;
        border-radius: 5px;
        &__control {
          top: 173px;
        }
      }
      .slide-controls {
        top: 173px;
        &.prev {
          left: -62%;
        }
        &.next {
          right: -66%;
        }
      }
    }
    &.smm {
      width: 60px;
      top: 24px;
      left: 50%;
      ::v-deep .swiper-item {
        height: 70px;
        &__control {
          top: 162px;
        }
      }
      .slide-controls {
        top: 162px;
        &.prev {
          left: -86%;
        }
        &.next {
          right: -90%;
        }
      }
    }
  }
  .slide-controls {
    background-color: transparent;
    border: 1px solid #107598;
    position: absolute;
    top: 216px;
    z-index: 1001;
    @media only screen and (min-width: 1360px) {
      top: 294px;
    }
    @media only screen and (min-width: 1600px) {
      top: 359px;
    }
    > svg {
      fill: #107598;
    }
    &:hover {
      border-color: lighten(#107598, 5%);
      background-color: transparent;
      svg {
        fill: lighten(#107598, 5%);
      }
    }
    &.prev {
      left: 0;
    }
    &.next {
      right: 0;
    }
    &.disabled {
      border-color: #bbbcc4;
      svg {
        fill: #bbbcc4;
      }
    }
  }
}

::v-deep .swiper-box {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: inline-flex;
}
::v-deep .swiper-wrapper {
  display: flex;
  justify-content: center;
}
::v-deep .swiper-item {
  &.dinner_top_right {
    background-size: contain;
    background-position: right top;
    height: 45px;
    &__img {
      @media only screen and (min-width: 1360px) {
        height: 60px;
      }
      @media only screen and (min-width: 1600px) {
        height: 70px;
      }
    }
  }
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  position: relative;
  &__img {
    object-fit: cover;

    @media only screen and (min-width: 1360px) {
      height: 84px;
    }
    @media only screen and (min-width: 1600px) {
      height: 96px;
    }
  }
  &.swiper-slide-active {
    z-index: 99;
    .swiper-item__control {
      display: flex;
      flex-direction: column;
      z-index: 100;
      gap: 10px;
    }
    .swiper-item__control-buttons {
      display: inline-flex;
    }
  }
  &__control {
    display: none;
    position: absolute;
    top: 216px;
    @media only screen and (min-width: 1360px) {
      top: 294px;
    }
    @media only screen and (min-width: 1600px) {
      top: 359px;
    }
    .bttn-round {
      box-shadow: none;
      flex-shrink: 0;
      margin-bottom: 0;
    }
    .bttn-round:not(:last-child) {
      margin-right: 8px;
    }
  }
}

::v-deep .swiper-pagination {
  transform: translate3d(-50%, 0, 0);
  position: absolute;
  top: 176px;
  left: 50%;
  z-index: 120;
  @media only screen and (min-width: 470px) {
    top: 176px;
  }
  @media only screen and (min-width: 1360px) {
    top: 255px;
  }
  @media only screen and (min-width: 1600px) {
    top: 305px;
  }
}

.media-placeholder {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  &__unit {
    width: 100%;
    background-position: center center;
    background-size: cover;
    opacity: 0.5;
    &--m0 {
      height: 60px;
      @media only screen and (min-width: 1360px) {
        height: 84px;
      }
      @media only screen and (min-width: 1600px) {
        height: 95px;
      }
    }
  }
}

.bttn-round {
  svg {
    overflow: visible;
  }
}

input[type="file"] {
  width: 1px;
  height: 1px;
  overflow: hidden;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.disabled {
  cursor: not-allowed;
  opacity: 0.6;
}
</style>
