<template lang="html">
  <b-modal id="joinGdModal" size="lg">
    <div slot="modal-header">
      <h2>{{ $t("edit_campaign.join_gd_header") }}</h2>
      <div class="modal-header__subtitle">{{ $t("edit_campaign.join_gd_text") }}</div>
    </div>

    <div slot="modal-footer">
      <button class="bttn bttn--lg bttn--blue" @click.prevent="hideModal">
        {{ $t("edit_campaign.cancel") }}
      </button>
      <button
        v-if="gdarr.length > 0"
        form="entaties-add"
        :class="
          loading || !gdSelected.id
            ? 'disabled bttn bttn--lg bttn--orange'
            : 'bttn bttn--lg bttn--orange'
        "
        :disabled="loading || !gdSelected.id"
      >
        {{ $t("edit_campaign.join_gd") }}
      </button>
    </div>

    <form id="entaties-add" @submit.prevent="changemultiselectdata" class="entities-data">
      <error v-if="errors" :errors="errors"></error>

      <div class="row" v-if="gdarr.length > 0">
        <div class="col-12 col-sm-12">
          <div class="form-group form-unit">
            <label class="form-unit__label form-unit__label--upper-sm">{{
              $t("edit_campaign.select_dg")
            }}</label>
            <multiselect v-model="multiselect" :options="gdarr" label="name" track-by="id">
            </multiselect>
          </div>
        </div>
      </div>

      <div class="row" v-else>
        <div class="col-12 col-sm-12">
          <p>{{ $t("edit_campaign.empty_join_gd") }}</p>
        </div>
      </div>
    </form>
  </b-modal>
</template>

<script>
import Multiselect from "vue-multiselect";

import error from "@/components/errors.vue";

import axios from "axios";

import urls from "@/constants/urls.js";

export default {
  props: {
    selectedid: {
      type: Number,
      default: 0,
    },
    campaignid: {
      default: 0,
    },
    gdarr: {
      type: Array,
      default: () => [],
    },
    multiselectdata: {
      default: {},
    },
  },

  components: {
    Multiselect,
    error,
  },

  data: function () {
    return {
      errors: [],
      loading: false,
      gdSelected: {},
      multiselect: null,
    };
  },

  watch: {
    multiselect: function () {
      if (this.multiselect) {
        this.gdSelected = this.multiselect;
      } else {
        this.gdSelected = {};
      }
    },
  },

  methods: {
    openModal: function () {
      this.$root.$emit("bv::show::modal", "joinGdModal");
    },

    hideModal() {
      this.$root.$emit("bv::hide::modal", "joinGdModal");
    },

    changemultiselectdata: function () {
      if (this.gdSelected.id) {
        let data = {
          attributes: {
            campaign_id: parseInt(this.campaignid),
            givingday_campaign_id: parseInt(this.gdSelected.id),
          },
        };

        this.loading = true;

        this.errors = [];

        axios
          .post(
            urls.joinGd
              .replace(":orgId", localStorage.getItem("orgId"))
              .replace(":campaignId", this.campaignid),
            { data: data }
          )
          .then(() => {
            this.loading = false;

            this.$_notificationsMixin_makeToastCompat({
              group: "Notification",
              title: this.$t("edit_campaign.parent_campaign_pending", {
                campaign: this.gdSelected.name,
              }),
            });

            this.$emit("join-gd");

            this.hideModal();
          })
          .catch((e) => {
            this.errors.push(e.response);
            this.loading = false;
          });
      }
    },
  },

  created: function () {
    this.gdSelected = this.multiselectdata;
  },
};
</script>

<style lang="scss">
#joinGdModal {
  .form-unit {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
  }
  .multiselect {
    max-width: 300px;
    margin-left: 20px;
  }
  label {
    margin: 0;
  }
}
</style>
