<template>
  <div class="mobile-media">
    <svg
      v-if="screen === 'm0'"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      width="233"
      height="351"
      viewBox="0 0 233 351"
    >
      <defs>
        <path
          id="b"
          d="M20.947 0h72.751C105.684 0 115 4.785 115 20.95V208.38c0 8.631-.415 23.621-20.235 23.621H18.04C9.396 232 0 226.787 0 211.999V20.951C0 3.698 10.776 0 20.947 0z"
        />
        <filter
          id="a"
          width="282.6%"
          height="190.5%"
          x="-91.3%"
          y="-32.3%"
          filterUnits="objectBoundingBox"
        >
          <feOffset dy="30" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="30" />
          <feComposite
            in="shadowBlurOuter1"
            in2="SourceAlpha"
            operator="out"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            in="shadowBlurOuter1"
            values="0 0 0 0 0.0385386904 0 0 0 0 0.0667825873 0 0 0 0 0.197677509 0 0 0 0.0744735054 0"
          />
        </filter>
        <path
          id="c"
          d="M72.419 8.187c4.207.059 6.055-3.679 6.104-6.289 0-1.384.398-1.839 1.956-1.839h9.008c5.155 0 11.082 2.373 11.082 11.51v194.82c0 7.356-3.912 11.508-12.623 11.508H11.674C2.785 217.897 0 212.5 0 206.567V10.915C0 3.44 5.334 0 10.312 0h10.015c1.422 0 1.719.83 1.719 1.78 0 .949.51 6.308 6.4 6.347 4.978 0 38.343.06 43.973.06z"
        />
        <filter
          id="d"
          width="106%"
          height="102.8%"
          x="-3%"
          y="-1.4%"
          filterUnits="objectBoundingBox"
        >
          <feOffset in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="1" />
          <feColorMatrix
            in="shadowBlurOuter1"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0"
          />
        </filter>
      </defs>
      <g fill="none" fill-rule="evenodd">
        <g transform="translate(59 29)">
          <use fill="#000" filter="url(#a)" xlink:href="#b" />
          <path
            fill="#FFF"
            stroke="#979797"
            stroke-linejoin="square"
            stroke-opacity=".173"
            stroke-width="1.272"
            d="M20.947.636C8.089.636.636 6.426.636 20.951v191.048c0 12.713 7.238 19.365 17.403 19.365h76.726c8.776 0 14.15-2.929 16.989-8.238 2.062-3.856 2.61-7.97 2.61-14.747v-10.45-21.923-62.965-92.09c0-14.265-7.198-20.315-20.666-20.315H20.947z"
          />
        </g>
        <g transform="translate(66.188 37.646)">
          <use fill="#000" filter="url(#d)" xlink:href="#c" />
          <use fill="#F7F8FA" xlink:href="#c" />
        </g>
        <g fill="#F0F3F5" transform="translate(109.313 37.646)">
          <path
            d="M.99.796h11.883c.547 0 .99.505.99 1.128 0 .623-.443 1.128-.99 1.128H.99c-.547 0-.99-.505-.99-1.128C0 1.301.443.796.99.796z"
          />
          <ellipse cx="19.106" cy="1.858" rx="1.631" ry="1.858" />
        </g>
      </g>
    </svg>
    <svg
      v-if="screen === 'm1'"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      width="234"
      height="351"
      viewBox="0 0 233 351"
    >
      <defs>
        <path
          id="b"
          d="M20.947 0h72.751C105.684 0 115 4.779 115 20.923v187.18c0 8.62-.415 23.59-20.235 23.59H18.04C9.396 231.693 0 226.487 0 211.718V20.923C0 3.693 10.776 0 20.947 0z"
        />
        <filter
          id="a"
          width="282.6%"
          height="190.6%"
          x="-91.3%"
          y="-32.4%"
          filterUnits="objectBoundingBox"
        >
          <feOffset dy="30" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="30" />
          <feColorMatrix
            in="shadowBlurOuter1"
            values="0 0 0 0 0.0385386904 0 0 0 0 0.0667825873 0 0 0 0 0.197677509 0 0 0 0.0744735054 0"
          />
        </filter>
        <path
          id="c"
          d="M72.535 8.18c4.214.059 6.065-3.676 6.114-6.283 0-1.383.399-1.838 1.959-1.838h9.023c5.163 0 11.099 2.371 11.099 11.499v194.648c0 7.35-3.918 11.499-12.643 11.499H11.692C2.79 217.705 0 212.31 0 206.384V10.906C0 3.438 5.342 0 10.328 0H20.36c1.424 0 1.721.83 1.721 1.778 0 .948.512 6.302 6.41 6.342 4.987 0 38.405.06 44.044.06z"
        />
        <filter
          id="d"
          width="106%"
          height="102.8%"
          x="-3%"
          y="-1.4%"
          filterUnits="objectBoundingBox"
        >
          <feOffset in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="1" />
          <feColorMatrix
            in="shadowBlurOuter1"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0"
          />
        </filter>
      </defs>
      <g fill="none" fill-rule="evenodd" transform="translate(59.5 29)">
        <use fill="#000" filter="url(#a)" xlink:href="#b" />
        <use fill="#FFF" xlink:href="#b" />
        <g transform="translate(6.809 8.573)">
          <use fill="#000" filter="url(#d)" xlink:href="#c" />
          <use fill="#F7F8FA" xlink:href="#c" />
        </g>
        <g fill="#F0F3F5" transform="translate(50.083 9.365)">
          <path d="M1.009.711h12.105a1.008 1.008 0 1 1 0 2.015H1.009a1.008 1.008 0 1 1 0-2.015z" />
          <ellipse cx="19.463" cy="1.66" rx="1.662" ry="1.66" />
        </g>
        <path
          fill="#E6EAED"
          d="M19.25 147.506h76.5a2 2 0 0 1 2 2v1.729a2 2 0 0 1-2 2h-76.5a2 2 0 0 1-2-2v-1.729a2 2 0 0 1 2-2z"
          opacity=".8"
        />
        <rect
          width="43.125"
          height="5.728"
          x="17.25"
          y="160.395"
          fill="#E6EAED"
          opacity=".8"
          rx="2"
        />
        <rect
          width="25.875"
          height="5.728"
          x="64.688"
          y="160.395"
          fill="#E6EAED"
          opacity=".8"
          rx="2"
        />
        <rect
          width="21.563"
          height="5.728"
          x="17.25"
          y="186.173"
          fill="#E6EAED"
          opacity=".8"
          rx="2"
        />
        <rect
          width="25.875"
          height="5.728"
          x="43.125"
          y="186.173"
          fill="#E6EAED"
          opacity=".8"
          rx="2"
        />
        <path
          fill="#E6EAED"
          d="M73.875 186.173h24.75a2 2 0 0 1 2 2v1.728a2 2 0 0 1-2 2h-24.75a2 2 0 0 1-2-2v-1.728a2 2 0 0 1 2-2zM19.25 173.284h75.063a2 2 0 0 1 2 2v1.728a2 2 0 0 1-2 2H19.25a2 2 0 0 1-2-2v-1.728a2 2 0 0 1 2-2z"
          opacity=".8"
        />
      </g>
    </svg>

    <div class="mobile-media__container">
      <div v-if="imageset == 0" class="media-placeholder">
        <div
          class="media-placeholder__unit"
          :class="[
            { 'media-placeholder__unit--m0': screen === 'm0' },
            { 'media-placeholder__unit--m0': screen === 'm1' },
          ]"
          style="
            background-image: url('http://hdimages.org/wp-content/uploads/2017/03/placeholder-image10.jpg');
          "
        ></div>
      </div>

      <swiper ref="mySwiper" :options="swiperOption" class="swiper-box">
        <swiper-slide
          class="swiper-item"
          v-for="image in imageset"
          :key="`slider-mobile-item-${image.id}`"
          :style="'background-image:url(' + image.attributes.src + ')'"
        >
          <div class="swiper-item__control">
            <button
              :disabled="!hasAccess"
              class="bttn-round"
              @click="delFile(image)"
              :title="$t('edit_campaign.image_delete')"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24">
                <path
                  d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"
                ></path>
                <path d="M0 0h24v24H0z" fill="none"></path>
              </svg>
            </button>

            <input
              type="file"
              :disabled="!hasAccess"
              :id="'update_file_' + image.id"
              @change="updFile($event, image)"
            />
            <label
              :class="!hasAccess ? 'bttn-round disabled' : 'bttn-round'"
              :for="'update_file_' + image.id"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24">
                <path d="M0 0h24v24H0z" fill="none"></path>
                <path
                  d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM14 13v4h-4v-4H7l5-5 5 5h-3z"
                ></path>
              </svg>
            </label>
            <input
              type="file"
              :disabled="!hasAccess"
              :id="'load_file_' + image.id"
              @change="uplFile($event)"
            />
            <label
              :class="!hasAccess ? 'bttn-round disabled' : 'bttn-round'"
              :for="'load_file_' + image.id"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path d="M0 0h24v24H0z" fill="none" />
                <path
                  d="M13 7h-2v4H7v2h4v4h2v-4h4v-2h-4V7zm-1-5C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"
                />
              </svg>
            </label>
          </div>
        </swiper-slide>

        <div v-show="imageset.length > 1" class="swiper-pagination" slot="pagination"></div>

        <div v-show="imageset.length > 1" class="bttn-round slide-controls prev" slot="button-prev">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
            <path d="M0 0h24v24H0z" fill="none" />
          </svg>
        </div>

        <div v-show="imageset.length > 1" class="bttn-round slide-controls next" slot="button-next">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
            <path d="M0 0h24v24H0z" fill="none" />
          </svg>
        </div>
      </swiper>

      <!--
      <div class="bttn-round slide-controls prev"
           v-if="imageset.length > 1"
           @click="goPrev">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"/><path d="M0 0h24v24H0z" fill="none"/></svg>
      </div>

      <div class="bttn-round slide-controls next"
           v-if="imageset.length > 1"
           @click="goNext">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"/><path d="M0 0h24v24H0z" fill="none"/></svg>
      </div>
      -->
    </div>
    <!--
    <div class="item-slot item-slot--c-mobile">

      <div class="upload-bar">
        <input type="file" :disabled='!hasAccess' name="" id="countdown_hero_mobile" @change="uplFile($event)">
        <label for="countdown_hero_mobile" class="upload-bar__label">

          <svg width="22px" height="42px" viewBox="0 0 22 42" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g id="images_1" transform="translate(-517.000000, -636.000000)" fill="#107598">
                <g id="Group-16" transform="translate(401.000000, 591.000000)">
                  <g id="noun_Mobile_440898" transform="translate(116.000000, 45.000000)">
                    <path d="M10.992074,14 C11.5944518,14 12.0700132,14.4755614 12.0700132,15.0779392 L12.0700132,18.9299868 L15.9220608,18.9299868 C16.5085865,18.9299868 17,19.4055482 17,19.992074 C17,20.5944518 16.5085865,21.0700132 15.9220608,21.0700132 L12.0700132,21.0700132 L12.0700132,24.9220608 C12.0700132,25.5085865 11.5944518,26 10.992074,26 C10.4055482,26 9.92998679,25.5085865 9.92998679,24.9220608 L9.92998679,21.0700132 L6.07793923,21.0700132 C5.47556143,21.0700132 5,20.5944518 5,19.992074 C5,19.4055482 5.47556143,18.9299868 6.07793923,18.9299868 L9.92998679,18.9299868 L9.92998679,15.0779392 C9.92998679,14.4755614 10.4055482,14 10.992074,14 Z" id="Shape"></path>
                    <path d="M21.9130435,39.2608696 L21.9130435,2.73913043 C21.9130435,1.2326087 20.6804348,0 19.173913,0 L2.73913043,0 C1.2326087,0 0,1.2326087 0,2.73913043 L0,39.2608696 C0,40.7673913 1.2326087,42 2.73913043,42 L19.173913,42 C20.6804348,42 21.9130435,40.7673913 21.9130435,39.2608696 Z M9.13043478,1.82608696 L12.7826087,1.82608696 L12.7826087,2.73913043 L9.13043478,2.73913043 L9.13043478,1.82608696 Z M10.9565217,40.173913 C9.95217391,40.173913 9.13043478,39.3521739 9.13043478,38.3478261 C9.13043478,37.3434783 9.95217391,36.5217391 10.9565217,36.5217391 C11.9608696,36.5217391 12.7826087,37.3434783 12.7826087,38.3478261 C12.7826087,39.3521739 11.9608696,40.173913 10.9565217,40.173913 Z M20.0869565,34.6956522 L1.82608696,34.6956522 L1.82608696,4.56521739 L20.0869565,4.56521739 L20.0869565,34.6956522 Z" id="Shape" fill-rule="nonzero"></path>
                  </g>
                </g>
              </g>
            </g>
          </svg>

          <span>{{ $t('edit_campaign.image_for_mobile') }}</span>

        </label>
      </div>

      <span class="img-cimages__size-text">{{ $t('edit_campaign.image_for_mobile_sizes') }}</span>

    </div> -->
  </div>
</template>

<script>
import { mapState } from "vuex";

import { swiper, swiperSlide } from "vue-awesome-swiper";

export default {
  props: ["imageset", "screen", "tag"], // container: set container="full" for full screen image on mobile
  data() {
    return {
      swiperOption: {
        //width: 70,
        //height: 88,
        slidePerView: 1,
        effect: "fade",
        allowTouchMove: false,
        pagination: {
          el: ".swiper-pagination",
          type: "fraction",
        },
        navigation: {
          nextEl: ".next",
          prevEl: ".prev",
          disabledClass: "disabled",
        },
      },
    };
  },
  components: {
    swiper,
    swiperSlide,
  },
  created() {
    if (this.screen === "full") {
      this.swiperOption.width = 71;
    }
    if (this.screen === "smm") {
      this.swiperOption.width = 60;
    }
  },
  computed: mapState({
    hasAccess: (state) => {
      return state.account.access.CanEditCampaignMedia;
    },
  }),

  watch: {
    imageset: function (n, o) {
      if (n.length !== o.length) {
        setTimeout(() => {
          this.slideTo(n.length);
        }, 500);
      }
    },
  },

  mounted() {
    this.slideTo(0);
  },

  methods: {
    updFile(e, image) {
      if (e.target.files[0]) {
        this.$emit("cUpdFile", e.target.files[0], image);
        e.target.type = "text";
        e.target.type = "file";
      }
    },

    uplFile(e) {
      this.$emit("cUplFile", e, this.tag);
    },

    delFile: function (image) {
      let message = this.$t("edit_campaign.confirm_delete_image");

      let options = {
        okText: this.$t("account.confirm_delete"),
        cancelText: this.$t("account.confirm_cancel"),
      };

      this.$dialog.confirm(message, options).then(() => {
        this.$emit("cDelFile", image);
      });
    },

    slideTo(n) {
      this.$refs.mySwiper.swiper.slideTo(n, 200);
    },
  },
};
</script>

<style lang="scss" scoped>
.mobile-media {
  width: 400px;
  height: 300px;
  display: flex;
  flex-shrink: 0;
  overflow: hidden;
  position: relative;
  @media only screen and (min-width: 1360px) {
    width: 100%;
    height: 450px;
  }
  > svg {
    display: block;
    width: 233px;
    height: auto;
    transform: translateX(-50%) translateY(-50%) translateZ(0px);
    position: absolute;
    top: 53%;
    left: 50%;
    @media only screen and (min-width: 1360px) {
      width: 320px;
    }
    @media only screen and (min-width: 1600px) {
      width: 376px;
    }
  }
  &__container {
    width: 100px;
    height: 170px;
    display: flex;
    //background-color: gray;
    background-color: transparent;
    transform: translateX(-50%) translateZ(0px);
    position: absolute;
    top: 40px;
    left: 50%;
    @media only screen and (min-width: 1360px) {
      top: 69px;
      width: 140px;
    }
    @media only screen and (min-width: 1600px) {
      top: 38px;
      width: 160px;
    }
    &.full {
      width: 72px;
      height: 200px;
      top: 11px;
      left: 50%;
      ::v-deep .swiper-item {
        height: 150px;
        border-radius: 5px;
        &__control {
          top: 173px;
        }
      }
      .slide-controls {
        top: 173px;
        &.prev {
          left: -62%;
        }
        &.next {
          right: -66%;
        }
      }
    }
    &.smm {
      width: 60px;
      top: 24px;
      left: 50%;
      ::v-deep .swiper-item {
        height: 70px;
        &__control {
          top: 162px;
        }
      }
      .slide-controls {
        top: 162px;
        &.prev {
          left: -86%;
        }
        &.next {
          right: -90%;
        }
      }
    }
  }
  .slide-controls {
    background-color: transparent;
    border: 1px solid #107598;
    position: absolute;
    top: 216px;
    z-index: 1001;
    @media only screen and (min-width: 1360px) {
      top: 294px;
    }
    @media only screen and (min-width: 1600px) {
      top: 359px;
    }
    > svg {
      fill: #107598;
    }
    &:hover {
      border-color: lighten(#107598, 5%);
      background-color: transparent;
      svg {
        fill: lighten(#107598, 5%);
      }
    }
    &.prev {
      left: -50px;
    }
    &.next {
      right: -50px;
    }
    &.disabled {
      border-color: #bbbcc4;
      svg {
        fill: #bbbcc4;
      }
    }
  }
}

::v-deep .swiper-box {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: inline-flex;
  //overflow: hidden;
}
::v-deep .swiper-wrapper {
  display: flex;
}
::v-deep .swiper-item {
  width: 100%;
  height: 60px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  @media only screen and (min-width: 1360px) {
    width: 140px;
    height: 84px;
  }
  @media only screen and (min-width: 1600px) {
    width: 160px;
    height: 96px;
  }
  &.swiper-slide-active {
    z-index: 99;
    .swiper-item__control {
      display: inline-flex;
      //opacity: 1;
      z-index: 100;
    }
  }
  &__control {
    //display: inline-flex;
    display: none;
    position: absolute;
    top: 216px;
    //opacity: 0;
    //transition: opacity 0.2s ease-in-out;
    @media only screen and (min-width: 1360px) {
      top: 294px;
    }
    @media only screen and (min-width: 1600px) {
      top: 359px;
    }
    .bttn-round {
      box-shadow: none;
      flex-shrink: 0;
      margin-bottom: 0;
    }
    .bttn-round:not(:last-child) {
      margin-right: 10px;
    }
  }
}

::v-deep .swiper-pagination {
  transform: translate3d(-50%, 0, 0);
  position: absolute;
  top: 176px;
  left: 50%;
  z-index: 120;
  @media only screen and (min-width: 470px) {
    top: 176px;
  }
  @media only screen and (min-width: 1360px) {
    top: 255px;
  }
  @media only screen and (min-width: 1600px) {
    top: 305px;
  }
}

.media-placeholder {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  &__unit {
    width: 100%;
    background-position: center center;
    background-size: cover;
    opacity: 0.5;
    &--m0 {
      height: 60px;
      @media only screen and (min-width: 1360px) {
        height: 84px;
      }
      @media only screen and (min-width: 1600px) {
        height: 95px;
      }
    }
    /*&--t1 {*/
    /*width: 185px;*/
    /*height: 95px;*/
    /*}*/
    /*&--t3 {*/
    /*height: 140px;*/
    /*}*/
  }
}

.bttn-round {
  svg {
    overflow: visible;
  }
}

input[type="file"] {
  width: 1px;
  height: 1px;
  overflow: hidden;
  opacity: 0;
  //visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
}

.disabled {
  cursor: not-allowed;
  opacity: 0.6;
}
</style>
