var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mobile-media"},[(_vm.screen === 'm0')?_c('SliderMobileScreen1'):_vm._e(),(_vm.screen === 'm1')?_c('SliderMobileScreen2'):_vm._e(),_c('div',{staticClass:"mobile-media__container"},[(_vm.imageset == 0)?_c('div',{staticClass:"media-placeholder"},[_c('div',{staticClass:"media-placeholder__unit",class:[
          { 'media-placeholder__unit--m0': _vm.screen === 'm0' },
          { 'media-placeholder__unit--m0': _vm.screen === 'm1' },
        ],staticStyle:{"background-image":"url('http://hdimages.org/wp-content/uploads/2017/03/placeholder-image10.jpg')"}})]):_vm._e(),_c('swiper',{ref:"mySwiper",staticClass:"swiper-box",attrs:{"options":_vm.swiperOption}},[_vm._l((_vm.imageset),function(image,i){return _c('swiper-slide',{key:`smob-${i}`,staticClass:"swiper-item",class:`${_vm.tag}`},[_c('img',{staticClass:"swiper-item__img",attrs:{"src":image.attributes.src,"width":"160","height":"96"}}),_c('div',{staticClass:"swiper-item__control"},[(_vm.tag !== 'shared_image')?_c('div',{staticClass:"swiper-item__control-buttons"},[_c('button',{staticClass:"bttn-round",attrs:{"type":"button","disabled":!_vm.hasAccess || _vm.load,"title":_vm.$t('edit_campaign.image_change_order_up')},on:{"click":function($event){return _vm.changeOrderUp(image)}}},[_c('b-icon-arrow-up-short')],1),_c('button',{staticClass:"bttn-round",attrs:{"type":"button","disabled":!_vm.hasAccess,"title":_vm.$t('edit_campaign.image_delete')},on:{"click":function($event){return _vm.delFile(image)}}},[_c('IconDeleteFile')],1),_c('input',{attrs:{"type":"file","disabled":!_vm.hasAccess,"id":`update_file_${image.id}`},on:{"change":function($event){return _vm.updFile($event, image)}}}),_c('label',{staticClass:"bttn-round",class:{
                disabled: !_vm.hasAccess,
              },attrs:{"for":`load_file_${image.id}`}},[_c('IconUpdateFile')],1),(!_vm.isOnlyOne)?_c('input',{attrs:{"type":"file","disabled":!_vm.hasAccess,"id":`load_file_${image.id}`},on:{"change":function($event){return _vm.uplFile($event)}}}):_vm._e(),(!_vm.isOnlyOne)?_c('label',{staticClass:"bttn-round",class:{
                disabled: !_vm.hasAccess,
              },attrs:{"for":`load_file_${image.id}`}},[_c('IconUploadFile')],1):_vm._e(),_c('button',{staticClass:"bttn-round",attrs:{"type":"button","disabled":!_vm.hasAccess || _vm.load || _vm.currentImageOrder === 0,"title":_vm.$t('edit_campaign.image_change_order_down')},on:{"click":function($event){return _vm.changeOrderDown(image)}}},[_c('b-icon-arrow-down-short')],1)]):_vm._e(),(_vm.tag !== 'shared_image')?_c('p',{staticClass:"swiper-item__control-order text-center"},[_vm._v(" Order: "+_vm._s(_vm.currentImageOrder)+" ")]):_vm._e()])])}),(_vm.tag !== 'shared_image')?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.imageset.length > 1),expression:"imageset.length > 1"}],staticClass:"!tw-z-auto swiper-pagination",attrs:{"slot":"pagination"},slot:"pagination"}):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.imageset.length > 1),expression:"imageset.length > 1"}],staticClass:"bttn-round slide-controls prev",attrs:{"slot":"button-prev"},slot:"button-prev"},[_c('IconSlidePrev')],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.imageset.length > 1),expression:"imageset.length > 1"}],staticClass:"bttn-round slide-controls next",attrs:{"slot":"button-next"},slot:"button-next"},[_c('IconSlideNext')],1)],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }