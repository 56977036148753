<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    title: {
      type: String,
      required: true,
    },
    as: {
      type: String,
      default: "div",
    },
  },
  methods: {
    confirm() {
      this.$bvModal
        .msgBoxConfirm(this.title, {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.$emit("confirm");
          } else {
            this.$emit("cancel");
          }
        })
        .catch((e) => {
          console.error(e);
        });
    },
  },
});
</script>

<template>
  <component v-bind="$attrs" v-on="$listeners" :is="as" @click="confirm">
    <slot />
  </component>
</template>
