<script setup>
import {
  CharidyCampaignGiftsClaimStatusCanceled,
  CharidyCampaignGiftsClaimStatusCurrentlyShipping,
  CharidyCampaignGiftsClaimStatusDelivered,
  CharidyCampaignGiftsClaimStatusOther,
  CharidyCampaignGiftsClaimStatusProcessed,
  CharidyCampaignGiftsClaimStatusSentToCarrier,
  useCampaignGiftClaim,
} from "@/composables/campaign-gift-claim";
import { notificationsMixin } from "@/mixins";
import { nanoid } from "nanoid";
import { getCurrentInstance, reactive } from "vue";

const emit = defineEmits(["submit"]);

const { item, loading, fetchOrgCampaignGiftClaimByIDByOrg, updateOrgCampaignGiftClaim } =
  useCampaignGiftClaim();

const vm = getCurrentInstance();
const $t = vm.proxy.$t.bind(vm.proxy);
const cId = vm.proxy.$store.state.campaign.campaign.data.id;
const notificationsMixinHandleCatch =
  notificationsMixin.methods.$_notificationsMixin_handleCatch.bind(vm.proxy);
const notificationsMixinMakeToastCompat =
  notificationsMixin.methods.$_notificationsMixin_makeToastCompat.bind(vm.proxy);

const MODAL_ID = `campaign-gift-claims-modal-${cId}-${nanoid()}`;
const FORM_ID = `campaign-gift-claims-modal-form-${cId}-${nanoid()}`;

const formData = reactive({
  gift_id: "",
  team_id: "",
  points: "",
  status: "",
});

async function show(id) {
  try {
    await fetchOrgCampaignGiftClaimByIDByOrg(cId, id, {});

    formData.gift_id = item.value.gift_id;
    formData.team_id = item.value.team_id;
    formData.points = item.value.points;
    formData.status = item.value.status;

    vm.proxy.$bvModal.show(MODAL_ID);
  } catch (e) {
    notificationsMixinHandleCatch(e);
  }
}

async function hide() {
  vm.proxy.$bvModal.hide(MODAL_ID);
}

function clearData() {
  formData.gift_id = "";
  formData.team_id = "";
  formData.points = "";
  formData.status = "";
}

async function submit() {
  const formDataClone = structuredClone(formData);

  if (item.value) {
    try {
      await updateOrgCampaignGiftClaim(cId, item.value.ID, formDataClone);

      notificationsMixinMakeToastCompat({
        group: "Notification",
        title: $t("edit_campaign.success_save_campaign_gifts_claim", "Saved"),
      });
      emit("submit", hide, formDataClone);

      return;
    } catch (e) {
      notificationsMixinHandleCatch(e);
    }
  }
}

const options = [
  {
    value: CharidyCampaignGiftsClaimStatusProcessed,
    text: CharidyCampaignGiftsClaimStatusProcessed,
  },
  {
    value: CharidyCampaignGiftsClaimStatusSentToCarrier,
    text: CharidyCampaignGiftsClaimStatusSentToCarrier,
  },
  {
    value: CharidyCampaignGiftsClaimStatusCurrentlyShipping,
    text: CharidyCampaignGiftsClaimStatusCurrentlyShipping,
  },
  {
    value: CharidyCampaignGiftsClaimStatusDelivered,
    text: CharidyCampaignGiftsClaimStatusDelivered,
  },
  {
    value: CharidyCampaignGiftsClaimStatusCanceled,
    text: CharidyCampaignGiftsClaimStatusCanceled,
  },
  {
    value: CharidyCampaignGiftsClaimStatusOther,
    text: CharidyCampaignGiftsClaimStatusOther,
  },
];
</script>

<template>
  <div>
    <slot :show="show" :loading="loading" />
    <b-modal :id="MODAL_ID" @hidden="clearData" size="xl" scrollable centered>
      <template #modal-header="{ hide }">
        <div>
          <h2>{{ $t("edit_campaign.campaign_gift_claims_modal_title", "Gifts") }}</h2>
          <div class="modal-header__hide" @click="hide">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <path
                d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
              />
              <path d="M0 0h24v24H0z" fill="none" />
            </svg>
          </div>
        </div>
      </template>
      <template #modal-footer="{ hide }">
        <div>
          <button type="button" class="bttn bttn--lg bttn--blue" @click="hide" name="button">
            {{ $t("edit_campaign.campaign_gift_claims_modal_close", "Close") }}
          </button>
          <button
            type="submit"
            :form="FORM_ID"
            :disabled="loading"
            :class="{
              disabled: loading,
            }"
            class="bttn bttn--lg bttn--orange"
          >
            {{ $t("edit_campaign.campaign_gift_claims_modal_save", "Save") }}
          </button>
        </div>
      </template>

      <form :id="FORM_ID" @submit.prevent="submit">
        <b-form-group :label="$t('edit_campaign.campaign_gift_claims_modal_status', 'Status')">
          <b-form-select required v-model="formData.status" :options="options"></b-form-select>
        </b-form-group>
      </form>
    </b-modal>
  </div>
</template>
