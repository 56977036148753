const state = {
  sidebarOpen: false,
};

const mutations = {
  TOGGLE_SIDEBAR(state, value) {
    state.sidebarOpen = value;
  },
};

const getters = {};

const actions = {
  vendorSidebarToggle({ commit }, payload) {
    commit("TOGGLE_SIDEBAR", payload);
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
