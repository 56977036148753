import urls from "@/constants/urls";
import axios from "axios";

const createURL = ({ oid, cid }) =>
  urls.campaignSettings.replace(":orgId", oid).replace(":campaignId", cid);

const createPayload = ({ id, cid, data, name }) => ({
  data: {
    type: "campaign_meta",
    id: String(id),
    attributes: {
      campaign_id: Number(cid),
      meta_name: name,
      meta_data: JSON.stringify(data),
    },
  },
});

const getCampaignSettings = async ({ oid, cid, params }) => {
  const requestURL = createURL({ oid, cid });

  const resp = await axios.get(requestURL, { params });
  resp.data.data = resp.data.data.map((el) => {
    try {
      el.attributes.default_meta_value_off = JSON.parse(
        el.attributes.default_meta_value_off || "{}"
      );
    } catch {
      el.attributes.default_meta_value_off = {};
    }

    try {
      el.attributes.default_meta_value_on = JSON.parse(el.attributes.default_meta_value_on || "{}");
    } catch {
      el.attributes.default_meta_value_on = {};
    }

    try {
      el.attributes.meta_data = JSON.parse(el.attributes.meta_data || "{}");
    } catch {
      el.attributes.meta_data = {};
    }

    return el;
  });

  return resp;
};

const postCampaignSettings = async ({ oid, cid, name, data }) => {
  const requestURL = createURL({ oid, cid });

  const payload = createPayload({ id: 0, cid, data, name });

  const resp = await axios.post(requestURL, payload);
  resp.data.data.attributes.meta_data = JSON.parse(resp.data.data.attributes.meta_data);

  return resp;
};

const putCampaignSettings = async ({ oid, cid, id, name, data }) => {
  const requestURL = createURL({ oid, cid });

  const payload = createPayload({ id, cid, data, name });

  const resp = await axios.put(requestURL, payload);
  resp.data.data.attributes.meta_data = JSON.parse(resp.data.data.attributes.meta_data);

  return resp;
};

export function useCampaignSettings() {
  const orgId = localStorage.getItem("orgId");

  async function getSetting({ cid, oid = orgId, extraParams = {} } = {}, name) {
    const params = {
      meta_name: name,
      ...extraParams,
    };

    const { data } = await getCampaignSettings({
      cid: cid,
      oid: oid,
      params,
    });
    const [meta] = data.data;
    return meta;
  }

  async function createSetting({ cid, oid = orgId } = {}, name, data) {
    const { data: data_1 } = await postCampaignSettings({
      cid: cid,
      oid: oid,
      name: name,
      data,
    });
    return data_1.data;
  }

  async function updateSetting({ cid, oid = orgId } = {}, name, id, data) {
    const { data: data_1 } = await putCampaignSettings({
      cid: cid,
      oid: oid,
      id: id,
      name: name,
      data,
    });
    return data_1.data;
  }

  return {
    getSetting,
    createSetting,
    updateSetting,
  };
}
