<template>
  <div class="tools-page" v-if="!loading && utmLinks">
    <div class="category-title">
      <SelectToolsCampaign />
    </div>

    <div v-if="selectedCmp">
      <utm-links />
    </div>
  </div>
  <div v-else-if="vettings">
    <vettings />
  </div>
  <div v-else-if="fundTransferStatus">
    <FTSPage />
  </div>
  <div v-else-if="integrationPage">
    <Integration />
  </div>
  <div v-else>
    <div class="text-center">
      <b-spinner class="align-middle"></b-spinner>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import UtmLinks from "./subcomponents/UTMLinks.vue";
import Vettings from "./subcomponents/Vettings.vue";
import FTSPage from "@/views/fund-transfer-status/fts-page.vue";
import Integration from "@/views/integration/integration-list.vue";
import SelectToolsCampaign from "./SelectToolsCampaign.vue";
import { getFavoriteCampaign } from "@/helpers/storage";
import { notificationsMixin } from "@/mixins";

export default {
  mixins: [notificationsMixin],
  components: {
    SelectToolsCampaign,
    UtmLinks,
    Vettings,
    FTSPage,
    Integration,
  },

  data() {
    return {};
  },

  computed: {
    ...mapState({
      campaigns: (state) => state.tools.campaigns,
      loading: (state) => state.tools.loading,
      showFundTransferSection: (state) => {
        return state.account.showFundTransferSection;
      },
    }),

    selectedCmp() {
      const storeCmp = this.$store.state.tools.campaign?.id;
      const favoriteCmp = getFavoriteCampaign();

      return storeCmp || favoriteCmp;
    },

    activePage() {
      return this.$route.params.page;
    },

    utmLinks() {
      return this.activePage === "utm-links";
    },

    vettings() {
      return this.activePage === "vettings";
    },

    fundTransferStatus() {
      return this.showFundTransferSection && this.activePage === "fund-transfer-status";
    },

    integrationPage() {
      return this.activePage === "integration";
    },
  },

  watch: {
    utmLinks: {
      handler(val) {
        if (val) {
          this.fetchCampaigns();
        }
      },
      immediate: true,
    },
  },

  methods: {
    ...mapActions({
      fetchCampaigns: "tools/fetchCampaigns",
    }),

    customLabel({ id, attributes }) {
      return `[${id}] ${attributes.title}`;
    },
  },
};
</script>
