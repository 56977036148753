var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-row',_vm._l((_vm.list),function(item){return _c('b-col',{key:item.id,staticClass:"mb-4",attrs:{"cols":"12","md":"3"}},[_c('b-card',{staticClass:"bg-white shadow rounded position-relative",attrs:{"no-body":""},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('b-card-text',[_vm._v(" "+_vm._s(item.name)+" ")])]},proxy:true}],null,true)},[_c('TableOptionsDropdown',{attrs:{"options":[
          { text: _vm.$t('dashboard.utm_actions_edit'), action: () => _vm.openAddModal(item) },
          {
            text: _vm.$t('dashboard.utm_actions_delete'),
            action: () => _vm.remove(item),
            variant: 'danger',
          },
        ],"toggle-class":"position-absolute bg-light close-btn rounded-circle"}}),_c('b-embed',{staticClass:"svw-unidy-embed",attrs:{"type":"video","controls":""}},[_c('source',{attrs:{"src":item.video,"type":"video/mp4"}})])],1)],1)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }