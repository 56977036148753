<script>
import { defineComponent } from "vue";
import { mapState } from "vuex";

export default defineComponent({
  props: {
    donation: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapState({
      metas: (state) => state.campaign.metas,
    }),

    allowConditionalDonations() {
      const data = this.metas?.allow_conditional_donations;

      if (data?.value) {
        return data;
      }

      return {};
    },
  },
});
</script>

<template>
  <div v-if="allowConditionalDonations.value">
    <slot
      name="match-donation"
      v-if="
        allowConditionalDonations.types_supported.includes('peer_match') &&
        donation.attributes.locked &&
        donation.attributes.locked_type === 'peer_match' &&
        donation.attributes.peer_matched_by_donation_id === 0
      "
    />
    <slot name="donation-matched-by" v-if="donation.attributes.peer_matched_by_donation_id > 0" />
    <slot
      name="donation-matched"
      v-else-if="
        donation.attributes.peer_matched_by_donation_id === 0 &&
        donation.attributes.peer_matched_donation_id > 0
      "
    />
  </div>
</template>
