<template lang="html">
  <div>
    <!-- Modal Component -->
    <b-modal id="changeTimezone" size="lg">
      <div slot="modal-header">
        <h2>
          {{ $t("edit_campaign.edit_time_zone_modal") }}
        </h2>
        <div class="modal-header__hide" @click="modalClose">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path
              d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
            />
            <path d="M0 0h24v24H0z" fill="none" />
          </svg>
        </div>
      </div>

      <div slot="modal-footer">
        <div class="controls">
          <button type="button" class="bttn bttn--lg bttn--blue" @click="modalClose" name="button">
            {{ $t("edit_campaign.edit_time_zone_close") }}
          </button>
          <button
            form="change-timezone"
            :disabled="sending"
            :class="sending ? 'disabled bttn bttn-lg bttn--orange' : 'bttn bttn--lg bttn--orange'"
          >
            {{ $t("edit_campaign.edit_time_zone_save") }}
          </button>
        </div>
      </div>

      <form id="change-timezone" @submit.prevent="saveFormTimeZone">
        <error v-if="errors" :errors="errors"></error>

        <div class="form-block">
          <div class="timezone-edit">
            <div class="text-in-modal">
              <p>
                {{ $t("account.timezone_modal") }}
              </p>
            </div>

            <div class="multiselect-wrapper">
              <multiselect
                v-model="multiselectData"
                :options="timezone"
                label="text"
                track-by="text"
              >
              </multiselect>
            </div>
          </div>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import urls from "@/constants/urls.js";

import error from "@/components/errors.vue";

import { mapState } from "vuex";

import axios from "axios";

import Multiselect from "vue-multiselect";

export default {
  data: function () {
    return {
      errors: [],

      sending: false,

      multiselectData: null,
    };
  },

  components: {
    error: error,
    Multiselect,
  },

  computed: mapState({
    timezone: (state) => {
      let timeZone = state.account.timeZones;

      let resultArr = [];

      for (var i = 0; i < timeZone.length; i++) {
        let obj = {
          text: timeZone[i].attributes.name,
          value: timeZone[i].attributes.timezone,
        };

        resultArr.push(obj);
      }

      return resultArr;
    },

    orgId: function () {
      return localStorage.getItem("orgId");
    },

    selectedTimeZone: (state) => {
      return state.account.account.data.attributes.timezone;
    },
  }),

  methods: {
    openModal: function () {
      this.sending = false;

      this.errors = [];

      this.$root.$emit("bv::show::modal", "changeTimezone");

      this.setTimezone();

      this.sending = false;

      this.errors = [];
    },

    modalClose: function () {
      this.$root.$emit("bv::hide::modal", "changeTimezone");
    },

    saveFormTimeZone: function () {
      this.sending = true;

      this.errors = [];

      if (!this.multiselectData) {
        this.errors.push(this.$t("edit_campaign.please_select_timezone"));
        this.sending = false;
      } else {
        let timezone = {
          data: {
            attributes: {
              timezone: this.multiselectData.value,
            },
          },
        };

        axios
          .patch(urls.editTimeZone.replace(":orgId", this.orgId), timezone)
          .then(() => {
            this.sending = false;

            this.modalClose();

            this.$_notificationsMixin_makeToastCompat({
              group: "Notification",
              title: this.$t("edit_campaign.success_edit"),
            });

            this.$store.dispatch("updateAccountInfo");
          })
          .catch((e) => {
            this.sending = false;
            if (e.response.data) {
              if (e.response.data.error) {
                this.errors.push(e.response.data.error);
              } else {
                this.errors.push(this.$t("template.error_default"));
              }
            } else {
              this.errors.push(this.$t("template.error_default"));
            }
          });
      }
    },

    setTimezone: function () {
      let timeForMultiselect = this.timezone.filter((el) => {
        return el.value == this.selectedTimeZone;
      });

      this.multiselectData = timeForMultiselect[0];
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../styles/_vars.scss";

.timezone-edit {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: 50px;
  padding-bottom: 50px;
  @media (max-width: 768px) {
    display: block;
  }
}

.multiselect-wrapper {
  width: calc(100% - 200px);
  max-width: 350px;
}

.text-in-modal {
  width: 200px;
  p {
    margin: 0;
    padding: 0;
  }
  @media (max-width: 768px) {
    padding-bottom: 10px;
  }
}

.change-time-zone-button {
  color: $lr-100;
}

.modal-footer {
  .controls {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}
</style>
