<template>
  <div class="payment-card-container tw-col-span-3">
    <div class="scroll-box">
      <div class="card-desk">
        <payment-card v-for="card in cards" :key="card.id">
          <template #body>
            <div class="card-body">
              <h3 class="card-title">{{ card.attributes.brand }}</h3>
              <div class="d-flex align-items-center">
                <i>
                  <img
                    v-if="card.attributes.type !== 'card'"
                    src="https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/donor_dashboard/bank-place.png"
                  />
                  <img
                    v-else
                    src="https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/donor_dashboard/bank-card.png"
                  />
                </i>
                <div class="card-text">
                  <p>Ending in {{ card.attributes.last4 }}</p>
                  <p v-if="!card.isBankAccount">
                    Expires {{ card.attributes.expires_month + "/" + card.attributes.expires_year }}
                  </p>
                  <p v-else>Status: expired</p>
                  <p v-if="card.attributes.default">Default</p>
                </div>
              </div>
            </div>
          </template>
          <template #footer>
            <div class="card-footer">
              <div class="highlight">
                <span class="highlight-text" @click="setIsDefaultState(card)">Set as default</span>
              </div>
              <b-button
                @click="showDeletePaymentModal(card)"
                ref="btnShow"
                v-b-modal.delete-payment-method
                class="btn-del"
              >
                <i></i>
                <span>Delete</span>
              </b-button>
            </div>
          </template>
        </payment-card>
        <b-button
          @click="showAddPaymentModal"
          ref="btnShow"
          v-b-modal.add-payment-method
          class="add-payment-method"
        >
          <i></i>
          <span>Add payment method</span>
        </b-button>
      </div>
    </div>

    <delete-payment-method ref="deletePaymentMethod"></delete-payment-method>
    <add-payment-method ref="addPaymentMethod"></add-payment-method>
  </div>
</template>

<script>
import { mapState } from "vuex";
import paymentCard from "./subcomponents/payment-card.vue";
import AddPaymentMethod from "./modals/add-payment-method-popup.vue";
import DeletePaymentMethod from "./modals/delete-payment-method-popup.vue";

export default {
  created: function () {
    this.$store.dispatch("getCards");
  },
  components: {
    "payment-card": paymentCard,
    "add-payment-method": AddPaymentMethod,
    "delete-payment-method": DeletePaymentMethod,
  },
  methods: {
    showAddPaymentModal() {
      this.$refs.addPaymentMethod.openModal();
    },
    showDeletePaymentModal(card) {
      this.$refs.deletePaymentMethod.openModal(card.id);
    },
    setIsDefaultState(card) {
      card.isDefault = !card.isDefault;
    },
  },
  computed: mapState({
    cards: (state) => {
      return state.stripe.cards;
    },
  }),
};
</script>

<style lang="scss">
@import "@/styles/_vars.scss";
.payment-card-container {
  padding: 30px 3% 36px;
  color: #a0a1ac;
  letter-spacing: -0.5px;
  .scroll-box {
    max-height: 494px;
    overflow: auto;
  }
  .card-desk {
    flex-wrap: wrap;
    display: flex;
  }
  .add-payment-method {
    background: #fbfdff;
    border: 1px dashed #dee6ef;
    border-radius: 4px;
    max-width: calc(33.33% - 20px);
    min-width: calc(33.33% - 20px);
    margin: 10px 10px 13px;
    display: flex;
    flex-direction: column;
    padding: 60px 20px;
    font-family: "VitoExtended-Light";
    i {
      display: inline-block;
      width: 36px;
      height: 36px;
      border: 1px solid #10759833;
      margin-bottom: 5px;
      border-radius: 18px;
      &:before {
        content: "\002B";
        display: block;
        width: 34px;
        height: 34px;
        font-style: normal;
        font-size: 26px;
        color: #107598;
        line-height: 1.3;
      }
    }
    span {
      display: block;
      text-decoration: underline;
      color: #107598;
    }
    &:focus,
    &:hover {
      outline: none;
      background: #fbfdff !important;
      border: 1px dashed #dee6ef !important;
    }
  }
}
</style>
