import { dashboardApiHost } from "@/constants/urls.js";
import axios from "axios";
import { mapIncludedToData } from "@/helpers/jsonapi";
import { computed, ref } from "vue";

async function getTeamDonations(params) {
  const url = `${dashboardApiHost}/orgarea/api/v1/account/donor_account/donations`;
  const { data, headers } = await axios.get(url, {
    params,
    paramsSerializer: {
      indexes: null, // no brackets at all
    },
  });
  const dataWithIncluded = mapIncludedToData(data.data, data.included);

  return {
    data: dataWithIncluded,
    count: headers["x-total-donations"],
    subscriptionsTotal: headers["x-total-subscriptions"] || 0,
    search: headers["x-search-donations"],
  };
}

export function useProctectedTeamDonationList() {
  const donations = ref([]);
  const subscriptionsTotal = ref(0);
  const loading = ref(false);
  const donationsTotal = ref(0);

  function countSubscriptions(donations) {
    for (const donation of donations) {
      const recurringCharges = donation.relationships?.recurring_charges?.data || [];
      if (donation.attributes.recurring_period > 0) {
        subscriptionsTotal.value = subscriptionsTotal.value + recurringCharges.length;
      }
    }
  }

  const donationsTotals = computed(() => {
    let amount = 0;
    let effectiveAmount = 0;
    let total = Number(donationsTotal.value) + Number(subscriptionsTotal.value);

    let donationsCurrencyCode = "";
    if (donations.value.length > 0) {
      donationsCurrencyCode = donations.value[0].attributes.currency_code;
    }

    for (var donation of donations.value) {
      amount += donation.attributes.charged_amount;
      effectiveAmount += donation.attributes.effective_amount;
    }
    return { donationsTotal: total, amount, effectiveAmount, donationsCurrencyCode };
  });

  async function fetchTeamDonations(params) {
    loading.value = true;

    try {
      const { data, count } = await getTeamDonations(params);
      donationsTotal.value = count;
      donations.value = data;
      countSubscriptions(donations.value);
    } catch (e) {
      console.error(e);
      throw e;
    } finally {
      loading.value = false;
    }
  }

  return {
    donations,
    donationsTotal,
    donationsTotals,
    loading,
    fetchTeamDonations,
  };
}
