<template>
  <div class="tw-col-span-3">
    <organization-role></organization-role>
  </div>
</template>

<script>
import organizationRole from "./subcomponents/organization-role.vue";

export default {
  components: {
    "organization-role": organizationRole,
  },
};
</script>

<style scoped lang="scss"></style>
