<template>
  <div v-if="!loading">
    <div class="accordion" role="tablist">
      <b-card
        no-body
        class="mb-1"
        :key="index"
        v-for="([key, templates], index) in Object.entries(groupedTemplates)"
      >
        <b-card-header header-tag="header" role="tab">
          <h2 class="mb-0">
            <b-button block v-b-toggle="`gt-accordion-${index}`" variant="link" class="text-left">
              {{ key }}
            </b-button>
          </h2>
        </b-card-header>
        <b-collapse
          :id="`gt-accordion-${index}`"
          :visible="index === 0"
          accordion="gt-accordion"
          role="tabpanel"
        >
          <div class="templates-section">
            <CampaignTemplate
              v-for="(template, index) in templates"
              :template="template"
              :value="value"
              :current="current"
              :key="index"
              @click="onInput"
            />
          </div>
        </b-collapse>
      </b-card>
    </div>
    <div class="templates-section">
      <CampaignTemplate
        v-for="(template, index) in nonGroupedTemplates"
        :template="template"
        :value="value"
        :current="current"
        :key="index"
        @click="onInput"
      />
    </div>
  </div>
  <div class="templates-section text-center" v-else>
    <label class="template-loading">
      <b-spinner label="loading templates data" />
    </label>
  </div>
</template>

<script>
import { mapState } from "vuex";
import groupBy from "lodash/groupBy";
import CampaignTemplate from "./campaignTemplate.vue";

export default {
  components: { CampaignTemplate },
  props: {
    value: {
      type: String,
      default: "",
    },
    current: {
      type: String,
      default: "",
    },
    data: {
      type: Array,
      default: () => [],
    },
    loading: Boolean,
  },
  methods: {
    onInput(template) {
      this.$emit("input", template.attributes.code);
    },
  },
  computed: {
    nonGroupedTemplates() {
      return this.data.filter(
        (template) =>
          template.attributes.group_id === null &&
          !this.excludedTemplate.includes(template.attributes.code)
      );
    },
    groupedTemplates() {
      const data = this.data.filter(
        (template) =>
          template.attributes.group_id !== null &&
          !this.excludedTemplate.includes(template.attributes.code)
      );
      return groupBy(data, (gt) => gt.attributes.group_title);
    },
    ...mapState({
      excludedTemplate: (state) => state.featureHide.excludedTemplate,
    }),
  },
};
</script>

<style lang="scss" scoped>
.templates-section {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  position: relative;
  align-items: center;
  justify-content: center;
}
.template-loading {
  margin: 15px;
  min-height: 270px;
  border: 3px solid transparent;
  display: block;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  border-radius: 6px;
  width: 100%;
  @media only screen and (min-width: 568px) {
    width: 46%;
    margin: calc(8% / 2 / 2);
  }
}
</style>
